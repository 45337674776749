import React from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from './secondaryButton/secondarybutton';

const DownloadButton = ({ fileUrl, fileName, buttonText }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName); // Use the provided fileName
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after the click event
  };

  return (
    <SecondaryButton
      onClick={handleDownload}
      text={buttonText}
      icon="download"
    />
  );
};

DownloadButton.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default DownloadButton;
