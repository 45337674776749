import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Matches from './matches/matches';
import GameModes from './gameModes/gameModes';
import CustomTabs from '../../../components/customTabs/customTabs';

const Onevone = () => {
    const data = [
        {
            title: 'Matches',
            render: <Matches />,
        },
        {
          title: 'Game modes',
          render: <GameModes />,
        },
      ];


  return (
    <Box >
        <CustomTabs data={data} />
    </Box>
  )
}

export default Onevone;