import React from 'react';
import { Box, Flex, Text, Button, Image, Divider, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import FilterIcon from '../../../../assets/filter.png';
import ArrowDownIcon from '../../../../assets/arrowDown.png';

const CustomButton = ({ text, icon, ...props }) => (
  <Button rightIcon={<Image src={icon} />} variant="outline" fontWeight="400" fontSize="14px" borderWidth="0.5px" {...props}>
    {text}
  </Button>
);

const TableColumnHeader = ({ children }) => (
  <Th textTransform="capitalize" whiteSpace="nowrap" color="black" fontWeight="700" fontSize="14px">
    {children}
  </Th>
);

const TableDataCell = ({ children, fontWeight }) => (
  <Td fontWeight={fontWeight} fontSize="14px">
    {children}
  </Td>
);

const Notifications = () => (
  <Box overflowX="auto"
  whiteSpace="nowrap"
  sx={{
    "&::-webkit-scrollbar": { display: "none" },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  }}>
    <Flex justifyContent="space-between" alignItems="center">
      <Text fontWeight="600" fontSize="16px">Notifications history</Text>
      <Flex>
        <CustomButton text="Filter" icon={FilterIcon} mr="4" textColor="#6C6C6C" />
        <CustomButton text="Last 30 days" icon={ArrowDownIcon} fontWeight="500" />
      </Flex>
    </Flex>
    
    <Divider my="1.2rem" borderColor="#E2E8F0" />

    <Table mt="1.2rem" borderRadius="12px" overflow="hidden">
      <Thead bg="#F9FAFB" border="1px solid #EAECF0">
        <Tr>
          <TableColumnHeader>Notification title</TableColumnHeader>
          <TableColumnHeader>Description</TableColumnHeader>
          <TableColumnHeader>Dates</TableColumnHeader>
          <TableColumnHeader>Recipients</TableColumnHeader>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <TableDataCell fontWeight="600">Happy New Year!</TableDataCell>
          <TableDataCell fontWeight="400">Happy new year to all our esteemed customers...</TableDataCell>
          <TableDataCell fontWeight="400">14th Jun, 2024</TableDataCell>
          <TableDataCell fontWeight="400">All users</TableDataCell>
        </Tr>
        <Tr>
          <TableDataCell fontWeight="600">Happy New Year!</TableDataCell>
          <TableDataCell fontWeight="400">Happy new year to all our esteemed customers...</TableDataCell>
          <TableDataCell fontWeight="400">14th Jun, 2024</TableDataCell>
          <TableDataCell fontWeight="400">CODM players</TableDataCell>
        </Tr>
        <Tr>
          <TableDataCell fontWeight="600">Happy New Year!</TableDataCell>
          <TableDataCell fontWeight="400">Happy new year to all our esteemed customers...</TableDataCell>
          <TableDataCell fontWeight="400">14th Jun, 2024</TableDataCell>
          <TableDataCell fontWeight="400">27 users</TableDataCell>
        </Tr>
        <Tr>
          <TableDataCell fontWeight="600">Happy New Year!</TableDataCell>
          <TableDataCell fontWeight="400">Happy new year to all our esteemed customers...</TableDataCell>
          <TableDataCell fontWeight="400">14th Jun, 2024</TableDataCell>
          <TableDataCell fontWeight="400">All users</TableDataCell>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

export default Notifications;
