import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axiosInstance2 from '../../../services/UnAuthApi';
import EmptyState from '../../../components/emptyState/emptyState';
import LineSkeleton from '../../../components/lineSkeleton';

const Countries = ({ clientId }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState('');

  const getVoucher = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/country');
      setCountries(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVoucher();
  }, [refetch]);

  return (
    <>
      <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={'12px'} overflowX="auto"
  whiteSpace="nowrap"
  sx={{
    "&::-webkit-scrollbar": { display: "none" },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  }}>
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Countries{' '}
          </Text>
        </Flex>

        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Country Code
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Country Name
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : countries?.length < 1 ? (
              <EmptyState mt="1.5rem" text="No vouchers available" />
            ) : (
              countries.map(({ code, name }, i) => {
                return (
                  <Tr key={i}>
                    <Td color={'#475467'} fontSize={'.7rem'}>
                      {code}
                    </Td>
                    <Td color={'#475467'} fontSize={'.7rem'}>
                      {name}
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default Countries;
