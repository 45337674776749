import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import CustomTabs from '../../../../components/customTabs/customTabs'
import axiosInstance from '../../../../services/api';
import { useParams } from 'react-router-dom';
import SpinLoader from '../../../../components/spinLoader/spinLoader';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import Disputes from './disputes/disputes';
import { AiOutlineTrophy } from 'react-icons/ai';
import EmptyState from '../../../../components/emptyState/emptyState';
import GoBack from '../../../../components/goBack';

const Match = () => {

  const [loading, setLoading] = useState(true);
  const [match, setMatch] = useState({});
  const [games, setGames] = useState([]);
  const [matchScore, setMatchScore] = useState([]);
  const [round, setRound] = useState(0)
  const [refetch, setRefetch] = useState("")
  const {id} = useParams()

  const formatText = match?.format?.split('_')[2] === "ONE" ? 1 : match?.format?.split('_')[2] === "TWO" ? 2 : match?.format?.split('_')[2] === "THREE" ? 3 : match?.format?.split('_')[2] === "FOUR" ? 4 : ""

  const getGames = async() => {
    try {
        const { data } = await axiosInstance.get(`games`)
        setGames(data?.data?.content)
    } catch(error) {
        console.error(error)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getGames()
  }, [])

  const getMatch = async() => {
    try {
        const { data } = await axiosInstance.get(`one-v-one/individual/${id}`)
        console.log(data?.data)
        setMatch(data?.data)
    } catch(error) {
        console.error(error)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getMatch()
  }, [refetch])

  const getMatchScores = async() => {
    try {
        const { data } = await axiosInstance.get(`one-v-one/individual/${id}/scores`)
        console.log(data?.data)
        setMatchScore(data?.data)
    } catch(error) {
        console.error(error)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getMatchScores()
  }, [])

  const tabData = [
    // {
    //     title: "Overview",
    //     render: <></>
    // },
    {
        title: "Disputes",
        render: <Disputes match={match} matchScore={matchScore} setRefetch={setRefetch} />
    },
    {
        title: "Creator's Rules",
        render: 
        <>
        {(match?.proposedRules?.length > 1) ? match?.proposedRules : <EmptyState text="No proposed rules from match creator" />}
        </>
    },
    {
        title: "Gameporte's Rules",
        render: 
        <>
            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>Disconnections</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".3rem"}}>In the event of a disconnection, you and your opponent must finish the remaining time of the match, keeping the score the same as it was in the game that got disconnected. i.e. If the disconnection occurred in the 30th minute, the new game should be played until the 60th.</p>
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>When the 60th minute is reached, as soon as the ball is no longer in play, the match is over and both players must quit the game. If you continue to play and goals are scored, the goals will count. We highly recommend recording video of all game footage in case of a dispute.</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>[Online matches] Online Squads Only</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>Online Matches must be played with 'Online Squads' only. If there is any attempt to use Custom Squads or edited players, you will automatically forfeit the match and get a loss.</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>[Online matches] No Custom Formations</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>Custom Formations are not allowed to be used in online matches, unless agreed upon by the players involved. In case of a dispute, you MUST HAVE VIDEO EVIDENCE for it to be considered and the decision is at the discretion of Players' Lounge.</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>Winning a round</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>We recognise online win per match and we do not aggregate scores of matches for a round to determine the winner . For you to be declared the winner of a round (set of matches) for best of 1 (user must win 1 match) , for  Best of 3 (user must win at least 2 matches) for Best of 5 (user must win at least 3 matches)</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>Drawing/Tying a Match</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>If there is a tie at the end of a round (set of matches) the users can decide either to play a new round (at no extra cost) or end the round as a Tie, if the round ends as a tie the funds will be refunded to the users .However, the match processing charges will not be returned as this service has already been provided.</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>[Online matches] Legacy Defending</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>Legacy defending is not allowed to be used in Gameporte Tournament matches. In case of a dispute, you MUST HAVE VIDEO EVIDENCE for it to be considered and the decision is at the discretion of Gameporte. (only if both parties agree and state in the proposed rule section)</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>Lag/Settings/Teams</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>After 1 minute of gameplay any complaints on lag, pre-game settings, or banned teams will not be taken into consideration. No exceptions. (Note: connection is much better if you use a LAN cable instead of WiFi.)</p>

            <strong style={{color: "#244CC6", fontSize: ".8rem"}}>Score reporting</strong> 
            <p style={{fontSize: ".8rem", marginBottom: ".5rem"}}>Either party can report the score of a particular match. However, if a party has reported a score the other party can only dispute the score by providing adequate evidence (e.g a video clip or screenshot) and has 5 minutes to contest such score. After all matches have been played and score reported either party is required to end the match after which the winner will be credited.</p>
        </>
    }
  ]

  return (
    <>
        {loading ?
        <SpinLoader />
        :
        <>
            <GoBack />
            <Box height={["280px","350px"]} mt=".7rem" bg={"lightgray"} backgroundImage={games?.find(i => i?.game?.id === match?.gameId)?.game?.bannerUrl} backgroundSize={"cover"} backgroundPosition={"bottom"} borderRadius={"8px"} boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}>
                <Flex bg="#FFF" height={["280px", "280px"]} width={["auto","500px", "800px"]} mx="auto" borderRadius={"6px"} transform={["none", "translateY(11rem)"]} boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}>
                    <Box width={"300px"} height={"100px"} mx="auto" mt="1.5rem">
                        <Text textAlign={"center"} color={"green.600"} fontSize={"1.1rem"} fontWeight={"600"} mb="1rem" textTransform={"capitalize"}><AiOutlineTrophy style={{display: "inline", transform: "translateY(2px)", marginRight: ".2rem"}} />₦{match?.prize}</Text>
                        <Flex justifyContent={"space-between"}>
                            <Box textAlign={"center"}>
                                <Text fontWeight={"500"} mb="1rem" textTransform={"capitalize"}>₦{match?.entryFee}</Text>
                                <Avatar border={match?.outcome === "HOST_WIN" ? "3px solid green" : ""} mb=".5rem" src={match?.host?.imageUrl} name={match?.host?.username} />
                                <Text fontWeight={"500"} textTransform={"capitalize"}>{match?.host?.username}{match?.outcome === "HOST_WIN" && <AiOutlineTrophy style={{display: "inline", transform: "translateY(2px)", marginLeft: ".2rem", color: "green"}} />}</Text>
                            </Box>
                            <Box textAlign={"center"}>
                                <Text fontWeight={"500"} textTransform={"capitalize"}>VS</Text>
                                <Text mt=".5rem" fontWeight={"600"} fontSize={"1.15rem"} textTransform={"capitalize"}>{matchScore[round]?.hostScore} - {matchScore[round]?.opponentScore}</Text>
                                {matchScore[round] && <Box mt=".7rem" transition={"250ms ease"} zIndex={"10"} _hover={{transform: "scale(10) translateY(.75rem)"}} borderRadius={"3px"} cursor={"pointer"} height={"25px"} width={"35px"} bg="lightgray" mx="auto" backgroundImage={matchScore[round]?.scoreImage} />}
                            </Box>
                            <Box textAlign={"center"}>
                                <Text fontWeight={"500"} mb="1rem" textTransform={"capitalize"}>₦{match?.entryFee}</Text>
                                <Avatar p=".2rem" border={match?.outcome === "OPPONENT_WIN" ? "3px solid green" : ""} mb=".5rem" src={match?.opponent?.imageUrl} name={match?.opponent?.username} />
                                <Text fontWeight={"500"} textTransform={"capitalize"}>{match?.opponent?.username}{match?.outcome === "OPPONENT_WIN" && <AiOutlineTrophy style={{display: "inline", transform: "translateY(2px)", marginLeft: ".2rem", color: "green"}} />}</Text>
                            </Box>
                        </Flex>

                        <Flex mt="2.2rem" justifyContent={"space-between"}>
                            <HiChevronLeft opacity={round > 0 ? "1" : "0"} onClick={round > 0 ? () => setRound(round - 1) : null} style={{transform: "translateY(4px) scale(1.3)", cursor: "pointer", zIndex: "3"}} />
                            <Text textAlign={"center"} fontWeight={"500"} textTransform={"capitalize"}>{match?.format?.split('_')?.join(" ")} - Match {round + 1} of {formatText}</Text>
                            <HiChevronRight opacity={(round + 1) >= parseInt(formatText) ? "0" : "1"} onClick={(round + 1) >= parseInt(formatText) ? null : () => setRound(round + 1)} style={{transform: "translateY(4px) scale(1.3)", cursor: "pointer"}} />
                        </Flex>
                        
                    </Box>
                </Flex>
            </Box>
            <br /><br /><br /><br /><br />
            <CustomTabs data={tabData} />
        </>
        }
    </>
  )
}

export default Match;