import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import LineSkeleton from '../../../../components/lineSkeleton';
import EmptyState from '../../../../components/emptyState/emptyState';
import axiosInstance from '../../../../services/api';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CreateChannel from './createChannel';
import EditChannel from './editChannel';

const Channels = () => {
    
    const [loading, setLoading] = useState(false);
    const [loadingc, setLoadingc] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [refetch, setRefetch] = useState("")
    const [channels, setChannels] = useState([])

    const getChannels = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get('whatsapp/group')
            setChannels(data?.data?.content)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
  
    useEffect(() => {
        getChannels()
    }, [refetch])
    

    const [selectedUser, setSelectedUser] = useState(null)

    const { isOpen, onOpen, onClose } = useDisclosure();


    const toast = useToast()

    const handleModal = (user) => {
      setSelectedUser(user)
      onOpen()
    }

    const deleteChannel = async() => {
        setLoadingc(true)
        try {
            const { data } = await axiosInstance.delete(`whatsapp/group/${selectedUser?.id}`)
            toast({
            description: data?.message || "Channel Deleted",
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingc(false)
        }
    }


  return (
    <>
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Channels <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{channels?.length}</span></Text>
            <CustomDrawer
            position={"right"}
            title={"Create Channel"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={"Add Channel"} icon="add" loading={false}  />
            }
            content={
                <CreateChannel setTriggerClose={setTriggerClose} setRefetch={setRefetch} />
            }
            />
        </Flex>
    
        <Box bg="#FFF">
            <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                    <Tr>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>URL</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                        <Th></Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                
                <Tbody>
                {loading ? 
                <LineSkeleton width="100%" num={10} />
                :
                channels?.length < 1 ?
                <EmptyState mt="1.5rem" text="There are no stores added yet" />
                :
                channels?.map((({id, url, name}) => {
                    const data = {id, url, name}
                    return (
                        <Tr key={console?.id} cursor={"pointer"}>
                            <Td color={"#475467"} fontSize={".9rem"}>#{id}</Td>
                            <Td>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                            </Td>
                            <Td color={"#475467"} fontSize={".9rem"}>{url}</Td>
                            <Td color={"#475467"} fontSize={".9rem"}></Td>
                            <Td color={"#475467"} fontSize={".9rem"}></Td>
                            <Td color={"#475467"} fontSize={".9rem"}></Td>
                            <Td>
                                <Flex gap="1rem" alignItems={"center"}>
                                  <CustomDrawer
                                  position={"right"}
                                  title={"Edit Console"}
                                  triggerClose={triggerClose}
                                  setTriggerClose={setTriggerClose}
                                  toggleElement={
                                      <Text textDecoration={"underline"} mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Edit</Text>
                                  }
                                  content={
                                    <EditChannel channelData={data} setTriggerClose={setTriggerClose} setRefetch={setRefetch} />
                                  }
                                  />
                                  <Text onClick={() => handleModal(data)} textDecoration={"underline"} mb=".2rem" color={"crimson"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Delete</Text>
                                </Flex>
                            </Td>
                        </Tr>
                    )
                }))
                }
                </Tbody>
            </Table>
        </Box>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>Delete Channel</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                        <PrimaryButton onClick={deleteChannel} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingc} />
                        </Flex>
                    </>
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
  )
}

export default Channels;