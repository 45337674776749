import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/customInput";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import { Box, Flex, Text, Button, Image, useToast } from "@chakra-ui/react";
import CustomSelect from "../../../../components/customSelect/customSelect";
import axiosInstance2 from "../../../../services/api2";
import Delete from "../../../../assets/delete.png";
import Edit from "../../../../assets/edit.png";

const CreateGiftCardType2 = ({
  setTriggerClose,
  imageURL,
  currentPhase,
  setCurrentPhase,
  handleNext,
  handlePrevious,
  setRefetch,
  goToNextPhase,
  countries,
  initialImage,
  // setGiftCardEntries,
  // giftCardEntries,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [giftCardEntries, setGiftCardEntries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [image, setImage] = useState(initialImage);

  const toast = useToast();

  // Add or edit entry to the list
  const handleAddOrEditEntry = () => {
    // Ensure giftCardEntries is always an array
    const currentEntries = Array.isArray(giftCardEntries) ? giftCardEntries : [];
  
    if (name && description && selectedCountry) {
      const newEntry = {
        name,
        description,
        countryCode: selectedCountry,
        image,
      };
  
      if (editingIndex !== null) {
        const updatedEntries = [...currentEntries];
        updatedEntries[editingIndex] = newEntry;
        setGiftCardEntries(updatedEntries);
        setEditingIndex(null);
      } else {
        setGiftCardEntries([...currentEntries, newEntry]);
      }
  
      setName("");
      setDescription("");
      setSelectedCountry("");
    }
  };

  // Edit an existing entry
  const handleEditEntry = (index) => {
    const entryToEdit = giftCardEntries[index];
    setName(entryToEdit.name);
    setDescription(entryToEdit.description);
    setSelectedCountry(entryToEdit.countryCode);
    setEditingIndex(index);
  };


  const handleDeleteEntry = (index) => {
    const updatedEntries = giftCardEntries.filter((_, idx) => idx !== index);
    setGiftCardEntries(updatedEntries);
  };

  const createGiftCardTypes = async () => {
    setLoading(true);
  
    try {
      let entriesToCreate = [...giftCardEntries];
  
      if (name && description && selectedCountry) {
        const unsavedEntry = {
          name,
          description,
          countryCode: selectedCountry,
          image,
        };
  
        const isDuplicate = giftCardEntries.some(
          (entry) =>
            entry.name === unsavedEntry.name &&
            entry.description === unsavedEntry.description &&
            entry.countryCode === unsavedEntry.countryCode
        );
  

        if (!isDuplicate) {
          entriesToCreate = [...entriesToCreate, unsavedEntry];
        }
      }
  
      for (const entry of entriesToCreate) {
        const requestBody = {
          name: entry.name,
          description: entry.description,
          countryCode: entry.countryCode,
          code: entry.name
            .replace(/[^\w\s]/g, "") // Remove non-alphanumeric characters
            .replace(/\s+/g, "_"), // Replace spaces with underscores
        };
  
        await axiosInstance2.post("/gift-card-type", requestBody);
      }
  
      toast({
        description: "Gift Card Types Created Successfully",
        status: "success",
        position: "top",
      });
  
      // setGiftCardEntries([]);
      setName("");
      setDescription("");
      setSelectedCountry("");
      setEditingIndex(null);
  
      setRefetch(Math.random());
      goToNextPhase();
      console.log("Current Gift Card Entries:", giftCardEntries);

    } catch (error) {
      toast({
        description: `Error Creating Gift Card Type: ${error.response.data.errors[0]}`,
        status: "error",
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      {/* Display added entries */}
      <Text
        fontSize={"16px"}
        fontWeight={"500"}
      >{`Gift card type (Step 2/3)`}</Text>
      <Box mt={3} mb="1.5rem">
  {giftCardEntries && giftCardEntries?.length > 0 ? (
    giftCardEntries.map((entry, index) => (
      <Flex
        key={index}
        alignItems="center"
        justifyContent="space-between"
        p="1rem"
        mb="0.5rem"
        borderRadius="md"
      >
        <Flex alignItems="center">
          {/* <Image
            src={imageURL || "https://via.placeholder.com/50"}
            alt="Gift Card"
            boxSize="50px"
            objectFit="cover"
            mr="1rem"
            borderRadius="8px"
          /> */}
          <Box>
            <Text fontWeight="bold">{entry.name}</Text>
            <Text fontSize="sm" color="gray.600">
              {entry.description}
            </Text>
          </Box>
        </Flex>
        <Flex gap={3}>
          <Image
            src={Edit}
            cursor={"pointer"}
            boxSize={"24px"}
            onClick={() => handleEditEntry(index)}
          />
          <Image
            src={Delete}
            cursor={"pointer"}
            boxSize={"24px"}
            onClick={() => handleDeleteEntry(index)}
          />
        </Flex>
      </Flex>
    ))
  ) : (
    <Text color="gray.500" textAlign="center">
      No gift card types added yet
    </Text>
  )}
</Box>      {/* Form to add/edit entries */}
      <form>
        <CustomInput
          type="text"
          required
          placeholder="e.g Playstation US $10"
          value={name}
          onChange={(e) => setName(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          mb=".8rem"
          placeholder="Select Country"
          options={countries}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        />
        <CustomInput
          type="text"
          required
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          mb=".8rem"
        />
        <Text
          color="#0071FE"
          fontSize="16px"
          fontWeight={600}
          onClick={handleAddOrEditEntry}
          cursor="pointer"
        >
          {editingIndex !== null ? "Update Entry" : "Add More"}
        </Text>
        <Flex>
          <Button
            onClick={handlePrevious}
            mt="2rem"
            mr={4}
            width={"100%"}
            fontSize=".9rem"
            fontWeight={"600"}
            fontFamily={"Inter"}
            borderRadius={"12px"}
            padding="1.87rem 1.5rem"
            transition={"250ms ease"}
            border={"1px solid"}
            backgroundColor="white"
            borderColor="#003C4E"
          >
            Go back
          </Button>
          <PrimaryButton
            mt="2rem"
            text={giftCardEntries?.length > 0 ? "Create All Gift Cards" : "Create Gift Card"}
            onClick={createGiftCardTypes}
            loading={loading}
            isDisabled={
              (giftCardEntries?.length === 0 && (!name || !description || !selectedCountry)) ||
              editingIndex !== null
            }
          />
        </Flex>
      </form>
    </>
  );
};

export default CreateGiftCardType2;
