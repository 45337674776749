import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Note from './note.svg';
import Gold from './gold.svg';
import Silver from './silver.svg';
import Bronze from "./bronze.svg"
import Naira from '../../../../../../assets/naira.svg'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { convertToProperObject } from '../../../../../../utils/helper';

const Overview = ({state}) => {

    const moreDetails = [
        {
            title: "lobby id",
            val: state?.lobbyDetails?.externalLobbyId || "N/A"
        },
        {
            title: "lobby password",
            val: state?.lobbyDetails?.externalLobbyPassword || "N/A"
        },
        {
            title: "entry fee",
            val: state?.entryFee?.toLocaleString() || "FREE"
        },
        {
            title: "prize pool",
            val: state?.prize?.toLocaleString()
        },
        {
            title: "team size",
            val: state?.teamSize || 1
        },
        {
            title: "event start",
            val: moment(state?.startDateTime).calendar()
        },
        {
            title: "bracket size",
            val: state?.numberOfTeams || state?.numberOfIndividuals
        },
        {
            title: "bracket type",
            val: state?.bracketType
        },
        {
            title: "game format",
            val: state?.gameFormat
        },
        {
            title: "location",
            val: state?.location === "ONLINE" ? state?.location : state?.address
        },
    ]

    const sharingFormula = convertToProperObject(state?.sharingFormula)


  return (
    <Box>
        <Flex p=".2rem" alignItems={"center"} gap=".6rem" bg="#C4D2FF">
            <Image src={Note} transform={"scale(.8)"} />
            <Text fontSize={".9rem"} color="#0A0A0B"><strong>Note: </strong>Tournament entry fees are paid by the team captain for each team.</Text>
        </Flex>
        <Flex py="1rem" gap="1rem" mt="1rem" overflowX="scroll"sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em", // Adjust this as needed
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
        },
      }}>
            <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Gold} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>1st Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{state?.winnerTakeAll ? state?.prize?.toLocaleString() : sharingFormula?.first?.toLocaleString()}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
            <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Silver} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>2nd Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.second?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
            <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>3rd Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.third?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
           {sharingFormula?.fourth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>4th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.fourth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.fifth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>5th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.fifth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.sixth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>6th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.sixth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.seventh && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>7th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.seventh?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.eighth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>8th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.eighth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.ninth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>9th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.ninth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
           {sharingFormula?.tenth && <Box flex={"1 0 200px"} p="1rem" borderRadius={"8px"} boxShadow="0px 4px 16px 0px #00000014">
                <Flex justifyContent={"center"}>
                    <Box>
                        <Image src={Bronze} />
                        <Text fontSize={".9rem"} mt=".5rem" color="#0A0A0B"  ml="-.7rem"><strong>10th Position</strong></Text>
                        <Flex alignItems={"center"} gap=".2rem" mt=".5rem" ml=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{!state?.winnerTakeAll ? sharingFormula?.tenth?.toLocaleString() : 0}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>}
        </Flex>

        <Accordion allowToggle mt="1.5rem" defaultIndex={[0]} allowMultiple>
            <AccordionItem>
                <h2>
                <AccordionButton _focus={{border: "none"}}>
                    <Box as="span" flex='1' textAlign='left' py=".5rem">
                        <Text fontWeight={"700"}>Tournament Details</Text>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pt=".5rem">
                    <Flex gap="1.5rem" flexWrap={"wrap"}>
                        {moreDetails?.map(({title, val}) => (
                            <Box key={title} boxShadow="0px 2px 8px 0px #00000014" flex={"1 1 400px"}>
                                <Text fontSize={".95rem"} p=".5rem" bg="#CFD0D3" textTransform={"uppercase"}>{title}</Text>
                                <Text fontSize={".95rem"} p=".5rem">{val}</Text>
                            </Box>
                        ))}
                        
                    </Flex>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton _focus={{border: "none"}}>
                    <Box as="span" flex='1' textAlign='left' py=".5rem">
                        <Text fontWeight={"700"}>How to play</Text>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Text mb=".4rem" fontSize={".95rem"}><CheckCircleIcon mr=".3rem" transform={"translateY(-1px)"} color={"#B5B6BA"} />Login or Register for free.</Text>
                    <Text mb=".4rem" fontSize={".95rem"}><CheckCircleIcon mr=".3rem" transform={"translateY(-1px)"} color={"#B5B6BA"} />Connect {state?.gameId} to your Gameporte account.</Text>
                    <Text fontSize={".95rem"}><CheckCircleIcon mr=".3rem" transform={"translateY(-1px)"} color={"#B5B6BA"} />Start playing.</Text>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    </Box>
  )
}

export default Overview