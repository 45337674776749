import axios from 'axios';

const baseURL = process.env.REACT_APP_PORTESHOP_BASE_URL;

const axiosInstance2 = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json ',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
});

const addTokenToRequest = async (req) => {
  const token = localStorage.getItem('GPTK');
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance2.interceptors.request.use(addTokenToRequest);

export default axiosInstance2;
