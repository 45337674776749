import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/customInput";
import CustomTextArea from "../../../../components/customTextArea/customTextArea";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Text,
  Image,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../../../services/api";
import ImageUploader from "../../../../components/imageUploader/imageUploader";
import CustomToast from "../../../../components/customToast/customToast";
import UploadIcon from "../../../../components/imageUploader/upload.svg";

const EditGame = ({ setTriggerClose, setRefetch, consoles, gameData }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(gameData?.game?.name);
  const [consoleIds, setConsoleIds] = useState(gameData?.game?.console);
  const [imageURL, setImageURL] = useState(gameData?.game?.lightModeImageUrl);
  const [imageURL2, setImageURL2] = useState(gameData?.game?.darkModeImageUrl);
  const [imageURL3, setImageURL3] = useState(gameData?.game?.bannerUrl);
  const [image, setImage] = useState(gameData?.game?.lightModeImageUrl);
  const [image2, setImage2] = useState(gameData?.game?.darkModeImageUrl);
  const [bannerImage, setBannerImage] = useState(gameData?.game?.bannerUrl);
  const [base64Image, setBase64Image] = useState(
    gameData?.game?.base64LightModeImage
  );
  const [base64Image2, setBase64Image2] = useState(
    gameData?.game?.base64DarkModeImage
  );
  const [base64BannerImage3, setBase64BannerImage] = useState(
    gameData?.game?.base64BannerImage
  );
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);

  const defVals = consoleIds?.map((i) => JSON.stringify(i));

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setImage(images[0]);
  };
  const handleImagesUpload2 = (images) => {
    setImage2(images[0]);
  };
  const handleImagesUpload3 = (images) => {
    setBannerImage(images[0]);
  };

  const handleCheckboxChange = (event) => {
    const value = JSON.parse(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!consoleIds.includes(value?.id)) {
        setConsoleIds([...consoleIds, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = consoleIds.filter((item) => item?.id !== value?.id);
      setConsoleIds(filteredItems);
    }
  };

  const showToast = (type, message) => {
    toast({
      render: () => <CustomToast type={type} message={message} />,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const editGame = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let base64LightModeImage =
        image instanceof Blob ? await convertToBase64(image) : base64Image;
      let base64DarkModeImage =
        image2 instanceof Blob ? await convertToBase64(image2) : base64Image2;
      let base64BannerImage =
        bannerImage instanceof Blob
          ? await convertToBase64(bannerImage)
          : base64BannerImage3;

      const formData = new FormData();
      const formData2 = new FormData();
      const formData3 = new FormData();
      formData.append("file", image instanceof Blob ? image : null);
      formData2.append("file", image2 instanceof Blob ? image2 : null);
      formData3.append(
        "file",
        bannerImage instanceof Blob ? bannerImage : null
      );

      let img1, img2, img3;

      if (image !== gameData?.game.lightModeImageUrl) {
        img1 = await axiosInstance.post("/file/upload", formData);
      }
      if (image2 !== gameData?.game.darkModeImageUrl) {
        img2 = await axiosInstance.post("/file/upload", formData2);
      }
      if (bannerImage !== gameData?.game.bannerUrl) {
        img3 = await axiosInstance.post("/file/upload", formData3);
      }

      const datax = {
        name,
        lightModeImageUrl: img1?.data?.data || image,
        darkModeImageUrl: img2?.data?.data || image2,
        bannerUrl: img3?.data?.data || bannerImage,
        availableConsoleIds: consoleIds.map((i) => i.id),
        base64LightModeImage,
        base64DarkModeImage,
        base64BannerImage,
      };

      const response = await axiosInstance.put(
        `/games/${gameData?.game?.id}/update`,
        datax
      );
      toast({
        description: response?.data?.message || "Game Updated",
        status: "success",
        position: "top",
      });
      setTriggerClose("close" + Math.random());

      const refetchData = () => {
        setRefetch(Math.random());
      };
      refetchData();
    } catch (error) {
      showToast("error", error?.message || error?.response?.data?.errors[0]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <form onSubmit={editGame}>
        <CustomInput
          type={"text"}
          required
          placeholder={"Game Name"}
          onChange={(e) => setName(e.target.value)}
          value={name}
          mb=".8rem"
        />
        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Lightmode Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {image && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${
                  image instanceof Blob ? URL.createObjectURL(image) : image
                })`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}

            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {image ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {image instanceof Blob ? image.name : "Upload image here"}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Darkmode Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {image2 && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${
                  image2 instanceof Blob ? URL.createObjectURL(image2) : image2
                })`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {image2 ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {image2 instanceof Blob ? image2.name : "Upload image here"}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setImage2(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Banner Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {bannerImage && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${
                  bannerImage instanceof Blob
                    ? URL.createObjectURL(bannerImage)
                    : bannerImage
                })`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {bannerImage ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {bannerImage instanceof Blob
                    ? bannerImage.name
                    : "Upload image here"}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setBannerImage(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <CheckboxGroup colorScheme="green" defaultValue={defVals}>
          <HStack
            mt="2rem"
            flexWrap={"wrap"}
            spacing={[1, 5]}
            direction={["column", "row"]}
          >
            {consoles?.map(({ console }) => {
              return (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e)}
                  key={console?.id}
                  value={JSON.stringify(console)}
                >
                  {console?.name}
                </Checkbox>
              );
            })}
          </HStack>
        </CheckboxGroup>

        <PrimaryButton
          mt="2rem"
          text="Edit Game"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default EditGame;
