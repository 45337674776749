import React, { useState, useEffect } from "react";
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import CreateGiftCard from "./createGiftCard"; 
import CreateGiftCardType from "./createGiftCardType";
import UploadGiftCard from "./uploadGiftCard";
import SecondaryButton from "../../../../components/secondaryButton/secondarybutton";

const MultiPhaseDrawer = ({ setRefetch, consoles, countries,  }) => {
    const [imageURL, setImageURL] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPhase, setCurrentPhase] = useState(1); 
  const [name, setName] = useState("");
  const [giftCardEntries, setGiftCardEntries] = useState([]);
  const handleNext = () => setCurrentPhase((prev) => prev + 1);
  const handlePrevious = () => setCurrentPhase((prev) => prev - 1);

  const renderPhase = () => {
    switch (currentPhase) {
      case 1:
        return (
          <CreateGiftCard
            setTriggerClose={onClose}
            setRefetch={setRefetch}
            consoles={consoles}
            goToNextPhase={handleNext}
            name={name}
            setName={setName}
            imageURL={imageURL}
            setImageURL={setImageURL}
            currentPhase={currentPhase}
            setCurrentPhase={setCurrentPhase}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      case 2:
        return (
          <CreateGiftCardType
            setTriggerClose={onClose}
            setRefetch={setRefetch}
            countries={countries}
            goToNextPhase={handleNext}
            imageURL={imageURL}
            currentPhase={currentPhase}
            setCurrentPhase={setCurrentPhase}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            giftCardEntries={giftCardEntries}
            setGiftCardEntries={setGiftCardEntries}
          />
        );
      case 3:
        return (
          <UploadGiftCard
            setTriggerClose={onClose}
            max={1}
            setRefetch={setRefetch}
            name={name}
            currentPhase={currentPhase}
            imageURL={imageURL}
            setCurrentPhase={setCurrentPhase}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            giftCardEntries={giftCardEntries}
            setGiftCardEntries={setGiftCardEntries}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log("MultiPhaseDrawer - Gift Card Entries Changed:", giftCardEntries);
  }, [giftCardEntries]);

  return (
    <>
      <SecondaryButton
        onClick={onOpen}
        text="Add New Gift Card"
        mr={"1rem"}
        icon="add"
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text>Add Gift cards</Text>
          </DrawerHeader>
          <DrawerBody>
            {/* <Text
              fontSize={"16px"}
            >{`Gift card details (Step${currentPhase}/3)`}</Text> */}
            {renderPhase()}
            {/* <Box mt={4}>
              {currentPhase > 1 && (
                <Button onClick={handlePrevious} mr={4}>
                  Previous
                </Button>
              )}
              {currentPhase < 3 && <Button onClick={handleNext}>Next</Button>}
            </Box> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MultiPhaseDrawer;
