import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {MdVerified} from 'react-icons/md'
import { RxCalendar } from 'react-icons/rx'
import moment from 'moment'
import axiosInstance from '../../../../../services/api'
import CopyButton from '../../../../../components/copyButton'
import SpinLoader from '../../../../../components/spinLoader/spinLoader'
import EmptyState from '../../../../../components/emptyState/emptyState'
import { FiCircle, FiUsers } from 'react-icons/fi'

const ViewGamer = ({data, title, type}) => {
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState({
    type: "",
    message: ""
  })
  const [walletDetails, setWalletDetails] = useState([]);


  const getUserWallet = async(username) => {
    setLoading(true)
    try {
        const res = await axiosInstance.get(`wallet/details/${data?.username}`)
        setWalletDetails(res?.data?.data)
    } catch(error) {
        setRes({
            type: "error",
            message: error?.response?.data?.errors[0]
        })
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getUserWallet()
  }, [data])


  const personalData = [
    {
      title: `${title} ID`,
      value: data?.id,
      icon: <FiUsers color='#98A2B3' />
    },
    {
      title: `${title} DOB`,
      value: data?.dateOfBirth,
      icon: <RxCalendar color='#98A2B3' />
    },
    {
      title: `${title} Staus`,
      value: data?.status,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: "Date Joined",
      value: data?.createdAt ? moment(data?.createdAt)?.calendar() : null,
      icon: <RxCalendar color='#98A2B3' />
    }
  ]

  const walletData = [
    {
      title: 'ACCOUNT NAME',
      value: walletDetails?.virtualBankAccountName,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'ACCOUNT NUMBER',
      value: walletDetails?.virtualBankAccountNumber,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'BANK NAME',
      value: walletDetails?.virtualBankName,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'NIP CODE',
      value: walletDetails?.virtualBankNipCode,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'NUBAN ID',
      value: walletDetails?.virtualNubanId,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'SUB ACCOUNT ID',
      value: walletDetails?.subAccountId,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'SUB ACCOUNT TYPE',
      value: walletDetails?.subAccountType,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'PARENT ACCOUNT ID',
      value: walletDetails?.parentAccountId,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'CUSTOMER ID',
      value: walletDetails?.customerId,
      icon: <FiCircle color='#98A2B3' />
    },
    {
      title: 'STATUS',
      value: walletDetails?.status,
      icon: <FiCircle color='#98A2B3' />
    },
  ]

  return (
    <Box>
        <Flex alignItems={"center"} gap=".7rem">
            <Box height={"45px"} width={"45px"} position={"relative"} bg="lightgray" backgroundImage={data?.profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
              {data?.isApproved && <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-10%", transform: "scale(1.2)"}} />}
            </Box>
            <Box>
              <Text mb=".1rem" color={"#101828"} fontSize={"1.1rem"} fontWeight={"500"} textTransform={"capitalize"}>{data?.username}</Text>
              <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>{data?.email}</Text>
            </Box>
        </Flex>
        <Box mt="3rem">
          {personalData?.map(({title, value, icon}) => (
            <Flex key={title} mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"} gap="1rem">
                {icon}
                <Box>
                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                  <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
                </Box>
              </Flex>
              <CopyButton toCopy={value} />
            </Flex>
          ))}
        </Box>
        <Box mt="2.7rem">
          <Text mb="1.5rem">WALLET DETAILS</Text>
          {loading ? 
          <SpinLoader />
          :
          res?.type === 'error' ?
          <EmptyState text={res?.message} />
          :
          <>
          {walletData?.map(({title, value, icon}) => (
            <Flex key={title} mb="1.3rem" alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"} gap="1rem">
                {icon}
                <Box>
                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                  <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
                </Box>
              </Flex>
              <CopyButton toCopy={value} />
            </Flex>
          ))}
          </>}

        </Box>
    </Box>
  )
}

export default ViewGamer;