import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Input,
  VStack,
  Text,
  Image,
  Flex,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import DropDown from "../../assets/arrowDown.png";
import RemoveIcon from "../../assets/remove.png";
import axiosInstance from '../../services/api';

const SearchableSelect = ({
  options: initialOptions = [],
  placeholder,
  onChange,
  value = [],
  isMulti
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(Array.isArray(initialOptions) ? initialOptions : []);
  const [searchTerm, setSearchTerm] = useState('');
  const menuRef = useRef(null);

  const fetchOptions = async (query) => {
    if (query) {
      try {
        const response = await axiosInstance.get(`/users/search?username=${query}`);
        setOptions(Array.isArray(response.data.data.content) ? response.data.data.content : []); 
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setOptions(Array.isArray(initialOptions) ? initialOptions : []);
    }
  };

  useEffect(() => {
    fetchOptions(searchTerm);
  }, [searchTerm]);

  const handleSelect = (option) => {
    let newValue;
  
    if (isMulti) {
      if (option.id === 0) {
        newValue = value.includes("all") ? [] : ["all"];
      } else {
        if (Array.isArray(value)) {
          if (value.includes("all")) {
            newValue = [option];
          } else if (value.some(val => val.id === option.id)) {
            newValue = value.filter((val) => val.id !== option.id);
          } else {
            newValue = [...value, option];
          }
        } else {
          newValue = [option];
        }
      }
    } else {
      newValue = option;
    }
  
    onChange({ target: { value: newValue } });
    if (!isMulti) setIsOpen(false);
  };

  const handleRemove = (optionId, e) => {
    e.stopPropagation();
    const newValue = value.filter((val) => val.id !== optionId);
    onChange({ target: { value: newValue } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedOptions = isMulti ? value : [value].filter(Boolean);

  const renderOptionText = (option) => {
    return option.id === 0 ? option.title : option.username;
  };

  return (
    <Box position="relative" minH="20px">
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        borderRadius="md"
        borderColor="gray.300"
        borderWidth="1px"
        p={2}
        bg="white"
        minH="40px"
        onClick={() => setIsOpen(!isOpen)}
        cursor="pointer"
      >
        {selectedOptions.length > 0 ? (
          selectedOptions.map((option) => (
            <Tag
              key={option.id}
              size="md"
              borderRadius="full"
              bgColor="#BFCBD929"
              m={1}
            >
               <TagLabel>{option === "all" ? "All Users" : renderOptionText(option)}</TagLabel>
              <Box 
                as="button"
                ml={1}
                onClick={(e) => handleRemove(option.id, e)}
              >
                <Image
                  src={RemoveIcon}
                  alt="Remove"
                  boxSize="16px"
                />
              </Box>
            </Tag>
          ))
        ) : (
          <Text color="gray.500" fontSize="14px">{placeholder}</Text>
        )}
        <Image
          src={DropDown}
          alt="Toggle dropdown"
          position="absolute"
          right={3}
          top="50%"
          transform="translateY(-50%)"
          zIndex={1}
          cursor="pointer"
          boxSize="15px"
        />
      </Box>

      {isOpen && (
        <Box
          ref={menuRef}
          position="absolute"
          top="100%"
          left={0}
          right={0}
          borderWidth="1px"
          borderRadius="md"
          mt={1}
          bg="white"
          boxShadow="md"
          maxHeight="200px"
          overflowY="auto"
          zIndex={2}
        >
          <Box p={2}>
            <Input
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              resize="none"
            />
          </Box>
          <VStack align="stretch" spacing={0}>
            {options.map((option) => (
              <Flex
                key={option.id}
                px={3}
                py={2}
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                onClick={() => handleSelect(option)}
                justifyContent="space-between"
                alignItems="center"
              >
               <Text>{renderOptionText(option)}</Text>
               {isMulti && (value.includes("all") ? option.id === 0 : value.some(val => val.id === option.id)) && (
                  <Image
                    src={RemoveIcon}
                    alt="Remove"
                    boxSize="16px"
                    onClick={(e) => handleRemove(option.id, e)}
                  />
                )}
              </Flex>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default SearchableSelect;