import { CheckCircleIcon, LinkIcon } from "@chakra-ui/icons"
import { Avatar, Box, Flex, Text } from "@chakra-ui/react"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react'

const Teams = ({state}) => {
  return (
    <Box>
        <Text fontWeight={"600"} fontSize={"1.2rem"}>Verified {state?.participantType === "TEAM" ? "Teams" : "Individuals"}</Text>
        <Text fontWeight={"500"} fontSize={".9rem"}>Confirmed participants for this tournament</Text>

        {state?.participantType === "TEAM" ?
        state?.teams?.map(({captain, gameId, groupLink, id, img, members, name}) => {
            return (
                <Accordion allowToggle mt="1.5rem" key={id}>
                    <AccordionItem>
                        <h2>
                        <AccordionButton _focus={{border: "none"}}>
                            <Box as="span" flex='1' textAlign='left' py=".5rem">
                                <Flex justifyContent={"space-between"} alignItems={"center"}>
                                    <Flex alignItems={"center"} gap=".7rem">
                                        <Avatar borderRadius={"4px"} src={img} />
                                        <Text fontWeight={"600"}>{name}</Text>
                                    </Flex>
                                </Flex>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} pt=".5rem">
                            <Flex justifyContent={"space-between"} mb=".7rem">
                                <Text fontWeight={"600"}>Members</Text>
                                <a href={groupLink} target="_blank" rel="noopener noreferrer"><LinkIcon /></a>
                            </Flex>
                            <Box width={"90%"} mx="auto">
                                {members?.map(({username, id, imageUrl}) => (
                                    <Flex alignItems={"center"} gap=".7rem" mb=".7rem">
                                        <Avatar size="sm" borderRadius={"4px"} src={imageUrl} />
                                        <Text fontSize={".9rem"} fontWeight={"600"}>{username}</Text>
                                    </Flex>
                                ))}
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            )})
        :
        state?.individuals?.map((i) => (
            <Flex mt="1rem" justifyContent={"space-between"} alignItems={"center"}>
                <Flex alignItems={"center"} gap=".7rem">
                    <Avatar borderRadius={"4px"} src={i?.imageUrl} />
                    <Text fontWeight={"600"} textTransform={"capitalize"}>{i?.username}</Text>
                </Flex>
            </Flex>
        ))
        }
    </Box>
  )
}

export default Teams