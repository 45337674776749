import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  Spinner,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  Switch,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axiosInstance from '../../../services/api';
import SecondaryButton from '../../../components/secondaryButton/secondarybutton';
import EmptyState from '../../../components/emptyState/emptyState';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import LineSkeleton from '../../../components/lineSkeleton';
import { buildResourceURL, truncateText } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import ExportCSV from '../../../components/exportCsv';
import CustomInput from '../../../components/customInput/customInput';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactQuill from 'react-quill';
import CustomSelect from '../../../components/customSelect/customSelect';
import Metrics from '../../../components/metrics/metrics';
import CustomToast from "../../../components/customToast/customToast"

const Tournaments = ({ clientId }) => {
  const [tournaments, setTournaments] = useState([]);
  const [totalTournaments, setTotalTournaments] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingc, setLoadingc] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [loadingq, setLoadingq] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState('');
  const [status, setStatus] = useState('');
  const [games, setGames] = useState([]);
  const [consoles, setConsoles] = useState([]);
  const [game, setGame] = useState('');
  const [consolex, setConsolex] = useState('');
  // const [date, setDate] = useState('');
  const [isVisible, setIsVisible] = useState(tournaments.isVisible); 
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const [id, setId] = useState('');
  const [title, setTitle] = useState(id?.title);
  const [desc, setDesc] = useState(id?.description);
  const [date, setDate] = useState(id?.startDateTime);
  const [regulations, setRegulations] = useState(id?.regulations);
  const [tournamentImage, setTournamentImage] = useState(id?.tournamentImage);
  const [imageUrl, setImageUrl] = useState(id?.imageUrl);
  const [numberOfIndividuals, setNumberOfIndividuals] = useState(
    id?.numberOfIndividuals
  );
  const [bracketType, setBracketType] = useState(id?.bracketType);
  const [groupChatLink, setGroupChatLink] = useState(id?.groupChatLink);
  const [placementSystemId, setPlacementSystemId] = useState(
    id?.placementSystemId
  );
  const [gameFormat, setGameFormat] = useState(id?.gameFormat);
  const [numberOfTeams, setNumberOfTeams] = useState(id?.numberOfTeams);
  const [gameMode, setGameMode] = useState(id?.gameMode);

  const [lobbyId, setLobbyId] = useState('');
  const [lobbyPassword, setLobbyPassword] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean']
    ],
    // Remove deprecated event listeners
    clipboard: {
      matchVisual: false
    }
  };

  const queryParams = [
    {
      key: 'size',
      value: '50',
    },
    {
      key: 'status',
      value: status === 'all' ? '' : status,
    },
    {
      key: 'gameId',
      value: game === 'all' ? '' : game,
    },
    {
      key: 'consoleId',
      value: consolex === 'all' ? '' : consolex,
    },
  ];
  
  const handleToggle = async (id, currentVisibility) => {
    setTournaments((prevTournaments) =>
        prevTournaments.map((tournament) =>
            tournament.id === id
                ? { ...tournament, hidden: !currentVisibility }
                : tournament
        )
    );
    try {
        const { data } = await axiosInstance.put(`/tournaments/toggle-visibility/${id}`, {
            hidden: !currentVisibility,
        });
        const updatedTournament = data.data;
        console.log(`Updated hidden status for tournament id ${updatedTournament.id} : ${updatedTournament.hidden}`);
        console.log('Response data:', data);

        setTournaments((prevTournaments) =>
            prevTournaments.map((tournament) =>
                tournament.id === id
                    ? { ...tournament, hidden: updatedTournament.hidden }
                    : tournament
            )
        );
    } catch (error) {
        console.error(error?.response?.data?.message || 'Error toggling visibility');
        setTournaments((prevTournaments) =>
            prevTournaments.map((tournament) =>
                tournament.id === id
                    ? { ...tournament, hidden: currentVisibility }
                    : tournament
            )
        );
    }
};


const getTournaments = async () => {
  setLoading(true);
  try {
    const { data } = await axiosInstance.get(
      buildResourceURL(
        currentUser?.role[0]?.name === 'ADMIN' ||
          currentUser?.role[0]?.name === 'COMMUNITY_MANAGER'
          ? '/tournaments'
          : '/tournaments/all',
        queryParams
      )
    );
    setTournaments(data?.data?.content);
    setTotalTournaments(data?.data?.content?.length);
  } catch (error) {
    console.error(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};


  // useEffect(() => {
  //     axiosInstance
  //       .get(
  //         currentUser?.role[currentUser?.role?.length - 2]?.name ===
  //           'COMMUNITY_REP'
  //           ? `${baseurl}/tournaments/all?size=50`
  //           : currentUser?.role[currentUser?.role?.length - 2]?.name === 'GAMER'
  //           ? `${baseurl}/tournaments/all?size=50`
  //           : `${baseurl}/tournaments?size=50`
  //       )
  //       // .then(res => (console.log(res?.data?.data?.content)))
  //       .then((res) => setItems(res?.data?.data?.content))
  //       .then((res) => setLoading(false))
  //       .catch((error) => console.log(error?.response));
  //   }, [loadingc]);

  useEffect(() => {
    getTournaments();
  }, [refetch, status, game, consolex]);

  const getGamesAndConsoles = async () => {
    setLoadingy(true);
    try {
      const [res1, res2] = await Promise.all([
        axiosInstance.get(`/games?size=20`),
        axiosInstance.get(`/console`),
      ]);
      setGames(res1?.data?.data?.content);
      setConsoles(res2?.data?.data?.content);
    } catch (error) {
      console.error(error?.response?.data?.errors[0]);
    } finally {
      setLoadingy(false);
    }
  };

  useEffect(() => {
    getGamesAndConsoles();
  }, []);

  const handleModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user);
    onOpen();
  };

  // const handleCloseModal = () => {
  //   onClose();  // Close the modal
  //   setModalType(null);  // Reset modalType
  //   setSelectedTournament(null); // Reset selected tournament
  // };
  
  
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure(); 
  
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure(); 
  
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure(); 

  const handleEdit = (tournament) => {
    setTitle(tournament?.title);
    setDesc(tournament?.description);
    const formattedDate = tournament?.startDateTime?.includes(':00') 
    ? tournament.startDateTime 
    : `${tournament?.startDateTime}:00`;
  setDate(formattedDate || '');
    setRegulations(tournament.regulations);
    setImageUrl(tournament?.imageUrl);
    setNumberOfIndividuals(tournament?.numberOfIndividuals);
    setBracketType(tournament?.bracketType);
    setGroupChatLink(tournament?.groupChatLink);
    setPlacementSystemId(tournament?.placementSystemId);
    setGameFormat(tournament?.gameFormat);
    setNumberOfTeams(tournament?.numberOfTeams);
    setGameMode(tournament?.gameMode);
    setTournamentImage(tournament?.tournamentImage);

    setId(tournament.id); 
    onOpen2(); 
  };
  
  const handleLobby = (tournament) => {
    setTitle(tournament.title);
    setId(tournament.id);
    onOpen5();
  };
  
  const handleDelete = (tournamentId) => {
    setId(tournamentId);
    onOpen3();
    console.log(tournamentId)
  };
  
  const handleCloseModal = () => {
    onClose2(); 
    setModalType(null); 
    setSelectedTournament(null); 
  };
  
  const handleEditSubmit = async () => {
    setLoadingc(true);
    
    let formattedDate;
    if (date === id?.startDateTime) {
      formattedDate = date;
    } else {
      formattedDate = date.includes(':00') ? date : `${date}:00`;
    }
  
    const datax = {
      title,
      description: desc,
      startDateTime: formattedDate,
      regulations,
      tournamentImage: imageUrl,
      imageUrl,
      numberOfIndividuals: numberOfIndividuals || 0,
      bracketType: bracketType,
      groupChatLink: groupChatLink,
      placementSystemId: placementSystemId,
      gameFormat: gameFormat,
      numberOfTeams: numberOfTeams || 0,
      gameMode: gameMode,
    };
  
    try {
      const { data } = await axiosInstance.put(`/tournaments/${id}`, datax);
      toast({
        render: () => <CustomToast type={"success"} message="Tournament Updated" />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      onClose2();
      setRefetch(Date.now());
    } catch (error) {
      toast({
        render: () => <CustomToast type={"error"} message={error?.response?.data?.errors[0]} />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingc(false);
    }
  };
  
  const handleAddLobby = async () => {
    setLoadingq(true);
    const datax = {
      externalLobbyId: lobbyId,
      externalLobbyPassword: lobbyPassword,
    };
  
    try {
      const { data } = await axiosInstance.put(`/tournaments/${id}/lobby`, datax);
      toast({
        render: () => <CustomToast type={"success"} message="Lobby details updated" />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      onClose5(); // Close Add Lobby modal after success
    } catch (error) {
      toast({
        render: () => <CustomToast type={"error"} message={error?.response?.data?.errors[0]} />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingq(false);
    }
  };
  
  const deleteTournament = async () => {
    setLoadingc(true);
    try {
      const { data } = await axiosInstance.delete(`/tournaments/${id}`, {});
      console.log(`/tournaments/${id}`);

      toast({
        render: () => <CustomToast type={"success"} message={data?.message} />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      onClose3();
    } catch (error) {
      toast({
        render: () => <CustomToast type={"error"} message={error?.response?.data?.errors[0]} />,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingc(false);
    }
  };

  const suspendEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `initiatives/${selectedUser?.id}/soft`
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingx(false);
    }
  };

  const restoreEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.patch(
        `initiatives/${selectedUser?.id}/restore`
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingx(false);
    }
  };

  const deleteEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `initiatives/${selectedUser?.id}`
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingx(false);
    }
  };

  const startInitiative = async (val) => {
    setLoadingx(true);
    const datax = date
      ? {
          status: val,
          date,
        }
      : {
          status: val,
        };
    try {
      const { data } = await axiosInstance.patch(
        `initiatives/${selectedUser?.id}/change-status`,
        datax
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingx(false);
    }
  };

  const tourneyData = [
    {
      title: 'Total Created Tournaments',
      value: !loading ? tournaments?.length : null,
    },
    {
      title: 'Ongoing Tournaments',
      value: !loading
        ? tournaments?.filter((i) => i.status === 'IN_PROGRESS')?.length
        : null,
    },
    {
      title: 'Pending Tournaments',
      value: !loading
        ? tournaments?.filter(
            (i) => i.status === 'PENDING' && i.approved !== true
          )?.length
        : null,
    },
    {
      title: 'All Closed Tournaments',
      value: !loading
        ? tournaments?.filter((i) => i.status === 'ENDED')?.length
        : null,
    },
  ];

  const statusOptions = [
    {
      title: 'All',
      value: 'all',
    },
    {
      title: 'Pending',
      value: 'PENDING',
    },
    {
      title: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      title: 'Ended',
      value: 'ENDED',
    },
  ];

  const gameOptions = [
    {
      title: 'All Games',
      value: 'all',
    },
    ...games?.map((i) => ({
      title: i?.game?.name,
      value: i?.game?.id,
    })),
  ];

  const consoleOptions = [
    {
      title: 'All Consoles',
      value: 'all',
    },
    ...consoles?.map((i) => ({
      title: i?.console?.name,
      value: i?.console?.id,
    })),
  ];


  return (
    <>
      <Metrics data={tourneyData} loading={loading} />
      <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={'12px'}overflowX="auto"
    whiteSpace="nowrap"
    sx={{
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}>
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Tournaments{' '}
            <span
              style={{
                padding: '.1rem .3rem',
                borderRadius: '50%',
                backgroundColor: '#F9FAFB',
                border: '1px solid #EAECF0',
                fontSize: '12px',
                fontWeight: '500',
                color: '#344054',
              }}
            >
              {totalTournaments}
            </span>
          </Text>

          <Flex alignItems={'center'} gap="1rem">
            {/* <ExportCSV headers={headers} items={Initiatives} /> */}
            <CustomSelect
              required
              options={statusOptions}
              onChange={(e) => setStatus(e.target.value)}
              height="33px !important"
              mt="0"
              borderRadius={'8px'}
              width="120px"
              cursor="pointer"
              fontSize=".85rem"
            />
            <CustomSelect
              required
              options={gameOptions}
              onChange={(e) => setGame(e.target.value)}
              height="33px !important"
              mt="0"
              borderRadius={'8px'}
              width="120px"
              cursor="pointer"
              fontSize=".85rem"
            />
            <CustomSelect
              required
              options={consoleOptions}
              onChange={(e) => setConsolex(e.target.value)}
              height="33px !important"
              mt="0"
              borderRadius={'8px'}
              width="128px"
              cursor="pointer"
              fontSize=".85rem"
            />
            <SecondaryButton
              onClick={() => navigate('/initiatives/create/1')}
              text={'Create Tournament'}
              icon="add"
              loading={false}
            />
          </Flex>
        </Flex>
        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                ID
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Name
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Teams
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Entry Fee
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Prize Pool
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                CR
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Date
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                Status
              </Th>
              <Th
               textTransform={'capitalize !important'}
               whiteSpace={'nowrap'}
               color={'#475467'}>
                Hide/Unhide
              </Th>
              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
            </Tr>
          </Thead>

          {loading ? (
            <LineSkeleton width="100%" num={10} />
          ) : tournaments?.length < 1 ? (
            <EmptyState mt="1.5rem" text="No tournaments created yet" />
          ) : (
            <Tbody>
              {tournaments?.map(
                ({
                  id,
                  title,
                  approved,
                  assignedCRs,
                  status,
                  participantType,
                  entryFee,
                  description,
                  regulations,
                  bracketType,
                  gameFormat,
                  gameMode,
                  groupChatLink,
                  placementSystemId,
                  prize,
                  imageUrl,
                  numberOfTeams,
                  numberOfIndividuals,
                  entryType,
                  startDateTime,
                  hidden,
                }) => {
                  return (
                    <Tr key={id}>
                      <Td color={'#475467'} fontSize={'.8rem'}>
                        #{id}
                      </Td>
                      <Td>
                        <Flex alignItems={'center'} gap=".4rem">
                          <Box
                            height={'40px'}
                            width={'40px'}
                            borderRadius={'4px'}
                            backgroundImage={imageUrl}
                            backgroundSize={'cover'}
                            backgroundRepeat={'no-repeat'}
                          />
                          <Box>
                            <Text
                              mb=".2rem"
                              color={'#101828'}
                              fontSize={'.8rem'}
                              fontWeight={'500'}
                              textTransform={'capitalize'}
                            >
                              {truncateText(title, 30)}
                            </Text>
                            <Text
                              mb=".2rem"
                              color={'#101828'}
                              fontSize={'.75rem'}
                              fontWeight={'400'}
                              textTransform={'capitalize'}
                            >
                              {truncateText(description, 17)}
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td
                        color={'#475467'}
                        fontWeight={'500'}
                        fontSize={'.8rem'}
                      >
                        {participantType === 'TEAM'
                          ? numberOfTeams
                          : numberOfIndividuals}
                      </Td>
                      <Td color={'#475467'} fontSize={'.8rem'}>
                        {entryType === 'FREE'
                          ? 'FREE'
                          : '₦' + entryFee?.toLocaleString()}
                      </Td>
                      <Td color={'#475467'} fontSize={'.8rem'}>
                        {' '}
                        ₦{prize?.toLocaleString()}
                      </Td>
                      <Td
                        color={'#475467'}
                        fontWeight={'500'}
                        fontSize={'.75rem'}
                        textTransform={'capitalize'}
                      >
                        {assignedCRs[0]?.username || '--'}
                      </Td>
                      <Td
                        color={'#475467'}
                        fontWeight={'500'}
                        fontSize={'.75rem'}
                        textTransform={'capitalize'}
                      >
                        {moment(startDateTime).calendar()}
                      </Td>
                      <Td>
                        <Text
                          textAlign={'center'}
                          fontWeight={'500'}
                          fontSize={'.6rem'}
                          p=".1rem .1rem"
                          borderRadius={'16px'}
                          border={
                            currentUser?.role[0]?.name ===
                              'COMMUNITY_MANAGER' &&
                            !approved &&
                            status === 'PENDING'
                              ? '1px solid crimson'
                              : status === 'PENDING'
                              ? '1px solid lightgray'
                              : status === 'IN_PROGRESS'
                              ? '1px solid goldenrod'
                              : '1px solid #ABEFC6'
                          }
                          color={
                            currentUser?.role[0]?.name ===
                              'COMMUNITY_MANAGER' &&
                            !approved &&
                            status === 'PENDING'
                              ? 'crimson'
                              : status === 'PENDING'
                              ? '#475467'
                              : status === 'IN_PROGRESS'
                              ? 'goldenrod'
                              : '#067647'
                          }
                          bg={
                            status === 'PENDING'
                              ? '#F9FAFB'
                              : status === 'IN_PROGRESS'
                              ? ''
                              : '#ECFDF3'
                          }
                        >
                          {currentUser?.role[0]?.name === 'COMMUNITY_MANAGER' &&
                          !approved &&
                          status === 'PENDING'
                            ? 'Not Approved'
                            : status}
                        </Text>
                      </Td>
                        <Td textAlign={"center"}>
                            {(status === 'IN_PROGRESS' || status === 'PENDING') && (
                                <Switch
                                    colorScheme="teal"
                                    isChecked={hidden}
                                    onChange={() => handleToggle(id, hidden)} 
                                />
                            )}
                        </Td>
                            <Td>
                              <Menu width={'200px !important'}>
                                <MenuButton>
                                  <BiDotsVerticalRounded
                                    style={{ cursor: 'pointer' }}
                                  />
                                </MenuButton>
                                <MenuList>
                                  <Divider my=".2rem" />
                                  <MenuItem
                                    onClick={() => navigate(`${id}`)}
                                    fontWeight={'500'}
                                    fontSize={'.85rem'}
                                  >
                                    View Tournament
                                  </MenuItem>
                                  {status === 'PENDING' && (
                                    <>
                                      <Divider my=".2rem" />
                                      <MenuItem
                                        onClick={() => handleEdit({id,
                                          title,
                                          description,
                                          startDateTime,
                                          regulations,
                                          imageUrl,
                                          numberOfIndividuals,
                                          bracketType,
                                          groupChatLink,
                                          placementSystemId,
                                          gameFormat,
                                          numberOfTeams,
                                          gameMode,
                                          tournamentImage})}
                                        fontWeight={'500'}
                                        fontSize={'.85rem'}
                                      >
                                        Edit Tournament
                                      </MenuItem>
                                    </>
                                  )}
                                  {(status === 'IN_PROGRESS' || status === 'PENDING') && (
                                    <>
                                      <Divider my=".2rem" />
                                      <MenuItem
                                        onClick={() => handleLobby({
                                          id,
                                          title
                                        })}
                                        fontWeight={'500'}
                                        fontSize={'.85rem'}
                                      >
                                        Add Lobby Details
                                      </MenuItem>
                                    </>
                                  )}
                                  <Divider my=".2rem" />
                                  <MenuItem
                                    onClick={() => handleDelete(id)}
                                    color={'crimson'}
                                    fontWeight={'500'}
                                    fontSize={'.85rem'}
                                  >
                                    Delete Tournament
                                  </MenuItem>
                                  <Divider my=".2rem" />
                                </MenuList>
                              </Menu>
                            </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          )}
        </Table>

        {/* <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={'1rem'}
              textTransform={'capitalize'}
            >{`${modalType} Initiative`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {modalType === 'suspend' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm suspension of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={suspendEmployee}
                      text="Suspend"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === 'restore' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm restore of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={restoreEmployee}
                      text="Restore"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === 'delete' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm deletion of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={deleteEmployee}
                      text="Delete"
                      width="150px"
                      bg="crimson !important"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === 'start' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm commencement of {selectedUser?.name}?
                  </Text>
                  <FormControl my="1.5rem">
                    <FormLabel
                      fontSize={'.85rem'}
                      transform={'translateY(2rem)'}
                      opacity={'.7'}
                    >
                      Set start date (Optional, start date would default to
                      current date if unset)
                    </FormLabel>
                  </FormControl>
                  <CustomInput
                    type={'date'}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <br />
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={() => startInitiative('Ongoing')}
                      text="Start"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === 'restart' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm date reset of {selectedUser?.name}?
                  </Text>
                  <FormControl my="1.5rem">
                    <FormLabel
                      fontSize={'.85rem'}
                      transform={'translateY(2rem)'}
                      opacity={'.7'}
                    >
                      Set start date (Optional, start date would default to
                      current date if unset)
                    </FormLabel>
                  </FormControl>
                  <CustomInput
                    type={'date'}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <br />
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={() => startInitiative('Pending')}
                      text="Start"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === 'complete' && (
                <>
                  <Text
                    textAlign={'center'}
                    color={'#475467'}
                    fontWeight={'500'}
                  >
                    Confirm completion of {selectedUser?.name}?
                  </Text>
                  <FormControl my="1.5rem">
                    <FormLabel
                      fontSize={'.85rem'}
                      transform={'translateY(2rem)'}
                      opacity={'.7'}
                    >
                      Set end date (Optional, end date would default to current
                      date if unset)
                    </FormLabel>
                  </FormControl>
                  <CustomInput
                    type={'date'}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <br />
                  <Flex justifyContent={'center'} my="1.3rem">
                    <PrimaryButton
                      onClick={() => startInitiative('Completed')}
                      text="Start"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal> */}


<Modal
        blockScrollOnMount={true}
        isOpen={isOpen2}
        onClose={onClose2}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'20px'}>
          <ModalHeader fontFamily={'Open Sans'} fontSize={'1.1rem'}>
            Edit Tournament
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily={'Open sans'} p=".5rem 1rem">
            <FormControl mb=".7rem">
              <FormLabel
                color="#0A0A0B"
                transform={'translateY(-2px)'}
                fontSize={'.9rem'}
                opacity={'.6'}
              >
                Tournament Title
              </FormLabel>
              <Input
                type="text"
                placeholder="Enter title"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                height={'45px'}
                borderRadius={'14px'}
                _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mb=".7rem">
              <FormLabel
                color="#0A0A0B"
                transform={'translateY(-2px)'}
                fontSize={'.9rem'}
                opacity={'.6'}
              >
                Description
              </FormLabel>
              <Input
                type="text"
                placeholder="Enter description"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                height={'45px'}
                borderRadius={'14px'}
                _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </FormControl>
            <FormControl mb="1.2rem">
              <FormLabel
                color="#0A0A0B"
                transform={'translateY(-2px)'}
                fontSize={'.9rem'}
                opacity={'.6'}
              >
                Start Date & Time
              </FormLabel>
              <Input
                type="datetime-local"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                min={new Date().toISOString().split('T')[0]}
                height={'45px'}
                borderRadius={'14px'}
                _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>
            <ReactQuill
              style={{ height: '130px', marginBottom: '3.5rem' }}
              theme="snow"
              value={regulations}
              onChange={setRegulations}
            />
            <PrimaryButton
              onClick={handleEditSubmit}
              type="submit"
              bg="#244CC6"
              color="#FFF"
              width="100%"
              mb="2rem"
              mt=".7rem"
              text={loadingc ? <Spinner /> : 'Edit Tournament'}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        blockScrollOnMount={true}
        isOpen={isOpen5}
        onClose={onClose5}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'20px'}>
          <ModalHeader fontFamily={'Open Sans'} fontSize={'1.1rem'}>
            Add Lobby Details for {truncateText(title, 20)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily={'Open sans'} p=".5rem 1rem">
            <FormControl mb="1rem">
              {/* <FormLabel
                color="#0A0A0B"
                transform={"translateY(-2px)"}
                fontSize={".9rem"}
                opacity={".6"}
              >
                Lobby ID
              </FormLabel> */}
              <Input
                type="text"
                placeholder="Enter Lobby ID"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                height={'45px'}
                borderRadius={'14px'}
                _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                onChange={(e) => setLobbyId(e.target.value)}
              />
            </FormControl>
            <FormControl mb=".7rem">
              {/* <FormLabel
                color="#0A0A0B"
                transform={"translateY(-2px)"}
                fontSize={".9rem"}
                opacity={".6"}
              >
                Lobby Access Code
              </FormLabel> */}
              <Input
                type="text"
                placeholder="Enter access code"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                height={'45px'}
                borderRadius={'14px'}
                _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                onChange={(e) => setLobbyPassword(e.target.value)}
              />
            </FormControl>
            <PrimaryButton
              onClick={handleAddLobby}
              type="submit"
              bg="#244CC6"
              color="#FFF"
              width="100%"
              mb="2rem"
              mt=".7rem"
              text={loadingq ? <Spinner /> : 'Add Lobby Info'}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen3} onClose={onClose3} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={'20px'} overflow={'hidden'}>
          <ModalHeader
            fontFamily={'Open Sans'}
            fontSize={'1.1rem'}
            textAlign={'center'}
            color={'#244CC6'}
          >
            Confirm Delete
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody fontFamily={'Open sans'} p="0">
            <Text my="1rem" textAlign={'center'}>
              {title}
            </Text>
            <Flex gap="1rem" padding={'1.5rem'} bg="#EDF1FF">
              <PrimaryButton
                onClick={onClose3}
                type="submit"
                bg="transparent"
                color="#244CC6"
                border="1px solid #244CC6"
                _hover={{ backgroundColor: 'transparent' }}
                width="100%"
                text="Cancel"
              />
              <PrimaryButton
                onClick={deleteTournament}
                type="submit"
                bg="#EB5757"
                color="#FFF"
                _hover={{ backgroundColor: '#EB5757' }}
                width="100%"
                text={loadingc ? <Spinner /> : 'Delete'}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      </Box>
    </>
  );
};

export default Tournaments;
