import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/customInput";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Text,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../../../services/api";
import UploadIcon from "../../../../components/imageUploader/upload.svg";
import ImageUploader from "../../../../components/imageUploader/imageUploader";

const CreateGame = ({ setTriggerClose, setRefetch, consoles }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  

  const toast = useToast();

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      if (!checkedItems.includes(value)) {
        setCheckedItems([...checkedItems, parseInt(value.slice(0, 1))]);
      }
    } else {
      const filteredItems = checkedItems.filter(
        (item) => item !== parseInt(value.slice(0, 1))
      );
      setCheckedItems(filteredItems);
    }
  };

  const createGame = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    try {
      const base64LightModeImage = await toBase64(image);
      const base64DarkModeImage = await toBase64(image2);
      const base64BannerImage = await toBase64(bannerImage);

      const formData = new FormData();
      const formDatax = new FormData();
      const formData2 = new FormData();
      formData.append("file", image);
      formDatax.append("file", image2);
      formData2.append("file", bannerImage);

      const { data } = await axiosInstance.post("/file/upload", formData);
      const dData = await axiosInstance.post("/file/upload", formDatax);
      const res = await axiosInstance.post("/file/upload", formData2);

      const datax = {
        name,
        lightModeImageUrl: data?.data,
        darkModeImageUrl: dData?.data?.data,
        bannerUrl: res?.data?.data,
        availableConsoleIds: checkedItems,
        base64LightModeImage,
        base64DarkModeImage,
        base64BannerImage,
      };

      try {
        const { data } = await axiosInstance.post("games", datax);
        toast({
          description: data?.message || "Game Created",
          status: "success",
          position: "top",
        });
        setTriggerClose("close" + Math.random());

        const refetchData = () => {
          setRefetch(Math.random());
        };
        refetchData();
      } catch (error) {
        console.error("Error creating game:", error);
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.errors[0] || "System unavailable",
          status: "error",
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error converting images to base64:", error);
      toast({
        title: "An Error Occurred",
        description: "Failed to convert images to base64",
        status: "error",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={createGame}>
        <CustomInput
          type={"text"}
          required
          placeholder={"Game Name"}
          onChange={(e) => setName(e.target.value)}
          mb=".8rem"
        />

        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Lightmode Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {image && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${URL.createObjectURL(image)})`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {image ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {image.name}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Darkmode Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {image2 && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${URL.createObjectURL(image2)})`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {image2 ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {image2.name}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setImage2(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Game Banner Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {bannerImage && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={`url(${URL.createObjectURL(bannerImage)})`}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <Flex
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              width={"100%"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              {bannerImage ? (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  {bannerImage.name}
                </Text>
              ) : (
                <Text
                  ml="1rem"
                  color={"#344054"}
                  fontSize={".9rem"}
                  whiteSpace={"nowrap"}
                >
                  Upload image here
                </Text>
              )}
              <input
                style={{
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  position: "absolute",
                  left: "0",
                }}
                type="file"
                accept="image/*"
                onChange={(e) => setBannerImage(e.target.files[0])}
              />
            </Flex>
          </Flex>
        </FormControl>

        <CheckboxGroup colorScheme="green" defaultValue={[]}>
          <HStack
            mt="2rem"
            flexWrap={"wrap"}
            spacing={[1, 5]}
            direction={["column", "row"]}
          >
            {consoles?.map(({ console }) => {
              return (
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e)}
                  key={console?.id}
                  value={console?.id + console?.name}
                >
                  {console?.name}
                </Checkbox>
              );
            })}
          </HStack>
        </CheckboxGroup>

        <PrimaryButton
          mt="2rem"
          text="Create Game"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default CreateGame;
