import React, { useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput'
import CustomTextArea from '../../../../components/customTextArea/customTextArea'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import { Box, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, HStack, useToast } from '@chakra-ui/react'
import axiosInstance from '../../../../services/api'
import ImageUploader from '../../../../components/imageUploader/imageUploader'

const EditConsole = ({setTriggerClose, setRefetch, consoleData}) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(consoleData?.console?.name)
  const [imageURL, setImageURL] = useState(consoleData?.console?.lightModeImageUrl);
  const [imageURL2, setImageURL2] = useState(consoleData?.console?.darkModeImageUrl);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };
  const handleImagesUpload2 = (images) => {
    setImageURL2(images[0])
  };

  const editConsole = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax =  {
        name,
        lightModeImageUrl: imageURL,
        darkModeImageUrl: imageURL2
    }

    try {
        const {data} = await axiosInstance.put(`/console/${consoleData?.console?.id}`, datax)
        toast({
        description: data?.message || "Console Updated",
        status: "success",
        position: 'top'
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0] || "System unavailable",
        status: "error",
        });
    } finally {
        setLoading(false)
    }
  }
  return (
    <>
        <form onSubmit={editConsole}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Console Name"}
        onChange={e => setName(e.target.value)}
        mb=".8rem"
        value={name}
        />
        <FormControl mb=".8rem">
        <FormLabel transform={"translateY(.5rem)"} opacity={".7"} fontSize={".9rem"}>Console Lightmode Image</FormLabel>
        <Flex mt="1rem" gap=".5rem">
         {imageURL !== '' && <Box backgroundColor={"lightgray"} backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} />}
         <ImageUploader
         onImagesUpload={handleImagesUpload}
         setLoading={setLoadingi}
         loading={loadingi}
         max={1}
        />
        </Flex>
        </FormControl>
        <FormControl mb=".8rem">
        <FormLabel transform={"translateY(.5rem)"} opacity={".7"} fontSize={".9rem"}>Console Darkmode Image</FormLabel>
        <Flex mt="1rem" gap=".5rem">
         {imageURL2 !== '' && <Box backgroundColor={"lightgray"} backgroundImage={imageURL2} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} />}
         <ImageUploader
         onImagesUpload={handleImagesUpload2}
         setLoading={setLoadingi2}
         loading={loadingi2}
         max={1}
        />
        </Flex>
        </FormControl>

        <PrimaryButton mt="2rem" text="Edit Console" type="submit" loading={loading} />
        </form>
    </>
  )
}

export default EditConsole;