// import { Box, Text } from '@chakra-ui/react'
// import React, { useEffect, useState } from 'react'
// import GoBack from '../../../../components/goBack'
// import Mechanics from './mechanics/mechanics'
// import CustomTabs from '../../../../components/customTabs/customTabs'
// import axiosInstance from '../../../../services/api'
// import { useParams } from 'react-router-dom'
// import SpinLoader from '../../../../components/spinLoader/spinLoader'
// import AssignStore from '../createInitiative/components/assignStore/assignStore'
// import Tasks from './tasks/tasks'
// import AggAttendance from './aggAttendance/aggAttendance'
// import Reports from './reports/reports'

// const TournamentDetails = () => {
//     const [initiative, setInitiative] = useState("")
//     const [loading, setLoading] = useState(true)
//     const [refetch, setRefetch] = useState("")
//     const {id} = useParams()

//     const getInitiative = async() => {
//         try {
//             const { data } = await axiosInstance.get(`initiatives/${id}`)
//             setInitiative(data?.data)
//             // console.log(iid, data?.data)
//         } catch(error) {
//             console.error(error?.response?.data?.message)
//         } finally {
//             setLoading(false)
//         }
//       }
    
//       useEffect(() => {
//         getInitiative()
//       }, [refetch])

//     const data = [
//         {
//             title: "Mechanics",
//             render: <></>
//         },
//         {
//             title: "Aggregate Tasks",
//             render: <></>
//         },
//         {
//             title: "Aggregate Attendance",
//             render: <></>
//         },
//         {
//             title: "Reports",
//             render: <></>
//         },
//         {
//             title: "Stores",
//             render: <></>
//         }
//     ]
//   return (
//     <Box>
//         <GoBack />
//         {loading ?
//         <SpinLoader />
//         :
//         <Box bg="#FFF" mt="1rem" p=".7rem 1rem">
//             <Text color="#1D2939" fontWeight={"500"} fontSize={"1.4rem"}>{initiative?.name}</Text>
//             <Box mt="1rem">
//                 <CustomTabs data={data} />
//             </Box>
//         </Box>}
        
        
//     </Box>
//   )
// }

// export default TournamentDetails


import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Avatar, Badge, Box, Button, Flex, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Spinner, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react'
import { BiShareAlt } from 'react-icons/bi'
import { HiUsers } from 'react-icons/hi'
import Naira from '../../../../assets/naira.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AiFillClockCircle } from 'react-icons/ai'
import Overview from './components/overview/overview'
import Bracket from './components/bracket/bracket'
import Matches from './components/matches/matches'
import Results from './components/results/results'
import Teams from './components/teams/teams'
import Rules from './components/rules/rules'
import { useEffect, useState } from 'react'
import axiosInstance from '../../../../services/api'
import baseurl from '../../../../config'
import { toast } from 'react-toastify'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'

const TournamentDetails = () => {
    const {id} = useParams()
    const navigate = useNavigate()

    const [games, setGames] = useState([])
    const [loading1, setLoading1] = useState(true)
    const [consoles, setConsoles] = useState([])
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(true)
    const [tourney, setTourney] = useState(null)
    const [reTrigger, setReTrigger] = useState("")

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        axiosInstance.get(`${baseurl}/tournaments/${id}`)
         // .then(res => (console.log(res?.data?.data)))
        .then(res => (setTourney(res?.data?.data)))
        .then(res => (setLoading4(false)))
        .catch(error => console.log(error?.response))
    }, [loading3, id])

    useEffect(() => {
        axiosInstance.get(`${baseurl}/games?size=20`)
        // .then(res => (console.log(res?.data?.data)))
        .then(res => (setGames(res?.data?.data?.content)))
        .then(res => (setLoading1(false)))
        .catch(error => console.log(error?.response))
    }, [])

    useEffect(() => {
        axiosInstance.get(`${baseurl}/console`)
        .then(res => (setConsoles(res?.data?.data?.content)))
        .then(res => (setLoading2(false)))
        .catch(error => console.log(error?.response))
    }, [])

  const [results, setResults] = useState([])
  const [loadingr, setLoadingr] = useState(true)

//   useEffect(() => {
//     axiosInstance.get(`${baseurl}/tournaments/${state?.id}/league/matches`)
//     .then(res => (console.log(res?.data?.data?.content)))
//     // .then(res => (setResults(res?.data?.data?.content)))
//     .then(res => (setLoading(false)))
//     .catch(error => console.log(error?.response))
// }, [loading])

  useEffect(() => {
    axiosInstance.get(`${baseurl}/tournaments/${tourney?.id}/${tourney?.bracketType?.toLowerCase()}/${tourney?.participantType?.toLowerCase()}/result`)
    // .then(res => (console.log(res?.data?.data)))
    .then(res => (setResults(res?.data?.data)))
    .then(res => (setLoadingr(false)))
    .catch(error => console.log(error?.response))
}, [tourney])

    const startTourney = async() => {
      setLoading3(true)
      try {
        const {data} = await axiosInstance.put(`${baseurl}/tournaments/${tourney?.id}/start`)
        toast.success("Tournament Started")
      } catch(error) {
        toast.error(error?.response?.data?.errors[0])
      } finally {
        setLoading3(false)
      }
    }
    const closeTourney = async() => {
      setLoading3(true)
      try {
        const {data} = await axiosInstance.put(`${baseurl}/tournaments/${tourney?.id}/close`)
        toast.success("Tournament Closed")
        onClose()
      } catch(error) {
        toast.error(error?.response?.data?.errors[0])
      } finally {
        setLoading3(false)
      }
    }

    const handleClose = () => {
      onOpen()
    }

  return (
    <Box>
    {loading4 ?
      <Flex mt="10rem" justifyContent={"center"}>
        <Spinner />
      </Flex>
      :
      <Box bg="#FFF" borderRadius={"10px"} padding={"1rem 2rem"} maxW={"1000px"} mx="auto">
        <Flex alignItems={"center"} justifyContent={"space-between"} mb="1.5rem">
          <Text fontSize={"1rem"} color={"#0A0A0B"} fontWeight={"600"}>Tournament Details</Text>
          {tourney?.status !== "ENDED" && <Button onClick={tourney?.status === "IN_PROGRESS" ? () => handleClose(id) : tourney?.status === "PENDING" ? startTourney : null} colorScheme={tourney?.status === "IN_PROGRESS" ? "red" : tourney?.status === "PENDING" ? "green" : ""}>{loading3 ? <Spinner /> : tourney?.status === "IN_PROGRESS" ? "Close Tournament" : "Start Tournament"}</Button>}
        </Flex>
        <Flex alignItems={"center"} justifyContent={"space-between"} mt="1.3rem">
            <ChevronLeftIcon onClick={() => navigate(-1)} cursor={"pointer"} bg="#EAEAEB" borderRadius={"4px"} transform={"scale(1.8)"} />
            {/* <BiShareAlt style={{backgroundColor: "#EAEAEB", borderRadius: "4px", padding: ".1rem", transform: "scale(1.8)", cursor: "pointer"}} /> */}
        </Flex>
        <Text fontWeight={"600"} transform={"translateY(-5px)"} mt="1.5rem">Created by {tourney?.creator?.username || "Gameporte"}</Text>
        <Box borderRadius={"16px"} height={"270px"} backgroundImage={tourney?.imageUrl} backgroundSize={"cover"} backgroundPosition={"center"}></Box>
        <Text mt="1rem" fontSize={"1.1rem"} color={"#0A0A0B"} fontWeight={"600"}>{tourney?.title}-{tourney?.gameFormat}-({tourney?.gameMode})</Text>
        <Flex mt="1rem" alignItems={"center"} justifyContent={"space-between"}>
          {tourney?.participantType === "TEAM" ?
          <Flex alignItems={"center"}>
            {tourney?.teams?.slice(0,5).map((i, index) => (
              <Avatar border={"1px solid #000"} ml={index !== 0 ? "-1rem" : ""} key={i?.id} size='sm' src={i?.img} />
            ))}
            <Text ml=".5rem" fontWeight={"500"}>{tourney?.teams?.length}</Text>
          </Flex>
          :
          <Flex alignItems={"center"}>
            {tourney?.individuals?.slice(0,5).map((i, index) => (
              <Avatar border={"1px solid #000"} ml={index !== 0 ? "-1rem" : ""} key={i?.id} size='sm' src={i?.imageUrl} />
            ))}
            <Text ml=".5rem" fontWeight={"500"}>{tourney?.individuals?.length}</Text>
          </Flex>
          }
            {/* <Text color={"#4E5055"} fontSize={".95rem"}><HiUsers style={{display: "inline", transform: "translateY(2px)", marginRight: ".2rem"}} />{tourney?.individuals?.length}</Text> */}
            <Flex alignItems={"center"} gap=".2rem">
                <Image src={Naira} />
                <Text fontSize={".95rem"} fontWeight={"700"} color={"#0A0A0B"}>{tourney?.prize?.toLocaleString()} <span style={{color: "#4E5055", fontWeight: "400", fontSize: ".85rem"}}>Prize pool</span></Text>
            </Flex>
        </Flex>
        <Box mt="1rem" padding={"1.2rem"} borderRadius={"12px"} border="2px solid #CFD0D3" boxShadow={"0px 4px 16px 0px #00000014"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Badge colorScheme={tourney?.active ? "green" : "yellow"} textTransform={"capitalize"} borderRadius={"6px"} padding={".3rem .5rem"}>{tourney?.active ? "Active" : "Pending"}</Badge>
                {tourney?.status === "ENDED" && 
                <Box textAlign={"center"}>
                  <Text opacity={".7"} fontSize={".8rem"}>Winner</Text>
                  <Text fontSize={"1rem"} color={"blue"} fontWeight={"600"} textTransform={"capitalize"}>{results[0]?.username || results[0]?.teamName}</Text>
                </Box>
                }
                <Text fontSize={".8rem"} fontWeight={"500"} color={"#0A0A0B"}>Platforms: {!loading1 ? consoles.find(i => i.console.id === tourney?.consoleId)?.console?.name : ""}</Text>
            </Flex>
            <Text mt="1rem" fontSize={"1rem"} color={"#0A0A0B"} fontWeight={"600"}>{!loading2 ? games.find(i => i.game.id === tourney?.gameId)?.game?.name : ""}</Text>
            <Flex mt=".5rem" alignItems={"center"} justifyContent={"space-between"}>
                <Box>
                    <Text color={"#4E5055"} fontSize={".9rem"} mb=".1rem">{tourney?.participantType === "TEAM" ? "Teams" : "Individuals"}</Text>
                    {tourney?.participantType === "TEAM" ? 
                    <Text p=".2rem .4rem" fontWeight={"500"} borderRadius={"4px"} bg="#EAEAEB" color={"#4E5055"} fontSize={".9rem"}><HiUsers style={{display: "inline", transform: "translateY(2px)", marginRight: ".2rem"}} />{tourney?.teams?.length + "/" + tourney?.numberOfTeams}</Text> 
                    :
                    <Text p=".2rem .4rem" fontWeight={"500"} borderRadius={"4px"} bg="#EAEAEB" color={"#4E5055"} fontSize={".9rem"}><HiUsers style={{display: "inline", transform: "translateY(2px)", marginRight: ".2rem"}} />{tourney?.individuals?.length + "/" + tourney?.numberOfIndividuals}</Text> 
                    }
                </Box>
                <Box>
                    <Text color={"#4E5055"} fontSize={".9rem"} mb=".1rem">{tourney?.startDateTime?.slice(0,10)}</Text>
                    <Text color={"#4E5055"} fontSize={".9rem"} fontWeight={"500"} ><AiFillClockCircle color='grey' style={{display: "inline", transform: "translateY(2px)", marginRight: ".4rem"}} />{tourney?.startDateTime?.slice(11)}</Text>
                </Box>
                <Box>
                    <Text color={"#4E5055"} fontSize={".9rem"} mb=".1rem">Entry Fee</Text>
                    {tourney?.entryType === "FREE" ?
                      <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>FREE</Text>
                      :
                      <Flex alignItems={"center"} gap=".2rem">
                        <Image src={Naira} />
                        <Text fontSize={".9rem"} fontWeight={"500"} color={"#0A0A0B"}>{tourney?.entryFee?.toLocaleString()}</Text>
                    </Flex>}
                </Box>
            </Flex>
        </Box>

        <Tabs position="relative" variant="unstyled" mt="1.2rem">
  <Box 
    overflowX={{ base: "auto", md: "visible" }} 
    sx={
      { 
      '::-webkit-scrollbar':{
          display:'none'
      }
      }}
    whiteSpace={{ base: "nowrap", md: "normal" }}
  >
    <TabList 
      justifyContent={{ base: "flex-start", md: "space-between" }} 
      borderBottom="1.5px solid lightgray"
    >
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }} 
        mr={{ base: "1rem", md: "0" }}
      >
        Overview
      </Tab>
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }} 
        mr={{ base: "1rem", md: "0" }}
      >
        Brackets
      </Tab>
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }} 
        mr={{ base: "1rem", md: "0" }}
      >
        Matches
      </Tab>
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }} 
        mr={{ base: "1rem", md: "0" }}
      >
        Results
      </Tab>
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }} 
        mr={{ base: "1rem", md: "0" }}
      >
        {tourney?.participantType === "TEAM" ? "Teams" : "Individuals"}
      </Tab>
      <Tab 
        color="#4E5055" 
        fontWeight="500" 
        _focus={{ border: "none" }} 
        _selected={{ 
          color: "blue.500", 
          fontWeight: "bold", 
          borderBottom: "2px solid", 
          borderColor: "blue.500" 
        }}
      >
        Rules
      </Tab>
    </TabList>
  </Box>
  <TabPanels mt=".5rem">
    <TabPanel>
      <Overview state={tourney} />
    </TabPanel>
    <TabPanel>
      <Bracket state={tourney} />
    </TabPanel>
    <TabPanel>
      <Matches state={tourney} reTrigger={reTrigger} setReTrigger={setReTrigger} />
    </TabPanel>
    <TabPanel>
      <Results state={tourney} reTrigger={reTrigger} />
    </TabPanel>
    <TabPanel>
      <Teams state={tourney} />
    </TabPanel>
    <TabPanel>
      <Rules state={tourney} />
    </TabPanel>
  </TabPanels>
</Tabs>


    </Box>}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius={"20px"} overflow={"hidden"}>
            <ModalHeader fontFamily={"Open Sans"} fontSize={"1.1rem"} textAlign={"center"} color={"#244CC6"}>Confirm Close</ModalHeader>
            <ModalBody fontFamily={"Open sans"} p="0">
                <Flex gap="1rem" padding={"1.5rem"} bg="#EDF1FF">
                    <PrimaryButton onClick={onClose} type='submit' bg="transparent" color="#244CC6" border="1px solid #244CC6" _hover={{backgroundColor: "transparent"}} width="100%">Cancel</PrimaryButton>
                    <PrimaryButton onClick={closeTourney} type='submit' bg="#EB5757" color="#FFF" _hover={{backgroundColor: "#EB5757"}} width="100%">{loading3 ? <Spinner /> : "Close"}</PrimaryButton>
                </Flex>
            </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default TournamentDetails