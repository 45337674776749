import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Skeleton,
    Stack,
    Text,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    useToast,
  } from '@chakra-ui/react';
  import { useEffect, useState } from 'react';
  import axiosInstance from '../../../../../../services/api';
  import baseurl from '../../../../../../config';
  import PrimaryButton from '../../../../../../components/primaryButton/primarybutton';
  import {
    ChevronLeftIcon,
    ChevronRightIcon,
    EditIcon,
    PlusSquareIcon,
  } from '@chakra-ui/icons';
  import UpdateScores from './updateScores/updateScores';
  import CustomToast from '../../../../../../components/customToast/customToast';
  import EditScores from './editScores/editScores';
  
  const Matches = ({ state, reTrigger, setReTrigger }) => {
    const [scores, setScores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [round, setRound] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [editMode, setEditMode] = useState('');
    const [finalScores, setFinalScores] = useState(null);
    const [ppk, setppk] = useState('');
  
    const [idx, setIdx] = useState(null);
  
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const toast = useToast();
  
    const getScores = async () => {
      setLoading(true);
      try {
        const { data } = await axiosInstance.get(
          `${baseurl}/tournaments/${
            state?.id
          }/${state?.bracketType?.toLowerCase()}/${state?.participantType?.toLowerCase()}/scores?page=${round}`
        );
        setScores(data?.data);
        setTotalPages(data?.data?.totalPages);
        console.log('it struck', data?.data?.totalPages);
      } catch (error) {
        // console.log(error?.response?.data?.errors[0])
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      getScores();
    }, [state?.id, round, state?.bracketType, state?.participantType, loadingx]);
  
    const handleChange = (e) => {
      setRound(e.target.value);
      // console.log(round)
  
      const filteredData =
        round === 1 ? scores : scores.filter((item) => item.round === round);
      setScores(filteredData);
    };
  
    const postScores = async () => {
      setLoadingx(true);
      // console.log(finalScores)
  
      const formattedFinalscoresI = finalScores?.map((i) => ({
        username: i?.username,
        placement: i?.placement === '' ? 0 : i?.placement,
        kills: i?.kills === '' ? 0 : i?.kills,
      }));
      const formattedFinalscoresT = finalScores?.map((i) => ({
        teamId: i?.teamId,
        placement: i?.placement === '' ? 0 : i?.placement,
        kills: i?.kills === '' ? 0 : i?.kills,
      }));
  
      const datax = {
        round: totalPages + 1,
        pointPerKill: state?.pointPerKill,
        scores:
          state?.participantType === 'TEAM'
            ? formattedFinalscoresT
            : formattedFinalscoresI,
      };
  
      // console.log(datax)
  
      try {
        const { data } = await axiosInstance.post(
          `${baseurl}/tournaments/${
            state?.id
          }/${state?.bracketType?.toLowerCase()}/${state?.participantType?.toLowerCase()}/scores`,
          datax
        );
        setEditMode(false);
        setRound(0);
        setReTrigger(Math.random());
        onClose();
        toast({
          render: () => <CustomToast type={'success'} message="Scores Updated" />,
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        toast({
          render: () => (
            <CustomToast
              type={'error'}
              message={error?.response?.data?.errors[0]}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setLoadingx(false);
      }
    };
  
    const editScores = async () => {
      setLoadingx(true);
      // console.log(finalScores)
  
      const datax = {
        round: idx[0]?.round,
        pointPerKill: state?.pointPerKill,
        scores: finalScores,
      };
  
      try {
        const { data } = await axiosInstance.put(
          `${baseurl}/tournaments/${
            state?.id
          }/${state?.bracketType?.toLowerCase()}/${state?.participantType?.toLowerCase()}/scores`,
          datax
        );
        // console.log(data)
        setEditMode('');
        setRound(0);
        setReTrigger(Math.random());
        onClose();
        toast({
          render: () => <CustomToast type={'success'} message="Scores Updated" />,
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        toast({
          render: () => (
            <CustomToast
              type={'error'}
              message={error?.response?.data?.errors[0]}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setLoadingx(false);
      }
    };
  
    const tags = state?.gamersTagMap;
  
    const handleEdit = (type, data) => {
      setIdx(data);
      setEditMode(type);
    };
  
    return (
      <Box pt=".5rem">
        {editMode === '' ? (
          <Box>
            <Flex
              alignItems={'center'}
              mb="1.5rem"
              justifyContent={'space-between'}
              p=".5rem"
              boxShadow="0px 2px 8px 0px #00000014"
            >
              <ChevronLeftIcon
                onClick={round + 1 === 1 ? null : () => setRound(round - 1)}
                cursor={'pointer'}
                bg={round + 1 >= 2 ? '#244CC6' : '#EAEAEB'}
                color={round + 1 >= 2 ? '#FFF' : '#0A0A0B'}
                height={'35px'}
                width={'35px'}
                borderRadius={'6px'}
              />
              <Text>Round {round + 1}</Text>
              <ChevronRightIcon
                onClick={
                  round + 1 < parseInt(state.gameFormat?.split(' ')[2])
                    ? () => setRound(round + 1)
                    : null
                }
                cursor={'pointer'}
                bg={
                  round + 1 < parseInt(state.gameFormat?.split(' ')[2])
                    ? '#244CC6'
                    : '#EAEAEB'
                }
                color={round + 1 >= 1 ? '#FFF' : '#0A0A0B'}
                height={'35px'}
                width={'35px'}
                borderRadius={'6px'}
              />
            </Flex>
            {scores?.content?.length < 1 ? (
              <Flex alignItems={'center'} justifyContent={'flex-end'}>
                <PrimaryButton
                  onClick={() => setEditMode('add')}
                  width={180}
                  bg="#244CC6"
                  color="#FFF"
                  height="40px"
                  padding=".4rem .7rem"
                  fontSize=".85rem"
                  borderRadius="8px"
                  text="Add Result"
                  icon="add"
                >
                  <PlusSquareIcon mr=".3rem" /> Add Result
                </PrimaryButton>
              </Flex>
            ) : (
              <Flex alignItems={'center'} justifyContent={'flex-end'}>
                <PrimaryButton
                  onClick={() => handleEdit('update', scores?.content)}
                  width={180}
                  bg="#244CC6"
                  color="#FFF"
                  height="40px"
                  padding=".4rem .7rem"
                  fontSize=".85rem"
                  borderRadius="8px"
                  text="Edit Scores"
                >
                  <EditIcon mr=".3rem" /> Edit Scores
                </PrimaryButton>
              </Flex>
            )}
  
            <Box mt="1.5rem" overflowX={{ base: "auto", md: "visible" }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ backgroundColor: '#EAEAEB', color: '#0A0A0B' }}>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', borderRadius: '8px 8px 0 0', whiteSpace: 'nowrap' }}>NO</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>PLAYERS</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>PLACEMENT</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>PLACEMENT POINTS</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>KILLS</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>KILL POINTS</th>
                  <th style={{ padding: '.8rem 1rem', fontSize: '.8rem', fontWeight: '700', textAlign: 'left', whiteSpace: 'nowrap' }}>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7">
                      <Stack padding={'1rem'}>
                        <Skeleton height={'25px'} width={'100%'} />
                        <Skeleton height={'25px'} width={'100%'} />
                        <Skeleton height={'25px'} width={'100%'} />
                        <Skeleton height={'25px'} width={'100%'} />
                        <Skeleton height={'25px'} width={'100%'} />
                      </Stack>
                    </td>
                  </tr>
                ) : scores?.content?.length < 1 ? (
                  <tr>
                    <td colSpan="7" style={{ fontWeight: '600', textAlign: 'center', marginTop: '2rem' }}>
                      Scores not available yet
                    </td>
                  </tr>
                ) : (
                  scores?.content?.map(
                    (
                      {
                        teamName,
                        username,
                        killPoints,
                        placementPoints,
                        kills,
                        placement,
                        round,
                        teamId,
                        total,
                      },
                      index
                    ) => (
                      <tr key={teamName || username} style={{ backgroundColor: '#FFF', borderBottom: '1px solid #EAEAEB' }}>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{index + 1}</td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', textTransform: 'capitalize', border: '1px solid lightgray' }}>
                          {teamName || `${username} (${tags[username]})`}
                        </td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{placement}</td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{placementPoints}</td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{kills}</td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{killPoints}</td>
                        <td style={{ padding: '.6rem 1rem', fontSize: '.8rem', fontWeight: '600', textAlign: 'left', whiteSpace: 'nowrap', border: '1px solid lightgray' }}>{total}</td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </Box>

          </Box>
        ) : editMode === 'add' ? (
          <Box>
            <UpdateScores
              pid={state?.placementSystemId}
              setEditMode={setEditMode}
              type={state?.participantType}
              teams={
                state?.participantType === 'INDIVIDUAL'
                  ? state?.individuals
                  : state?.teams
              }
              setFinalScores={setFinalScores}
              ppk={state?.pointPerKill}
              state={state}
              onOpen={onOpen}
            />
          </Box>
        ) : editMode === 'update' ? (
          <EditScores
            idx={idx}
            pid={state?.placementSystemId}
            setEditMode={setEditMode}
            type={state?.participantType}
            teams={
              state?.participantType === 'INDIVIDUAL'
                ? state?.individuals
                : state?.teams
            }
            setFinalScores={setFinalScores}
            ppk={state?.pointPerKill}
            state={state}
            onOpen={onOpen}
          />
        ) : null}
  
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={'20px'} overflow={'hidden'}>
            <ModalHeader
              fontFamily={'Open Sans'}
              fontSize={'1.1rem'}
              textAlign={'center'}
              color={'#244CC6'}
            >
              {editMode === 'add' ? 'Upload Scores?' : 'Edit Scores?'}
            </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody fontFamily={'Open sans'} p="0">
              {/* <Text my="1rem" textAlign={"center"}>{idx?.name}</Text> */}
              <Flex gap="1rem" padding={'1.5rem'} bg="#EDF1FF">
                <PrimaryButton
                  onClick={onClose}
                  type="submit"
                  bg="transparent"
                  color="#244CC6"
                  border="1px solid #244CC6"
                  _hover={{ backgroundColor: 'transparent' }}
                  width="100%"
                  text="Cancel"
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  onClick={editMode === 'add' ? postScores : editScores}
                  type="submit"
                  bg="#244CC6"
                  color="#FFF"
                  _hover={{ backgroundColor: '#244CC6' }}
                  width="100%"
                  text={loadingx ? (
                    <Spinner />
                  ) : editMode === 'add' ? (
                    'Upload'
                  ) : (
                    'Update'
                  )}
                >
                  {loadingx ? (
                    <Spinner />
                  ) : editMode === 'add' ? (
                    'Upload'
                  ) : (
                    'Update'
                  )}
                </PrimaryButton>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  };
  
  export default Matches;