import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import LineSkeleton from '../../../components/lineSkeleton';
import EmptyState from '../../../components/emptyState/emptyState';
import axiosInstance from '../../../services/api';
import { truncateText } from '../../../utils/helper';
import CustomDrawer from '../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../components/secondaryButton/secondarybutton';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import CustomInput from '../../../components/customInput/customInput';
import CustomTextArea from '../../../components/customTextArea/customTextArea';
import CustomTabs from '../../../components/customTabs/customTabs';
import Games from './games/games';
import Consoles from './consoles/consoles';
import Channels from './channels/channels';
import Banner from './banners/banners';

const AppData = () => {
  const [games, setGames] = useState([]);
  const [consoles, setConsoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState("");

  const getGames = async() => {
      setLoading(true)
      try {
          const { data } = await axiosInstance.get(`/games?size=20`)
          setGames(data?.data?.content)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
  }

  useEffect(() => {
      getGames()
  }, [refetch])

  const getConsoles = async() => {
      setLoading(true)
      try {
          const { data } = await axiosInstance.get(`/console`)
          setConsoles(data?.data?.content)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
  }

  useEffect(() => {
      getConsoles()
  }, [refetch])

  const data = [
    {
      title: "Games",
      render: <Games games={games} loading={loading} consoles={consoles} setRefetch={setRefetch} />
    },
    {
      title: "Consoles",
      render: <Consoles games={games} loading={loading} consoles={consoles} setRefetch={setRefetch} />
    },
    {
      title: "Chat/Channels",
      render: <Channels />
    },
    {
      title: "Banners",
      render: <Banner/>
    },
  ]

  return (
    <>
      <CustomTabs data={data} />
    </>
  )
}

export default AppData;