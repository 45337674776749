import { DeleteIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../services/api";
import { toast } from "react-toastify";
import { LuImagePlus } from "react-icons/lu";
// import CustomToast from "../../components/customToast/customToast";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import SecondaryButton from "../../../../components/secondaryButton/secondarybutton";


const Banner = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);

    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [idx, setIdx] = useState("")

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    useEffect(() => {
        axiosInstance.get("banner")
        // .then(res => console.log(res.data?.data?.content))
        .then(res => setBanners(res.data?.data?.content))
        .catch(err => console.log(err))
        .finally(setLoading(false))
    }, [loading2])

    

    const createBanner = async(e) => {
      if(name?.slice(0,5) === "Light" || name?.slice(0,4) === "Dark") {
        setLoading2(true)
        try {
            const formData = new FormData()
            formData.append("file", image)
    
            const {data} = await axiosInstance.post(`/file/upload`, formData)
    
            const datax = {
                name,
                url: data?.data
            }
    
            try {
                const {data} = await axiosInstance.post(`/banner`, datax)
                toast.success("Banner added")
                setName("")
                setImage("")
                onClose()
            } catch(error) {
                toast.error(error?.response?.data?.errors[0])
            }
        } catch (error) {
          toast.error(error?.response?.data?.errors[0])
        } finally {
            setLoading2(false)
        }
      } else {
        toast.error("Name must start with Light or Dark")
      }
    }

    const handleDelete = (i) => {
      setIdx(i)
      onOpen2()
    }

    const deleteBanner = async(id) => {
      setLoading2(true)
      try {
          const res = await axios.delete(`banners/${id}`)
          // toast.success(res?.data?.message)
          onClose()
      } 
      catch(error) {
          console.log(error)
      }
      finally {
          setLoading2(false)
      }
  }

  return (
    <Box>
      <Flex direction="row" justifyContent="flex-end">
        {banners?.length >= 10 ?
        <Badge colorScheme="green" padding=".5rem 1rem" borderRadius="6px">Reached Limit of Eight(8) Banners</Badge> :
        // <Button onClick={onOpen} color="#fff" _hover={{backgroundColor: "#244CC6", color: "#fff"}} bg="#244CC6" width={200}>
        //   Add Banner
        // </Button>
        <SecondaryButton onClick={onOpen} icon="add" text="Add Banner" width={200}/>}
      </Flex>

      {loading ? <Flex mt={50} direction='row' justifyContent='center'><Spinner /></Flex> :
      <TableContainer mt={5} boxShadow='0px 4px 25px rgba(0, 0, 0, 0.1)' height={"600px"} overflowY={"scroll"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Banner</Th>
              {/* <Th>Action</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {banners?.map((i) => {
                return (
                 <Tr key={i?.id}>
                    <Td>
                      <Text transform={"translateY(-5px)"} fontSize={".9rem"}>{i?.name}</Text>
                      <Box width="300px" height="150px" backgroundImage={i?.url} backgroundSize="contain" backgroundRepeat={"no-repeat"}></Box>
                    </Td>
                    {/* <Td><DeleteIcon onClick={() => handleDelete(i)} cursor='pointer' ml={5} /></Td> */}
                </Tr>
            )})}
          </Tbody>
        </Table>
      </TableContainer>}


      <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={"Open Sans"}>Add Banner</ModalHeader>
          <ModalCloseButton />
            <ModalBody>
            <FormControl mb=".7rem">
                        <FormLabel
                        color="#0A0A0B"
                        transform={"translateY(-2px)"}
                        fontSize={".85rem"}
                        fontFamily={"Open Sans"}
                        >
                        Name
                        </FormLabel>
                        <Input
                        type="text"
                        placeholder="Name"
                        bg="#EAEAEB"
                        border="2px solid #CFD0D3"
                        _focus={{ boxShadow: "none" }}
                        fontSize={".9rem"}
                        height={"45px"}
                        borderRadius={"14px"}
                        _placeholder={{fontSize: ".85rem", color: "#4E5055"}}
                        value={name} 
                        onChange={e => setName(e.target.value)}
                        />
                         <FormHelperText fontSize={".8rem"} fontFamily={"Open Sans"} lineHeight={5} p={2} bg='gray.100' borderRadius={6}>Ensure that the name starts with either <strong>Light</strong> or <strong>Dark</strong></FormHelperText>
                    </FormControl>
              <FormControl mb={5}>
                  <FormLabel fontSize={".85rem"} fontFamily={"Open Sans"} color={"#0A0A0B"}>Banner Image</FormLabel>
                  <FormControl mb=".7rem">
                        <InputGroup overflow={"hidden"}>
                        <Input
                        type="text"
                        placeholder="Banner Image"
                        readOnly
                        bg="#EAEAEB"
                        border="2px solid #CFD0D3"
                        _focus={{ boxShadow: "none" }}
                        fontSize={".9rem"}
                        height={"45px"}
                        borderRadius={"14px"}
                        _placeholder={{fontSize: ".85rem", color: "#4E5055"}}
                        value={image?.name}
                        />
                        <InputRightElement width={"120px"} cursor={"pointer"} children={ 
                            <Box position={"relative"} cursor={"pointer"}>
                               <Input onChange={e => setImage(e.target.files[0])} cursor={"pointer"} type="file" opacity={"0"} transform={"translate(-1.2rem, .6rem)"} position={"absolute"} mt="-1rem" ml="1rem" height={"50px"} />
                               <Flex cursor={"pointer"} bg="#FFF" mt=".25rem" borderRadius={"8px"} ml="-.4rem" alignItems={"center"} gap=".3rem" padding={".5rem .6rem"}>
                                    <LuImagePlus />
                                    <Text cursor={"pointer"} fontSize={".75rem"}>Upload Image</Text>
                                </Flex> 
                            </Box>
                        } />
                        </InputGroup>
                    </FormControl>
                  <FormHelperText fontSize={".8rem"} fontFamily={"Open Sans"} lineHeight={5} p={2} bg='gray.100' borderRadius={6}>Select a banner image no larger then 2MB and of dimensions 1400x560</FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button fontSize={".85rem"} fontFamily={"Open Sans"} _focus={{boxShadow: 'none'}} variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                {/* <Button fontFamily={"Open Sans"} onClick={createBanner} bg='#244CC6' color="#FFF">{loading2 ? <Spinner /> : "Create"}</Button> */}
                <SecondaryButton onClick={createBanner} fontSize={".85rem"} text={loading2 ? <Spinner /> : "Create"}/>
            </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Modal isOpen={isOpen2} onClose={onClose2} isCentered initialFocusRef={tmpRef}>
            <ModalOverlay />
            <ModalContent borderRadius={"20px"} overflow={"hidden"}>
            <ModalHeader fontFamily={"Open Sans"} fontSize={"1.1rem"} textAlign={"center"} color={"#244CC6"}>Confirm Delete</ModalHeader>
            <ModalBody fontFamily={"Open sans"} p="0">
                <Text my="1rem" textAlign={"center"}>{idx?.name}</Text>
                <Flex gap="1rem" padding={"1.5rem"} bg="#EDF1FF">
                    <PrimaryButton onClick={onClose2} type='submit' bg="transparent" color="#244CC6" border="1px solid #244CC6" _hover={{backgroundColor: "transparent"}} width="100%">Cancel</PrimaryButton>
                    <PrimaryButton onClick={deleteGame} type='submit' bg="#EB5757" color="#FFF" _hover={{backgroundColor: "#EB5757"}} width="100%">{loading2 ? <Spinner /> : "Delete"}</PrimaryButton>
                </Flex>
            </ModalBody>
            </ModalContent>
        </Modal> */}
      </Box>
  );
};

export default Banner;