import { Avatar, Box, Button, Divider, Flex, FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Skeleton, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { HiPaperAirplane } from 'react-icons/hi'
import axiosInstance from '../../../../../../services/api'
import baseurl from '../../../../../../config'
import EmptyState from '../../../../../../components/emptyState/emptyState'
import SecondaryButton from '../../../../../../components/secondaryButton/secondarybutton'
import CustomToast from '../../../../../../components/customToast/customToast'

const Crs = ({ assignedCRs, setAssignedCRs, defaultTabIndex, setDefaultTabIndex, image, setImagex}) => {
    const [selected, setSelected] = useState([])
    const [searchVal, setSearchVal] = useState("")
    const [reps, setReps] = useState([])
    const [loadingx, setLoadingx] = useState(true)
    const [loadingy, setLoadingy] = useState(false)

    const toast = useToast()

    useEffect(() => {
        axiosInstance.get(`${baseurl}/users/community-rep?size=100`)
        // .then(res => console.log(res?.data?.data?.content))
        .then(res => (setReps(res?.data?.data?.content)))
        .then(res => (setLoadingx(false)))
        .catch(error => console.log(error?.response))
    }, [])

    const handleSelect = (id) => {
        if(!assignedCRs.includes(id)) {
            setAssignedCRs([...assignedCRs, id])
        } else {
            const newArr = assignedCRs.filter(i => i !== id)
            setAssignedCRs(newArr)
        }
    }

    const handleShowPreview = async () => {
        if (!image) {
            toast({
                render: () => (
                    <CustomToast type={"error"} message="Please select an image before previewing." />
                ),
                duration: 5000,
                isClosable: true,
                position: "top"
            });
            return;
        }
    
        setLoadingy(true);
        try {
            const formData = new FormData();
            formData.append("file", image);
            const { data } = await axiosInstance.post(`${baseurl}/file/upload`, formData);
            
            if (!data || !data.data) {
                throw new Error("Invalid response from server");
            }
    
            setImagex(data.data);
            setDefaultTabIndex(prevIndex => prevIndex + 1);
        } catch (error) {
            console.error("Error in handleShowPreview:", error);
            toast({
                render: () => (
                    <CustomToast 
                        type={"error"} 
                        message={error?.response?.data?.errors?.[0] || "An error occurred while uploading the image. Please try again."}
                    />
                ),
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        } finally {
            setLoadingy(false);
        }
    };

  return (
    <Box>
        <Flex alignItems={"center"} justifyContent={"space-between"} mb="2rem" flexDirection={["column", "row"]}>
            <FormControl
                width={"400px"}>
                <InputGroup>
                    <InputLeftElement
                    children={
                    <CiSearch color="#9A9CA2" style={{transform: "scale(1.4)"}} />
                    }
                    />
                    <Input onChange={e => setSearchVal(e.target.value)} placeholder="Search Community Reps" fontFamily={"Open Sans"} bg="#EAEAEB" fontSize={".85rem"} outline={"none"} focusBorderColor="none" _placeholder={{fontSize: ".85rem", color: "#4E5055"}} border="none" boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.08)"} borderRadius="8px" />
                    {/* <InputRightElement
                        children={
                        <Flex gap="1rem" marginLeft={"-.6rem"}>
                            <Button type="submit" bg="#FFF" fontSize={".75rem"} padding=".2rem 0rem" height={"32px"} borderRadius="21px" color="#244CC6"><HiPaperAirplane style={{transform: "rotate(90deg)"}} /></Button>
                        </Flex>
                        }
                    /> */}
                </InputGroup>
            </FormControl>
            <Text
                mt={["0.5rem", "0"]}
                textAlign={["right", "left"]} 
                width={["100%", "auto"]} 
                ml={["auto", "0"]} 
            >
                <strong>{assignedCRs?.length}</strong> selected
            </Text>
        </Flex>
        
        <Flex gap={"1rem"} flexWrap={"wrap"} width={"100%"} overflowY={"scroll"}>

        {loadingx ? 
           <Stack padding={"1rem"}>
            <Skeleton height={"25px"} width={"100%"} />
            <Skeleton height={"25px"} width={"100%"} />
            <Skeleton height={"25px"} width={"100%"} />
            <Skeleton height={"25px"} width={"100%"} />
            <Skeleton height={"25px"} width={"100%"} />
           </Stack>
           :
           <>
            {reps?.length < 1 ?
            <Box width={"100%"}>
                <EmptyState />
            </Box>
            :
            reps?.filter(i => i.username.toLowerCase()?.includes(searchVal?.toLowerCase()))?.map((i) => {
            return (
                <Flex
                height={"fit-content"}
                onClick={() => handleSelect(i?.id)}
                cursor={"pointer"}
                key={i?.id}
                flex={["0 1 auto", "0 0 120px"]} 
                flexDirection={["row", "row"]}
                alignItems={"center"}
                p=".7rem"
                borderRadius={["0", "8px"]}
                borderBottom={["1px solid #6766664D", "none"]}
                border={["" , "1px solid #E9D7FE"]}
                bg={assignedCRs?.includes(i?.id) ? "#F9F5FF" : ["none", "white"]} 
                gap=".5rem"
                width={"100%"} 
                maxWidth={["100%", "auto"]} 
                boxSizing="border-box"
              >
                <Avatar src={i?.imageUrl} size={["md", "sm"]} /> 
                <Box ml={["0", "1rem"]} textAlign={["center", "left"]}>
                  <Text
                    fontWeight={"400"}
                    fontSize={"14px"}
                    textAlign={"left"}
                    color={assignedCRs.includes(i?.id) ? "#6941C6" : "#393939"}
                  >
                    {i?.username}
                  </Text>
                  <Text
                    fontWeight={"400"}
                    fontFamily={"Poppins"}
                    fontSize={"12px"}
                    color={assignedCRs.includes(i?.id) ? "#6941C6" : "#676666CC"}
                  >
                    {i?.email}
                  </Text>
                </Box>
              </Flex>
                
              
            )
           })}
           </>
           }
        </Flex>
        <SecondaryButton mt="3rem" onClick={handleShowPreview} type="submit" bg="#244CC6" color="#FFF" width="100%" text={loadingy ? <Spinner /> : "Preview Tournament"}/>
        <Text textAlign={"center"} mt="1rem" fontSize={".85rem"} fontWeight={"500"}>Community Reps. can be assigned even after a tournament has been created</Text>
    </Box>
  )
}

export default Crs