import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import EmptyState from '../../../../components/emptyState/emptyState';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import LineSkeleton from '../../../../components/lineSkeleton';
import { buildResourceURL, truncateText } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Metrics from '../../../../components/metrics/metrics';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import CreatePorteshopGame from './createGames';
import EditGame from './editGames';

const Games = ({ clientId }) => {
  const [games, setGames] = useState([]);
  const [totalGames, setTotalGames] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState('');
  const [status, setStatus] = useState('');
  const [consoles, setConsoles] = useState([]);
  const [consolex, setConsolex] = useState('');
  const [date, setDate] = useState('');
  const [triggerClose, setTriggerClose] = useState('');
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [seletedGame, setSelectdGame] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  // const queryParams = [
  //   {
  //     key: 'size',
  //     value: '50',
  //   },
  //   {
  //     key: 'status',
  //     value: status === 'all' ? '' : status,
  //   },
  //   {
  //     key: 'gameId',
  //     value: game === 'all' ? '' : game,
  //   },
  //   {
  //     key: 'consoleId',
  //     value: consolex === 'all' ? '' : consolex,
  //   },
  // ];

  const getGames = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/games');
      setGames(data?.data?.content);
      setTotalGames(data?.data?.content?.length);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, [refetch]);

  const handleModal = (getdata) => {
    setSelectdGame(getdata);
    onOpen();
  };

  const deleteGame = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance2.delete(
        `games/${seletedGame?.ulid}`,
        {}
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingx(false);
    }
  };
  const gameData = [
    {
      title: 'Total Product',
      value: !loading ? totalGames : null,
    },
    // {
    //   title: 'Ongoing Tournaments',
    //   value: !loading
    //     ? tournaments?.filter((i) => i.status === 'IN_PROGRESS')?.length
    //     : null,
    // },
    // {
    //   title: 'Pending Tournaments',
    //   value: !loading
    //     ? tournaments?.filter(
    //         (i) => i.status === 'PENDING' && i.approved !== true
    //       )?.length
    //     : null,
    // },
    // {
    //   title: 'All Closed Tournaments',
    //   value: !loading
    //     ? tournaments?.filter((i) => i.status === 'ENDED')?.length
    //     : null,
    // },
  ];

  // const statusOptions = [
  //   {
  //     title: 'All',
  //     value: 'all',
  //   },
  //   {
  //     title: 'Pending',
  //     value: 'PENDING',
  //   },
  //   {
  //     title: 'In Progress',
  //     value: 'IN_PROGRESS',
  //   },
  //   {
  //     title: 'Ended',
  //     value: 'ENDED',
  //   },
  // ];

  // const gameOptions = [
  //   {
  //     title: 'All Games',
  //     value: 'all',
  //   },
  //   ...games?.map((i) => ({
  //     title: i?.game?.name,
  //     value: i?.game?.id,
  //   })),
  // ];

  // const consoleOptions = [
  //   {
  //     title: 'All Consoles',
  //     value: 'all',
  //   },
  //   ...consoles?.map((i) => ({
  //     title: i?.console?.name,
  //     value: i?.console?.id,
  //   })),
  // ];

  return (
    <>
      <Metrics data={gameData} loading={loading} />
      <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={'12px'} overflowX="auto"
  whiteSpace="nowrap"
  sx={{
    "&::-webkit-scrollbar": { display: "none" },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  }}>
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Products{' '}
            <span
              style={{
                padding: '.1rem .3rem',
                borderRadius: '50%',
                backgroundColor: '#F9FAFB',
                border: '1px solid #EAECF0',
                fontSize: '12px',
                fontWeight: '500',
                color: '#344054',
              }}
            >
              {totalGames}
            </span>
          </Text>

          <CustomDrawer
            position={'right'}
            title={'Create Product'}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton
                type="submit"
                text={'Add Product'}
                icon="add"
                loading={false}
              />
            }
            content={
              <CreatePorteshopGame
                setTriggerClose={setTriggerClose}
                setRefetch={setRefetch}
              />
            }
          />
        </Flex>
        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                NAME
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                ULID
              </Th>

              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
              <Th></Th>
            </Tr>
          </Thead>

          {loading ? (
            <LineSkeleton width="100%" num={10} />
          ) : games?.length < 1 ? (
            <EmptyState mt="1.5rem" text="No tournaments created yet" />
          ) : (
            <Tbody>
              {games?.map(({ ulid, name, lightModeImageUrl, howToRedeem }) => {
                const gameData = { ulid, name, lightModeImageUrl, howToRedeem };
                return (
                  <Tr key={ulid}>
                    <Td>
                      <Flex alignItems={'center'} gap=".4rem">
                        <Box
                          height={'40px'}
                          width={'40px'}
                          borderRadius={'4px'}
                          backgroundImage={lightModeImageUrl}
                          backgroundSize={'cover'}
                          backgroundRepeat={'no-repeat'}
                        />
                        <Box>
                          <Text
                            mb=".2rem"
                            color={'#101828'}
                            fontSize={'.8rem'}
                            fontWeight={'500'}
                            textTransform={'capitalize'}
                          >
                            {truncateText(name, 20)}
                          </Text>
                          {/* <Text
                            mb=".2rem"
                            color={'#101828'}
                            fontSize={'.75rem'}
                            fontWeight={'400'}
                            textTransform={'capitalize'}
                          >
                            {truncateText(description, 17)}
                          </Text> */}
                        </Box>
                      </Flex>
                    </Td>
                    <Td color={'#475467'} fontSize={'.8rem'}>
                      #{ulid}
                    </Td>
                    <Td>
                      <Flex gap="1rem" alignItems={'center'}>
                        <CustomDrawer
                          position={'right'}
                          title={'Edit Game'}
                          triggerClose={triggerClose}
                          setTriggerClose={setTriggerClose}
                          toggleElement={
                            <Text
                              textDecoration={'underline'}
                              mb=".2rem"
                              color={'#101828'}
                              fontSize={'.9rem'}
                              fontWeight={'500'}
                              textTransform={'capitalize'}
                              cursor={'pointer'}
                            >
                              Edit
                            </Text>
                          }
                          content={
                            <EditGame
                              gameData={gameData}
                              setTriggerClose={setTriggerClose}
                              setRefetch={setRefetch}
                            />
                          }
                        />
                        <Text
                          onClick={() => handleModal(gameData)}
                          textDecoration={'underline'}
                          mb=".2rem"
                          color={'crimson'}
                          fontSize={'.9rem'}
                          fontWeight={'500'}
                          textTransform={'capitalize'}
                          cursor={'pointer'}
                        >
                          Delete
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
              Delete Game
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <Text textAlign={'center'} color={'#475467'} fontWeight={'500'}>
                  Confirm deletion of {seletedGame?.name}?
                </Text>
                <Flex justifyContent={'center'} my="1.3rem">
                  <PrimaryButton
                    onClick={deleteGame}
                    text="Delete"
                    width="150px"
                    bg="crimson !important"
                    padding="1rem !important"
                    loading={loadingx}
                  />
                </Flex>
              </>
              <Text
                pb="1rem"
                textAlign={'center'}
                fontSize={'.9rem'}
                fontWeight={'500'}
                cursor={'pointer'}
                onClick={onClose}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default Games;
