import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import axiosInstance3 from '../../../../services/unAuth';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import EmptyState from '../../../../components/emptyState/emptyState';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import LineSkeleton from '../../../../components/lineSkeleton';
import { buildResourceURL, truncateText } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Metrics from '../../../../components/metrics/metrics';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import { formatAmount } from '../../../../utils/helper';
import CreatePrice from './setPrice';
import UpdatePrice from './updateprice';
import UploadGiftCard from './uploadGiftCard';
import CustomSelect from '../../../../components/customSelect/customSelect';
import DownloadButton from '../../../../components/downloadTemplateButton';
import CreateGiftCardType from './createGiftCardType';
import EditGiftCardType from './editGiftCardType';
import Countries from '../countries';
import CustomPagination from '../../../../components/customPagination/customPagination';
import CreateGiftCardType2 from './createGiftCardType2';
import TypeCreationFlow from './typeCreationFlow'

const GiftCardType = ({ clientId }) => {
  const [giftCardsType, setGiftCardsType] = useState([]);
  const [totalGiftCardType, setTotalGiftCardsType] = useState('');
  const [totalPages, setTotalPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState('');
  const [consoles, setConsoles] = useState([]);
  const [triggerClose, setTriggerClose] = useState('');
  const [filterText, setFilterText] = useState(''); // Holds the country code for filtering
  const [countries, setCountries] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)

  const queryParams = [
    {
        key: 'page',
        value: page
    },
    {
        key: 'size',
        value: limit
    },
    {
        key: 'size',
        value: limit
    }
]
  
  const getGiftCardType = async () => {
    setLoading(true);
    try {
      if (filterText === '') {
        const { data } = await axiosInstance2.get(buildResourceURL('/gift-card-type', queryParams));
        setGiftCardsType(data?.data?.content);
        setTotalGiftCardsType(data?.data?.totalElements || 0);
        setTotalPages(data?.data?.totalPages)
      } else {
        const { data } = await axiosInstance2.get(
          `/gift-card-type/${filterText}`
        );
        setGiftCardsType(data?.data?.content);
        setTotalGiftCardsType(data?.data?.content?.length || 0);
      }
      console.log(giftCardsType)
    } catch (error) {
      toast({
        description: 'Failed to load Gift Card Types',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGiftCardType()
}, [page, limit, refetch])


  const getCountry = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/country');
      const formmattedCountry = data?.data?.map((country) => ({
        title: country?.name,
        value: country?.code,
      }));
      setCountries(formmattedCountry);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGiftCardType();
  }, [refetch, filterText]);
  useEffect(() => {
    getCountry();
  }, [refetch]);
  const countryOptions = [{ title: 'All Countries', value: '' }, ...countries];

  return (
    <>
      <Box
        bg="#FFF"
        mt=".7rem"
        p="1.5rem 1rem"
        borderRadius={'12px'}
        overflowX="auto"
        whiteSpace="nowrap"
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        <Flex
          alignItems={'center'}
          gap=".8rem"
          justifyContent={'space-between'}
        >
          <Text
            whiteSpace={'nowrap'}
            fontWeight={'500'}
            fontSize={'1.05rem'}
            color={'#27272A'}
          >
            Gift Cards{' '}
            <span
              style={{
                padding: '.1rem .3rem',
                borderRadius: '50%',
                backgroundColor: '#F9FAFB',
                border: '1px solid #EAECF0',
                fontSize: '12px',
                fontWeight: '500',
                color: '#344054',
              }}
            >
              {totalGiftCardType}
            </span>
          </Text>
          <Box
            mt="1rem"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {/* CustomSelect for filtering by country */}
            <CustomSelect
              options={countryOptions}
              onChange={(e) => setFilterText(e.target.value)} // Set the selected country code for filtering
              height="33px !important"
              mt="0"
              mr="1rem"
              borderRadius={'8px'}
              width="130px"
              cursor="pointer"
              fontSize=".85rem"
            />

            {/* <CustomDrawer
              position={'right'}
              title={'Create Gift Card Type'}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <SecondaryButton
                  type="submit"
                  text={'Create Gift Card Type'}
                  icon="add"
                  loading={false}
                  mr="1rem"
                />
              }
              content={
                <CreateGiftCardType2
                  setTriggerClose={setTriggerClose}
                  setRefetch={setRefetch}
                  countries={countries}
                  max={1}
                />
              }
            /> */}
            <Box>
            <TypeCreationFlow setRefetch={setRefetch}
        consoles={consoles}
        countries={countries} />
        </Box>
          </Box>
        </Flex>

        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                NAME
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                DESCRIPTION
              </Th>
              <Th
                textTransform={'capitalize !important'}
                whiteSpace={'nowrap'}
                color={'#475467'}
              >
                COUNTRY
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : giftCardsType?.length < 1 ? (
              <EmptyState mt="1.5rem" text="No Gift Cards available" />
            ) : (
              giftCardsType?.map(({ name, description, country, ulid }, i) => {
                const data = { name, description, country, ulid };
                return (
                  <Tr key={i}>
                    <Td color={'#475467'} fontSize={'.7rem'}>
                      {name}
                    </Td>
                    <Td color={'#475467'} fontSize={'.7rem'}>
                      {description}
                    </Td>
                    <Td color={'#475467'} fontSize={'.7rem'}>
                      {country?.name}
                    </Td>
                    <CustomDrawer
                      position={'right'}
                      title={'Edit Game'}
                      triggerClose={triggerClose}
                      setTriggerClose={setTriggerClose}
                      toggleElement={
                        <Text
                          textDecoration={'underline'}
                          mb=".2rem"
                          color={'#101828'}
                          fontSize={'.9rem'}
                          fontWeight={'500'}
                          textTransform={'capitalize'}
                          cursor={'pointer'}
                        >
                          Edit
                        </Text>
                      }
                      content={
                        <EditGiftCardType
                          gameData={data}
                          setTriggerClose={setTriggerClose}
                          setRefetch={setRefetch}
                        />
                      }
                    />
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>

        <CustomPagination  
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={totalGiftCardType}
         totalPages={totalPages} />
      </Box>
    </>
  );
};

export default GiftCardType;
