import { Box, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userActionTypes } from '../../../redux/constants/userActionTypes';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import CustomInput from '../../../components/customInput/customInput';
import AuthBg from '../../../assets/authBg.svg';
import axiosInstance3 from '../../../services/unAuth';
import axiosInstance from '../../../services/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const [loginState, setLoginState] = useState('admin');

  const toast = useToast();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const datax = {
    username: email,
    password,
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance3.post('/auth/login', datax);
  
      localStorage.setItem('GPTK', data.data.accessToken);
      localStorage.setItem('GPTKRF', data.data.refreshToken);
  
      const currentUserResponse = await axiosInstance.get('/users/current');
      const roles = currentUserResponse.data.data.role.map((role) => role.name); 

      console.log('Current User Roles:', roles);
  
      if (roles.length === 1 && roles.includes('GAMER')) {
        toast({
          title: 'Login Failed',
          description: '"GAMERS" are not allowed to login.',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return; 
      }
      dispatch({
        type: userActionTypes.SIGNIN_SUCCESS,
        payload: data?.data,
      });
      window.location.href = '/';
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message || 'Failed to login',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Flex height={'100vh'} flexWrap={'wrap'}>
      <Box
        flex={'1 1 400px'}
        bg="#002F3D"
        backgroundImage={AuthBg}
        backgroundSize={'contain'}
        backgroundRepeat={'repeat'}
      ></Box>
      <Flex
        flex={'1 1 400px'}
        p={['1.5rem 1rem', '5.5rem 4rem']}
        bg="#F5F5F5"
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          padding={['2rem .7rem 1.5rem .7rem', '4rem 2rem 1.5rem 2rem']}
          bg="#FFF"
          width={'100%'}
          height={'100%'}
          boxShadow={'0px 4px 24px rgba(0, 0, 0, 0.16)'}
          borderRadius={'8px'}
          textAlign={'center'}
          border="0.5px solid #D0D5DD"
        >
          <Heading
            mt={6}
            fontWeight={'600'}
            fontSize={['1.35rem', '1.5rem']}
            color="#27272A"
          >
            {loginState === 'admin' ? 'Admin Login' : 'Client Login'}
          </Heading>
          <Text mt={2} mb="1rem" color={'#3F3F46'} fontSize={'.9rem'}>
            Jump back in.
          </Text>
          <form onSubmit={login}>
            <CustomInput
              placeholder={'Email Address'}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value?.toLowerCase())}
            />

            <CustomInput
              placeholder={'Password'}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Flex
              mb="2rem"
              mt=".5rem"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text></Text>
              <Text
                onClick={
                  () => {}
                  // navigate("/forgot-password", {
                  //   state: { userType: state?.userType },
                  // })
                }
                fontSize={'.9rem'}
                fontWeight={'400'}
                color="#F03738"
                cursor={'pointer'}
                transition={'250ms ease'}
                _hover={{ color: '#F03738', fontWeight: '500' }}
              >
                Forgot Password
              </Text>
            </Flex>
            <PrimaryButton type="submit" text="Login" loading={loading} />
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
