import { userActionTypes } from '../constants/userActionTypes';
import axiosInstance from '../../services/api';

export const loaduser = (navigate) => async (dispatch) => {
  try {
    if (localStorage.GPTK) {
      const { data } = await axiosInstance.get(`/users/current`);
      dispatch({ type: userActionTypes.LOADUSER_SUCCESS, payload: data?.data });
      if (data?.data?.role[data?.data?.role?.length - 1]?.name !== 'ADMIN') {
        navigate('/tournaments');
      }
    } else {
      localStorage.removeItem('GPTK');
      window.location.href = '/';
    }
  } catch (error) {
    dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
    localStorage.removeItem('GPTK');
    window.location.href = '/';
    console.log('heloooooo');
    if (error.response) {
      console.log(error.response.data);
      localStorage.removeItem('GPTK');
      window.location.href = '/';
    }
  }
};
