import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
  FormControl,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import CustomDrawer from "../../../components/customDrawer/customDrawer";
import CustomInput from "../../../components/customInput/customInput";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import axiosInstance2 from "../../../services/api2";
import axiosInstance from "../../../services/api";

const Rates = () => {
  const [triggerClose, setTriggerClose] = useState("");
  const [nairaRate, setNairaRate] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullSystemSettings, setFullSystemSettings] = useState({});
  const [rates, setRates] = useState("");
  const [refetch, setRefetch] = useState("")
  const [enableWalletActivation, setEnableWalletActivation] = useState()
  const [enable1v1, setEnable1v1] = useState()
  const [enableWallet, setEnableWallet] = useState()


  const toast = useToast();

  const getRates = async () => { 
    setLoading(true); 
    try { 
      const { data } = await axiosInstance.get("/system-settings"); 
      setRates(data?.data?.nairaToDollarRate); 
      setEnableWalletActivation(data?.data?.walletActivationEnabled)
      setEnable1v1(data?.data?.is1v1Enabled)
      setEnableWallet(data?.data?.walletEnabled)
      return data?.data; 
    } catch (error) { 
      console.error(error?.response?.data?.message); 
      return null;
    } finally { 
      setLoading(false); 
    }
  }; 
   
  useEffect(() => { 
    getRates(); 
  }, [refetch]); 
   
  const handleEditRate = async () => { 
    setEditLoading(true); 
    try { 
  
      const existingSettings = await getRates();

      const updatedSettings = {
        ...existingSettings,
        enableWalletActivation: enableWalletActivation,
        enable1v1: enable1v1,
        enableWallet: enableWallet,
        nairaToDollarRate: parseFloat(nairaRate)
      };
   
      const { data } = await axiosInstance.put("system-settings", updatedSettings); 
   
      toast({ 
        description: data?.message || "Rate Updated Successfully", 
        status: "success", 
        position: "top", 
      }); 
   
      setRefetch(Math.random()); 
      setTriggerClose("close" + Math.random()); 
    } catch (error) { 
      console.error("Error response:", error.response?.data); 
      const errorMessage = 
        error?.response?.data?.errors?.[0] || 
        error?.response?.data?.message || 
        "An unexpected error occurred"; 
   
      toast({ 
        description: errorMessage, 
        status: "error", 
        position: "top", 
      }); 
    } finally { 
      setEditLoading(false); 
    }
  };
  

  return (
    <>
      <Text
        whiteSpace={"nowrap"}
        fontWeight={"500"}
        fontSize={"1.15rem"}
        color={"#27272A"}
      >
        Rates
      </Text>

      <Box bg="#FFF">
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th textTransform={"capitalize !important"} color={"#475467"}>
                Dollar Rate
              </Th>
              <Th textTransform={"capitalize !important"} color={"#475467"}>
                Naira Rate
              </Th>
              <Th
                textTransform={"capitalize !important"}
                color={"#475467"}
              ></Th>
              <Th
                textTransform={"capitalize !important"}
                color={"#475467"}
              ></Th>
              <Th
                textTransform={"capitalize !important"}
                color={"#475467"}
              ></Th>
              <Th
                textTransform={"capitalize !important"}
                color={"#475467"}
              ></Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td color={"#475467"} fontSize={".9rem"}>
                $1
              </Td>
              <Td color={"#475467"} fontSize={".9rem"}>
                ₦{rates}
              </Td>
              <Td color={"#475467"} fontSize={".9rem"}></Td>
              <Td color={"#475467"} fontSize={".9rem"}></Td>
              <Td color={"#475467"} fontSize={".9rem"}></Td>
              <Td color={"#475467"} fontSize={".9rem"}>
                <Flex gap="1rem" alignItems={"center"}>
                  <CustomDrawer
                    position={"right"}
                    title={"Edit rate"}
                    triggerClose={triggerClose}
                    setTriggerClose={setTriggerClose}
                    toggleElement={
                      <Text
                        textDecoration={"underline"}
                        mb=".2rem"
                        color={"#101828"}
                        fontSize={".9rem"}
                        fontWeight={"500"}
                        textTransform={"capitalize"}
                        cursor={"pointer"}
                      >
                        Edit rate
                      </Text>
                    }
                    content={
                      <VStack spacing={4} width="100%">
                        <FormControl>
                          <FormLabel>Dollar Rate (Fixed at $1)</FormLabel>
                          <CustomInput
                            type="number"
                            placeholder="$1"
                            value={1}
                            isReadOnly
                            mb=".8rem"
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Naira Rate</FormLabel>
                          <CustomInput
                            type="number"
                            placeholder="Enter Naira Rate"
                            value={nairaRate}
                            onChange={(e) => setNairaRate(e.target.value)}
                            mb=".8rem"
                          />
                        </FormControl>
                        <PrimaryButton
                          mt="2rem"
                          text="Update Rates"
                          onClick={handleEditRate}
                          loading={editLoading}
                          width="100%"
                        />
                      </VStack>
                    }
                  />
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default Rates;