import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import LineSkeleton from '../../../../components/lineSkeleton';
import EmptyState from '../../../../components/emptyState/emptyState';
import axiosInstance from '../../../../services/api';
import { truncateText } from '../../../../utils/helper';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CustomInput from '../../../../components/customInput/customInput';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import CreateGame from './createGame';
import EditGame from './editGame';

const Games = ({ games, loading, setRefetch, consoles }) => {
  const [loadingc, setLoadingc] = useState(false);
  const [triggerClose, setTriggerClose] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleModal = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const deleteGame = async () => {
    setLoadingc(true);
    try {
      const { data } = await axiosInstance.delete(
        `games/${selectedUser?.id}`,
        {}
      );
      toast({
        description: data?.message,
        status: 'success',
        position: 'top',
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoadingc(false);
    }
  };

  return (
    <>
      <Flex alignItems={'center'} gap=".8rem" justifyContent={'space-between'}>
        <Text
          whiteSpace={'nowrap'}
          fontWeight={'500'}
          fontSize={'1.15rem'}
          color={'#27272A'}
        >
          Games{' '}
          <span
            style={{
              padding: '.1rem .3rem',
              borderRadius: '50%',
              backgroundColor: '#F9FAFB',
              border: '1px solid #EAECF0',
              fontSize: '12px',
              fontWeight: '500',
              color: '#344054',
            }}
          >
            {games?.length}
          </span>
        </Text>
        <CustomDrawer
          position={'right'}
          title={'Create Game'}
          triggerClose={triggerClose}
          setTriggerClose={setTriggerClose}
          toggleElement={
            <SecondaryButton
              type="submit"
              text={'Add Game'}
              icon="add"
              loading={false}
            />
          }
          content={
            <CreateGame
              setTriggerClose={setTriggerClose}
              setRefetch={setRefetch}
              consoles={consoles}
            />
          }
        />
      </Flex>

      <Box bg="#FFF">
        <Table mt="1.2rem" borderRadius={'12px'} overflow={'hidden'}>
          <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
              <Th textTransform={'capitalize !important'} color={'#475467'}>
                ID
              </Th>
              <Th textTransform={'capitalize !important'} color={'#475467'}>
                Image
              </Th>
              <Th textTransform={'capitalize !important'} color={'#475467'}>
                Name
              </Th>
              <Th textTransform={'capitalize !important'} color={'#475467'}>
                Consoles
              </Th>
              <Th textTransform={'capitalize !important'} color={'#475467'}>
                Users
              </Th>
              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
              <Th
                textTransform={'capitalize !important'}
                color={'#475467'}
              ></Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : games?.length < 1 ? (
              <EmptyState mt="1.5rem" text="There are no stores added yet" />
            ) : (
              games?.map(({ game, prefCount }) => {
                const data = { game, prefCount };
                return (
                  <Tr key={game?.id} cursor={'pointer'}>
                    <Td color={'#475467'} fontSize={'.9rem'}>
                      #{game?.id}
                    </Td>
                    <Td>
                      <Flex alignItems={'center'} gap={'.5rem'}>
                        <Box
                          height={'40px'}
                          width={'40px'}
                          borderRadius={'6px'}
                          bg="lightgray"
                          backgroundImage={game?.lightModeImageUrl}
                          backgroundSize={'cover'}
                        />
                        <Box
                          height={'40px'}
                          width={'40px'}
                          borderRadius={'6px'}
                          bg="lightgray"
                          backgroundImage={game?.darkModeImageUrl}
                          backgroundSize={'cover'}
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <Text
                        mb=".2rem"
                        color={'#101828'}
                        fontSize={'.9rem'}
                        fontWeight={'500'}
                        textTransform={'capitalize'}
                      >
                        {game?.name}
                      </Text>
                    </Td>
                    <Td color={'#475467'} fontSize={'.9rem'}>
                      {game?.console?.map((i, index) =>
                        index + 1 < game?.console?.length
                          ? `${i?.name}, `
                          : `${i?.name}.`
                      )}
                    </Td>
                    <Td color={'#475467'} fontSize={'.9rem'}>
                      {prefCount}
                    </Td>
                    <Td color={'#475467'} fontSize={'.9rem'}></Td>
                    <Td color={'#475467'} fontSize={'.9rem'}></Td>
                    <Td color={'#475467'} fontSize={'.9rem'}></Td>
                    <Td>
                      <Flex gap="1rem" alignItems={'center'}>
                        <CustomDrawer
                          position={'right'}
                          title={'Edit Game'}
                          triggerClose={triggerClose}
                          setTriggerClose={setTriggerClose}
                          toggleElement={
                            <Text
                              textDecoration={'underline'}
                              mb=".2rem"
                              color={'#101828'}
                              fontSize={'.9rem'}
                              fontWeight={'500'}
                              textTransform={'capitalize'}
                            >
                              Edit
                            </Text>
                          }
                          content={
                            <EditGame
                              gameData={data}
                              setTriggerClose={setTriggerClose}
                              setRefetch={setRefetch}
                              consoles={consoles}
                            />
                          }
                        />
                        <Text
                          onClick={() => handleModal(data?.game)}
                          textDecoration={'underline'}
                          mb=".2rem"
                          color={'crimson'}
                          fontSize={'.9rem'}
                          fontWeight={'500'}
                          textTransform={'capitalize'}
                        >
                          Delete
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </Box>

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'1rem'} textTransform={'capitalize'}>
            Delete Game
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Text textAlign={'center'} color={'#475467'} fontWeight={'500'}>
                Confirm deletion of {selectedUser?.name}?
              </Text>
              <Flex justifyContent={'center'} my="1.3rem">
                <PrimaryButton
                  onClick={deleteGame}
                  text="Delete"
                  width="150px"
                  bg="crimson !important"
                  padding="1rem !important"
                  loading={loadingc}
                />
              </Flex>
            </>
            <Text
              pb="1rem"
              textAlign={'center'}
              fontSize={'.9rem'}
              fontWeight={'500'}
              cursor={'pointer'}
              onClick={onClose}
            >
              Cancel
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Games;
