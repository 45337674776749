import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import CustomSelect from '../../../../components/customSelect/customSelect';
import UploadIcon from './upload.svg';

const UploadVoucher = ({ setTriggerClose, max, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);
  const [games, setGames] = useState([]);
  const [totalGames, setTotalGames] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);

  const toast = useToast();

  const getGames = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/games');
      setGames(data?.data?.content);
      setTotalGames(data?.data?.content?.length);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, []);

  const gameData = games?.map((game) => ({
    title: game.name,
    value: game.ulid,
  }));
  console.log('game', gameData, games);

  useEffect(() => {
    console.log('file', selectedFile);
  }, [selectedFile]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (selectedFile.length === 0) {
      toast({
        description: 'No file selected',
        status: 'error',
        position: 'top',
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      console.log('fire3', formData);
      const response = await axiosInstance2.post(
        `/game-voucher/upload-vouchers/${selectedGame}`,
        formData
      );
      console.log('File upload response:', response);
      toast({
        description: response?.data?.message || 'Voucher Uploaded Successfully',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        description: `Error uploading file: ${error.response.data.error.message}`,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={uploadFile}>
        <CustomSelect
          required
          mb="2rem"
          placeholder={'Select Product'}
          options={gameData}
          onChange={(e) => setSelectedGame(e.target.value)}
        />
        <Flex
          position={'relative'}
          height={'100px'}
          alignItems={'center'}
          borderRadius={'8px'}
          width={'100%'}
          border="1px dashed #D0D5DD"
          cursor={'pointer'}
          boxSizing="border-box"
          padding={'1rem'}
        >
          <Image src={UploadIcon} />
          <>
            {loading ? (
              <Text
                ml="1rem"
                color={'#344054'}
                fontSize={'.9rem'}
                whiteSpace={'nowrap'}
              >
                Processing {max > 1 ? 'files' : 'file'}{' '}
                <Spinner
                  transform={'translateY(10px)'}
                  ml=".3rem"
                  size={'xs'}
                />
              </Text>
            ) : (
              <Text
                ml="1rem"
                color={'#344054'}
                fontSize={'.9rem'}
                whiteSpace={'nowrap'}
              >
                Upload {max > 1 ? 'Vouchers' : 'Voucher'} here{' '}
                {max < 10 && `(max ${max})`}
              </Text>
            )}
            {!loading === true && (
              <>
                <Input
                  style={{
                    height: '100%',
                    cursor: 'pointer',
                    opacity: '0',
                    position: 'absolute',
                    left: '0',
                  }}
                  type="file"
                  multiple
                  maxLength={2}
                  onChange={handleFileChange}
                />
              </>
            )}
          </>
        </Flex>
        {selectedFile && (
          <Text color={'#344054'} fontSize={'.9rem'} whiteSpace={'nowrap'}>
            {selectedFile?.name}{' '}
          </Text>
        )}
        <PrimaryButton
          mt="2rem"
          text="Upload Voucher"
          type="submit"
          loading={loading}
          isDisabled={selectedGame === '' || selectedFile.length < 1}
        />
      </form>
    </>
  );
};

export default UploadVoucher;
