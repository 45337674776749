import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { loaduser } from './redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { userActionTypes } from './redux/constants/userActionTypes';
import Login from './pages/auth/login/login';
import Signup from './pages/auth/signup/signup';
import ForgotPassword from './pages/auth/forgotPassword/forgotPassword';
import ResetPassword from './pages/auth/forgotPassword/resetPassword';
import Dashboard from './pages/dashboard/dashboard';
import Home from './pages/dashboard/home/home';
import SpinLoader from './components/spinLoader/spinLoader';
import InitiativeDetails from './pages/dashboard/tournaments/tournamentDetails/tournamentDetails';
import Settings from './pages/settings/settings';
import Tournaments from './pages/dashboard/tournaments/tournaments';
import Gamers from './pages/dashboard/gamers/gamers';
import CreateInitiative from './pages/dashboard/tournaments/createInitiative/createInitiative';
import CommunityManagers from './pages/dashboard/communityMangers/communityManagers';
import CommunityReps from './pages/dashboard/communityReps/communityReps';
import Matches from './pages/dashboard/1v1/1v1';
import Match from './pages/dashboard/1v1/match/match';
import Payouts from './pages/dashboard/wallet/payouts/payouts';
import Porteshop from './pages/dashboard/porteshop/porteshop';
import EmailAndNotification from './pages/dashboard/emailAndNotifications/emailAndNotifications';
import GamersAndCommunity from './pages/dashboard/gamers/gamersAndCommunities';
import Transactions from './pages/dashboard/wallet/transactions/transactions';
import Wallet from './pages/dashboard/wallet/wallet';
import Onevone from './pages/dashboard/1v1/1v1';
import RequestsView from './pages/dashboard/home/requestsView/requestsView';
import RequestMgmt from './pages/requestMgmt/requestMgmt';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  const loading = useSelector(({ userData }) => userData?.isLoading);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  useEffect(() => {
    if (localStorage.GPTK) {
      dispatch(loaduser(navigate));
    } else {
      dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
    }
  }, []);

  return (
    <>
      {loading ? (
        <SpinLoader />
      ) : (
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                currentUser ? (
                  <Dashboard
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    component={<Home />}
                  />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/reset" element={<ResetPassword />} />
            {/* <Route path='/verify'  element={<Verify />} />
          <Route path='/verify/success'  element={<VerifySuccess />} /> */}
            <Route
              path="/initiatives/create/:step"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<CreateInitiative />}
                />
              }
            />
            <Route
              path="/tournaments"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Tournaments />}
                />
              }
            />
            <Route
              path="/tournaments/:id"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<InitiativeDetails />}
                />
              }
            />
            <Route
              path="/gamers"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<GamersAndCommunity />}
                />
              }
            />
            {/*< Route
              path="/managers"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<CommunityManagers />}
                />
              }
            />
            <Route
              path="/reps"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<CommunityReps />}
                />
              }
            /> */}
            <Route
              path="/1v1"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Onevone />}
                />
              }
            />
            <Route
              path="/request"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<RequestMgmt />}
                />
              }
            />
            <Route
              path="/1v1/:id"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Match />}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Settings />}
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Wallet />}
                />
              }
            />
            <Route
              path="/payouts"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Payouts />}
                />
              }
            />
            <Route
              path="/shop"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<Porteshop />}
                />
              }
            />
            <Route
              path="/notifications"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<EmailAndNotification/>}
                />
              }
            />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
