import { Box, Flex, FormControl, FormLabel, Image, Input, Select, Text, Textarea, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../../../../../components/secondaryButton/secondarybutton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const About = ({regulations, setRegulations, location, setLocation, setAddress,bracketType, setBracketType, setParticipantType, participantType, setNumInd, setNumTeam, setTeamSize, setTimeInterval, handleContinue}) => {
  const isMobileView = useBreakpointValue({ base: true, md: false });


  return (
    <Box width={{ base: "100%", md: "70%" }} mx="auto">
        <FormControl mb="1.3rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Tournament Location
            </FormLabel>
            <Select
              type="text"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option selected disabled>Select Location</option>
              <option value="ONLINE">ONLINE</option>
              <option value="OFFLINE">OFFLINE</option>
            </Select>
            </FormControl>
            {location === "OFFLINE" &&
              <FormControl mb="1rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Tournament Address
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter Address"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              // value={title}
              onChange={(e) => setAddress(e.target.value)}
            />
            </FormControl>}
            <FormControl mb="1.3rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Bracket Type
            </FormLabel>
            <Select
              type="text"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              onChange={(e) => setBracketType(e.target.value)}
            >
              <option selected disabled>Select Bracket Type</option>
              <option value="BATTLE_ROYALE">Battle Royale</option>
              <option value="LEAGUE">League <span style={{fontSize: ".7rem"}}>(coming soon)</span></option>
              <option value="KNOCKOUT">Knockout <span style={{fontSize: ".7rem"}}>(coming soon)</span></option>
              <option value="ROUND_ROBIN_KNOCKOUT">Round Robin Knockout <span style={{fontSize: ".7rem"}}>(coming soon)</span></option>
              <option value="LADDER">Ladder <span style={{fontSize: ".7rem"}}>(coming soon)</span></option>
              <option value="SINGLE_ELIMINATION">Double Elimination <span style={{fontSize: ".7rem"}}>(coming soon)</span></option>
            </Select>
            </FormControl>
            <Text fontWeight={"500"} color="#0A0A0B" fontSize={".9rem"} mb=".5rem">Participant Type</Text>
            <Flex justifyContent={"space-between"} gap="1.5rem" mb="1.2rem">
                    <Box cursor={"pointer"} onClick={() => setParticipantType("INDIVIDUAL")} flex={"1 1 200px"} bg={participantType === "INDIVIDUAL" ? "#F9F5FF" : "#EAEAEB"} transition={"250ms ease"} border="1px solid #E9D7FE" borderRadius={"12px"} p=".5rem 3rem" boxShadow="0px 2px 8px 0px #00000014">
                        <Text mt=".2rem" fontWeight={"500"} fontSize={".85rem"} color={participantType !== "INDIVIDUAL" ? "#000" : "#6941C6"} textAlign="center">Individual</Text>
                    </Box>
                    <Box cursor={"pointer"} onClick={() => setParticipantType("TEAM")} flex={"1 1 200px"} bg={participantType === "TEAM" ? "#F9F5FF" : "#EAEAEB"} transition={"250ms ease"} border="1px solid #E9D7FE" borderRadius={"12px"} p=".5rem 3rem" boxShadow="0px 2px 8px 0px #00000014">
                        <Text mt=".2rem" fontWeight={"500"} fontSize={".85rem"} color={participantType !== "TEAM" ? "#000" : "#6941C6"} textAlign="center">Team</Text>
                    </Box>
            </Flex>

            <FormControl mb="1.2rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Number of {participantType === "INDIVIDUAL" ? "Individuals" : "Teams"}
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter Number"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              // value={title}
              onChange={(e) => participantType === "INDIVIDUAL" ? setNumInd(parseInt(e.target.value)) : setNumTeam(parseInt(e.target.value))}
            />
            </FormControl>

            {participantType !== "INDIVIDUAL" &&
              <FormControl mb="1rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Team Size
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter Number"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              // value={title}
              onChange={(e) => setTeamSize(parseInt(e.target.value))}
            />
            </FormControl>}

            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Tournament Regulations <span style={{opacity: ".5", fontSize: ".8rem"}}>(Editable)</span>
            </FormLabel>
            <ReactQuill style={{height: "133px", marginBottom: ".7rem"}} theme="snow" value={regulations} onChange={setRegulations} />
            <br />
            {/* <FormControl mb="1rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Regulations
            </FormLabel>
            <Textarea
              type="text"
              placeholder="Enter Tournament Regulations"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              rows="12"
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              // value={title}
              onChange={(e) => setTeamSize(parseInt(e.target.value))}
            />
            </FormControl> */}

            <br />
            <Box mt={{base: "20px", md: "0"}}>
            <SecondaryButton onClick={handleContinue} type="submit" bg="#244CC6" color="#FFF" width="100%" text="Next"/>
            </Box>
    </Box>
  )
}

export default About