import { Avatar, Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react'
import { removeQuotes } from "../../../../../../utils/helper"

const Rules = ({state}) => {

    const ruleData = [
        {
            title: "Match System",
            points: [
                `A set of ${state?.gameFormat?.split(" ")[2]} matches will be played one after the other. A lobby will be created for the competition and the details will be sent to the appropriate channels. Players will be given a 15 minute grace period to join the lobby before the commencement of the tournament.`
            ],
            isList: false
        },
        {
            title: "General Rules",
            points: [
                "External devices including: controllers and emulators are BANNED",
                "Unfair gameplay and advantages such as glitches, cheating software, etc are BANNED.",
                "VPN is BANNED.",
                "Players MUST be registered to participate in events, if it is found you are using players that are not registered in your matches; it will result in team disqualification from the match.",
                "Change of IGNs during the course of tourneys is BANNED.",
                "This tourney is open to Nigerian players ONLY."
            ],
            isList: true
        },
        {
            title: "Lobby Rules",
            points: [
                "Match lobbies are created 15 mins before the set time, no late coming will be tolerated. A team is required to have at least 2 members in the lobby in order to play the match. Less than 2 members on the team at the allocated start time will result in the team being kicked from the lobby and forfeit of that match.",
                "Room code/ID will be dropped in the appropriate channel once created.",
                "Please maintain your slot number, Failure to do so will result in a forfeit of that match.",
                "After each map, a 5 mins waiting time will be given before the start of the next map.",
                "NO teaming up is allowed (two separate teams teaming together to split points/prize pool etc). If caught, both teams will suffer a deduction of points",
                "Teams may be required to drop a screenshot of final standings in the allocated channel for verification and tallying of points."
            ],
            isList: true
        },
        {
            title: "Technical issues",
            points: [
                "Each participant is responsible for the problems on their mobile devices.",
                "A player is allowed to reconnect upon disconnection from the lobby. If he fails to reconnect, the game will not be paused. He must join the lobby after the game.",
                "Any team/player that registers for any Gameporte tournament and fails to play will not be allowed to participate in further tournaments."
            ],
            isList: true
        },
        {
            title: "Payment",
            points: [
                "Payments are not made immediately after the tournament ends.",
                "Note: Payment is made within 2 working days."
            ],
            isList: false
        },
    ]


  return (
    <Box>
        <Text fontWeight={"600"} fontSize={"1.2rem"}>Tournament Rules</Text>
        <br />
        <div dangerouslySetInnerHTML={{ __html: state?.regulations }} />
        {/* <Accordion defaultIndex={[0]} allowToggle mt="1.5rem">
        {ruleData.map(({title, points, isList}) => {
            return (
                    <AccordionItem key={title}>
                        <h2>
                        <AccordionButton _focus={{border: "none"}} bg="#244CC6" color="#FFF" _hover={{backgroundColor: "#244CC6"}}>
                            <Box as="span" flex='1' textAlign='left' py=".5rem">
                                <Text color="#FFF" fontWeight={"600"}>{title}</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                       {!isList ?
                       <AccordionPanel pb={4} pt=".5rem" color={"#4E5055"}>
                            {points?.map((i) => (
                                    <Text key={i} fontWeight={"500"}>{i}</Text>
                            ))}
                        </AccordionPanel>
                        :
                        <AccordionPanel pb={4} pt=".5rem" color={"#4E5055"}>
                            <UnorderedList lineHeight={"28px"}>
                                {points?.map((i) => (
                                    <ListItem key={i} fontWeight={"500"} mb=".7rem">{i}</ListItem>
                                ))}
                            </UnorderedList>
                            
                        </AccordionPanel>
                        }
                    </AccordionItem>
                
            )})}
            </Accordion> */}
    </Box>
  )
}

export default Rules