import { Box, Heading, Image, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import CustomInput from "../../../components/customInput/customInput";
import AuthBg from "../../../assets/bg-element.webp";
import FullLogo from "../../../assets/full-logo.svg";
import Illustration from "./illustration.svg";
import axiosInstance2 from "../../../services/UnAuthApi";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {state} = useLocation()

  const toast = useToast();

  const navigate = useNavigate();

  const datax = {
    token: state?.token,
    newPassword: password,
    confirmPassword: cpassword
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance2.post("auth/reset-password/update", datax);
      navigate("/verify/success");
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0],
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width={"100vw"}
      height="90vh"
      overflow={"hidden"}
      backgroundImage={AuthBg}
      backgroundSize="cover"
      p="1rem"
    >
      <Box
        padding={["2rem .7rem 1.5rem .7rem", "2rem 2rem 1.5rem 2rem"]}
        maxWidth={"600px"}
        bg="#003C4E"
        mx="auto"
        mt="3vh"
        boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
        borderRadius={["16px", "40px"]}
        textAlign={"center"}
      >
        <Image src={FullLogo} width={["50%", "33%"]} mx="auto" />
        <Image mt="2rem" src={Illustration} width={["35%", "16%"]} mx="auto" />
        <Heading
          mt={6}
          fontWeight={"700"}
          fontSize={["1.35rem", "1.5rem"]}
          color="#FFF"
        >
          New Password
        </Heading>
        <Text mt={2} mb="1rem" color={"#CFD0D3"} fontSize={".9rem"}>
          Enter new password to change your account password.
        </Text>
        <form onSubmit={resetPassword}>
          <CustomInput
            placeholder={"Password"}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomInput
            placeholder={"Confirm Password"}
            type="password"
            value={cpassword}
            onChange={(e) => setcPassword(e.target.value)}
          />
          <br />
          <PrimaryButton type="submit" text="Reset password" loading={loading} />
          <Text
            mt="1.2rem"
            mb=".3rem"
            textAlign={"center"}
            fontSize={".9rem"}
            color="#CFD0D3"
          >
            Back to{" "}
            <span
              onClick={() =>
                navigate("/")
              }
              style={{
                fontWeight: "500",
                color: "#5DDAFF",
                cursor: "pointer",
                transition: "250ms ease",
              }}
            >
              Login
            </span>
          </Text>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPassword;
