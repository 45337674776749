import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buildResourceURL, truncateText } from '../../../../utils/libs/helper'
import LineSkeleton from '../../../../components/lineSkeleton'
import EmptyState from '../../../../components/emptyState/emptyState'
import CustomPagination from '../../../../components/customPagination/customPagination'
import axiosInstance from '../../../../services/api'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import AddGameMode from '../addGameMode/addGameMode'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'

const Matches = () => {
    const [matches, setMatches] = useState([]);
    const [totalMatches, setTotalMatches] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [refetch, setRefetch] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [triggerClose, setTriggerClose] = useState("");
    const toast = useToast()

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);

    const navigate = useNavigate()


    const queryParams = [
        {
            key: 'page',
            value: page
        },
        {
            key: 'size',
            value: limit
        },
        {
            key: 'size',
            value: limit
        }
    ]

    const getMatches = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('one-v-one/individual/all', queryParams))
            setMatches(data?.data?.content)
            setTotalMatches(data?.data?.totalElements)
            setTotalPages(data?.data?.totalPages)
        } catch(error) {
            console.error(error?.response?.data?.errors[0])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMatches()
    }, [page, limit, refetch])


    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const deleteMatch = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`one-v-one/individual/${selectedUser?.id}`)
            toast({
            description: "1v1 Match deleted" || data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"} overflowX="auto"
    whiteSpace="nowrap"
    sx={{
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}>
  
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Matches <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalMatches}</span></Text>
                {/* <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={'Search Matches..'}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                /> */}
            </Flex>

            <Flex alignItems={"center"} gap="1rem">
            
            {/* <CustomDrawer
            position={"right"}
            title={`Add Game Mode`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Add Game Mode`} icon="add" loading={false}  />
            }
            content={
                <AddGameMode setRefetch={setRefetch} setTriggerClose={setTriggerClose} />
            }
            /> */}

            </Flex>

        </Flex>
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Game</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Host</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Opponent</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Description</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Console</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Prize</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            matches?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no managers yet" />
            :
            <Tbody>
            {matches?.map((({id, gameMode, email, host, opponent, gameName, consoleName, entryFee, prize, status, format}) => {
                return (
                    <Tr key={id}>
                        <Td color={"#475467"} fontSize={".9rem"}>{id}</Td>
                        <Td>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{gameName}</Text>
                          <Text mb=".2rem" color={"#101828"} fontSize={".85rem"} fontWeight={"400"} textTransform={"capitalize"}>{gameMode?.name}</Text>
                          <Text mb=".2rem" opacity={".7"} color={"#101828"} fontSize={".85rem"} fontWeight={"400"} textTransform={"capitalize"}>{truncateText(gameMode?.description, 30)}</Text>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{host?.username}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{opponent?.username || "--"}</Td>
                        <Td color={"#475467"} fontSize={".8rem"}>{format}</Td>
                        <Td color={"#475467"} fontSize={".8rem"}>{consoleName}</Td>
                        <Td color={"#475467"} fontSize={".8rem"} whiteSpace={"nowrap"}><strong>₦{entryFee?.toLocaleString()}</strong> to win <strong>₦{prize?.toLocaleString()}</strong></Td>
                        <Td color={"#475467"} fontSize={".9rem"}>
                            <Text textAlign={"center"} fontWeight={"500"} fontSize={".6rem"} p=".1rem .1rem" borderRadius={"16px"} border={status === "PENDING" ? "1px solid lightgray" : status === "SCHEDULED" ? "1px solid goldenrod" : "1px solid #ABEFC6"} color={status === "PENDING" ? "#475467" : status === "SCHEDULED" ? "goldenrod" : "#067647"} bg={status === "PENDING" ? "#F9FAFB" : status === "SCHEDULED" ? "" : "#ECFDF3"}>{status}</Text>
                        </Td>
                        <Td>
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => navigate(`/1v1/${id}`)} fontWeight={"500"} fontSize={".85rem"}>View Match</MenuItem>
                                    {/* <Divider my=".2rem" />
                                    {status !== "Completed" && <MenuItem onClick={() => handleModal(status === "Pending" ? 'start' : 'restart', {title, id, status})} fontWeight={"500"} fontSize={".85rem"}>{status === "Pending" ? "Start" : status === "Ongoing" ? "Restart" : ""} Initiative</MenuItem>}
                                    <Divider my=".2rem" />
                                    {status === "Ongoing" && <MenuItem onClick={() => handleModal('complete', {title, id, status})} fontWeight={"500"} fontSize={".85rem"}>Complete Initiative</MenuItem>}
                                    <Divider my=".2rem" /> */}
                                    {/* {status === "PENDING" &&
                                    <>
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => handleModal('delete', {title: `${gameName} Match between ${host?.username} & ${opponent?.username || "--"}`, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete Match</MenuItem>
                                    </>} */}
                                    <Divider my=".2rem" />
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>


        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} 1v1 Match`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.title}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteMatch} text="Deactivate" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>

       

        <CustomPagination
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={totalPages}
         />
    </Box>
  )
}

export default Matches;