import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance3 = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json ',
    'Access-Control-Allow-Headers': 'Content-Type',
  },
});

export default axiosInstance3;
