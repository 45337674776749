import React, { useEffect, useState } from 'react';
import { Flex, Image, Input, Spinner, Text, Box } from '@chakra-ui/react';
import NewUploadIcon from '../../assets/newUpload.png';
import axiosInstance from '../../services/api';

const SendNotificationImageUploader = ({ onImagesUpload, setLoading, loading, max, required, onChange, ...props }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageName, setImageName] = useState('');

  useEffect(() => {
    if (selectedImages?.length > 0) {
      uploadImages(selectedImages);
    }
  }, [selectedImages]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, max);
    setSelectedImages(files);
    setImageName(files[0]?.name || '');
  };

  const uploadImages = async () => {
    setLoading(true);
    const uploadedImageUrls = [];

    for (const image of selectedImages) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const response = await axiosInstance.post('/file/upload', formData);
        console.log(response);
        if (response?.data?.data) {
          uploadedImageUrls.push(response?.data?.data);
        }
      } catch (error) {
        console.error('Error uploading image: ', error);
      } finally {
        setLoading(false);
      }
    }
    onImagesUpload(uploadedImageUrls);
  };

  return (
    <Flex
      position={'relative'}
      height={'40px'} 
      alignItems={'center'}
      justifyContent={'space-between'}
      borderRadius={'8px'}
      width={'100%'}
      border="1px dashed #D0D5DD"
      cursor={'pointer'}
      boxSizing="border-box"
      padding={'13px'}
    >
      <Text
        color={'#344054'}
        fontSize={'.9rem'}
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
      >
        {loading ? (
          <>
            Processing {max > 1 ? 'images' : 'image'}{' '}
            <Spinner transform={'translateY(2px)'} ml=".3rem" size={'xs'} />
          </>
        ) : (
          imageName || 'Add attachment here'
        )}
      </Text>
      <Image src={NewUploadIcon} height={"20px"} width={"20px"} /> 
      {!loading && (
        <Input
          style={{
            height: '100%',
            cursor: 'pointer',
            opacity: '0',
            position: 'absolute',
            left: '0',
          }}
          type="file"
          multiple
          maxLength={2}
          onChange={handleImageChange}
          cursor="pointer"
          required={required}
          
        />
      )}
    </Flex>
  );
};

export default SendNotificationImageUploader;
