import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useState } from 'react';
import CustomTabs from '../../../components/customTabs/customTabs';
import Voucher from './voucher/voucher';
import Games from './games/games';
import GiftCards from './giftCards/giftCards';
import Countries from './countries';
import GiftCardType from './giftCards/gitCardType';

const Settings = () => {
  const data = [
    {
      title: 'Porteshop Products',
      render: <Games />,
    },
    {
      title: 'Vouchers',
      render: <Voucher />,
    },
    {
      title: 'Gift Cards',
      render: <GiftCards />,
    },
    {
      title: 'Gift Card Types',
      render: <GiftCardType />,
    },
    {
      title: 'Country Code',
      render: <Countries />,
    },
  ];

  return (
    <Box>
      <CustomTabs data={data} />
    </Box>
  );
};

export default Settings;
