import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import CustomSelect from '../../../../components/customSelect/customSelect';
import UploadIcon from './upload.svg';

const EditGiftCardType = ({ gameData, setTriggerClose, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(gameData?.name || '');
  const [description, setDescription] = useState(gameData?.description || '');
  const [selectedCountry, setSelectedCountry] = useState(
    gameData?.country?.code || ''
  );
  const [countries, setCountries] = useState([]);

  const toast = useToast();

  // Fetch countries as in Create component
  const getCountry = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/country');
      const formattedCountries = data?.data?.map((country) => ({
        title: country?.name,
        value: country?.code,
      }));
      setCountries(formattedCountries);
    } catch (error) {
      toast({
        description: 'Failed to load countries',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  // Function to handle update
  const updateGiftCardType = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestBody = {
      name,
      description,
      countryCode: selectedCountry,
      code: name
        .replace(/[^\w\s]/g, '') // Remove non-alphanumeric characters (e.g., currency symbols)
        .replace(/\s+/g, '_'), // Replace spaces with underscores
    };

    try {
      const response = await axiosInstance2.put(
        `/gift-card-type/${gameData.ulid}`, // Assuming gameData has an `id` for the existing gift card type
        requestBody
      );
      toast({
        description:
          response?.data?.message || 'Gift Card Type Updated Successfully',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        description: `Error Updating Gift Card Type: ${
          error?.response?.data?.errors[0] || 'Unknown error'
        }`,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={updateGiftCardType}>
        <CustomInput
          type="text"
          required
          placeholder="Gift Card Type Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          mb="2rem"
          placeholder="Select Country"
          options={countries}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        />
        <CustomInput
          type="text"
          required
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          mb=".8rem"
        />
        <PrimaryButton
          mt="2rem"
          text="Update"
          type="submit"
          loading={loading}
          isDisabled={name === '' || selectedCountry === ''}
        />
      </form>
    </>
  );
};

export default EditGiftCardType;
