import { ExportJsonCsv } from 'react-export-json-csv';
import { BiExport } from 'react-icons/bi';

const ExportCSV = ({headers, data}) => {

    const styles = {
        backgroundColor: "#F9F5FF",
        borderRadius: "12px",
        border: "1px solid #E9D7FE",
        padding: ".2rem .6rem",
        height: "40px",
        color: "#6941C6",
        fontWeight: "600",
        whiteSpace: "nowrap",
        fontSize: ".9rem"
    }
    
  return (
    <ExportJsonCsv style={styles} headers={headers} items={data}><BiExport style={{transform: "rotate(90deg) scale(1.5)", marginRight: ".5rem", display: "inline"}} /> Export</ExportJsonCsv>
  )
}

export default ExportCSV