import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useState } from 'react';
import CustomTabs from '../../../components/customTabs/customTabs';
import Transactions from './transactions/transactions';
import Payouts from './payouts/payouts';

const Wallet = () => {
  const data = [
    {
      title: 'Transactions',
      render: <Transactions />,
    },
    {
      title: 'Payouts',
      render: <Payouts />,
    },
  ];

  return (
    <Box>
      <CustomTabs data={data} />
    </Box>
  );
};

export default Wallet;
