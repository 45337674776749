import React, { useState } from 'react';
import { Select, Popover, PopoverTrigger, PopoverContent, PopoverBody, Input, Icon, Button, VStack, HStack, Text, useToast } from '@chakra-ui/react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { ChevronDownIcon } from '@chakra-ui/icons';
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton';
import ArrowDown from "../../../../assets/chevron-down.png"

const DateRangeSelector = ({ onDateRangeChange }) => {
  const [selectedOption, setSelectedOption] = useState('1 month');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toast = useToast();

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    
    const today = new Date();
    let startDate, endDate;

    switch (option) {
      case '1 day':
        startDate = new Date(today.setDate(today.getDate() - 1));
        endDate = new Date();
        break;
      case '7 days':
        startDate = new Date(today.setDate(today.getDate() - 7));
        endDate = new Date();
        break;
      case '1 month':
        startDate = new Date(today.setMonth(today.getMonth() - 1));
        endDate = new Date();
        break;
      case 'custom':
        setIsOpen(true);
        return;
      default:
        return;
    }

    onDateRangeChange(formatDate(startDate), formatDate(endDate));
  };

  const handleCustomDateSubmit = () => {
    if (customStartDate && customEndDate) {
      const start = new Date(customStartDate);
      const end = new Date(customEndDate);
      const today = new Date();

      if (start > today || end > today) {
        toast({
          title: "Invalid Date Range",
          description: "You cannot select future dates.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top"
        });
        return;
      }

      if (start > end) {
        toast({
          title: "Invalid Date Range",
          description: "Start date must be before end date.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top"
        });
        return;
      }

      onDateRangeChange(formatDate(start), formatDate(end));
      setIsOpen(false);
    }
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  return (
    <>
      <Select value={selectedOption} onChange={handleOptionChange} icon={<Icon as={ChevronDownIcon} />} sx={{
        backgroundColor: "#F9F5FF",
        color: "#6941C6",
        fontSize: "14px",
        fontWeight: "600",
        option: {
          color: "black",
          fontWeight: "400",
          fontSize: "14px",
        },
      }}>
        <option value="1 day">Timeframe: 1 day</option>
        <option value="7 days">Timeframe: 7 days</option>
        <option value="1 month">Timeframe: 1 month</option>
        <option value="custom">Timeframe: Custom</option>
      </Select>
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <span /> 
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <VStack spacing={4} alignItems="center">
                <HStack spacing={2}>
                <Text fontWeight="400" mt={"5px"} fontSize="14px">Start Date:</Text>
                <Input
                    py="0.5"
                    type="date"
                    borderRadius="10px"
                    value={customStartDate}
                    onChange={(e) => setCustomStartDate(e.target.value)}
                />
                </HStack>
                <HStack spacing={2}>
                <Text fontWeight="400" fontSize="14px">End Date:</Text>
                <Input
                    py="0.5"
                    type="date"
                    borderRadius="10px"
                    value={customEndDate}
                    onChange={(e) => setCustomEndDate(e.target.value)}
                />
                </HStack>
                <SecondaryButton width="240px" text="Apply" onClick={handleCustomDateSubmit} />
            </VStack>
            </PopoverBody>

        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateRangeSelector;