import React from 'react'
import { Box } from '@chakra-ui/react';
import ApprovalRequests from './approvalRequest/approvalRequest';
import MyRequests from './myRequests/myRequests';
import CustomTabs from '../../components/customTabs/customTabs';

const RequestMgmt = () => {
    const data = [
        {
            title: 'Approval Requests',
            render: <ApprovalRequests />,
        },
        {
          title: 'My Requests',
          render: <MyRequests />,
        },
      ];


  return (
    <Box >
    <CustomTabs data={data} />
</Box>
  )
}

export default RequestMgmt