import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Text,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../services/api";
import Metrics from "../../../../components/metrics/metrics";
import ChartView from "../chartView/chartView";
import ExportCSV from "../../../../components/exportCsv";

const Gameporte = ({ dateRange = {}, onMetricsCsvChange }) => {
  const [totalClients, setTotalClients] = useState(0);
  const [totalPromoters, setTotalPromoters] = useState(0);
  const [totalSupervisors, setTotalSupervisors] = useState(0);
  const [metricsCsv, setMetricsCsv] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [loading, setLoading] = useState(true);

  const fetchMetrics = useCallback(
    async (start, end) => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/metric", {
          params: {
            startDate: start,
            endDate: end,
          },
        });

        const metrics = response.data.data;

        const metricsCSVData = [
          {
            totalSignUp: metrics.totalSignUp || 0,
            totalLogin: metrics.totalLogin || 0,
            totalUsers: metrics.totalUsers || 0,
            startDate: start,
            endDate: end,
          },
        ];

        const mappedMetrics = [
          { title: "Total Users", value: metrics.totalUsers || 0 },
          { title: "Total Sign-Ups", value: metrics.totalSignUp || 0 },
          { title: "Total Logins", value: metrics.totalLogin || 0 },
        ];

        setMetricsCsv(metricsCSVData);
        setMetricsData(mappedMetrics);
        setTotalClients(metrics.totalUsers);
        setTotalSupervisors(metrics.totalLogin);
        setTotalPromoters(metrics.totalSignUp);

        onMetricsCsvChange(metricsCSVData);
      } catch (error) {
        console.error("Error fetching metrics data:", error);
      } finally {
        setLoading(false);
      }
    },
    [onMetricsCsvChange]
  );

  useEffect(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

    const startDate =
      dateRange.startDate || thirtyDaysAgo.toISOString().split("T")[0];
    const endDate = dateRange.endDate || today.toISOString().split("T")[0];

    fetchMetrics(startDate, endDate);
  }, [dateRange.startDate, dateRange.endDate, fetchMetrics]);

  const churnRate = totalClients > 0 ? (totalClients / totalClients) * 100 : 0;

  const headers = [
    { key: "totalSignUp", name: "Total Sign-Ups" },
    { key: "totalLogin", name: "Total Logins" },
    { key: "totalUsers", name: "Total Users" },
    { key: "startDate", name: "Start Date" },
    { key: "endDate", name: "End Date" },
  ];

  return (
    <Box
      // overflowX="auto"
      // whiteSpace="nowrap"
      // sx={{
      //   "&::-webkit-scrollbar": { display: "none" },
      //   "-ms-overflow-style": "none",
      //   "scrollbar-width": "none",
      // }}
    >
      {currentUser?.role !== "Client" && (
        <Metrics data={metricsData} loading={loading} />
      )}
      <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>

        <Box flexGrow={1} height="320px" mb={5}>
          <ChartView height="335px" />
        </Box>
        <Box
          // boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          width="343px"
          height="335px"
          bg="#FFF"
          border=".5px solid #8E8E9333"
          p="30px"
          borderRadius="12px"
        >
          <Flex mb="50px" alignSelf="flex-start">
            <Text fontWeight="500" fontSize="20px" color={"#101321"}>
              Customer Value
            </Text>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center" mt="20px">
            <Box position="relative" width="120px" height="120px">
              <CircularProgress
                value={100}
                size="160px"
                thickness="4px"
                color="gray.200"
                position="absolute"
                top="0"
                left="0"
              />

              <CircularProgress
                value={churnRate}
                size="160px"
                thickness="8px"
                color="#0071FE"
                capIsRound
                position="absolute"
                top="0"
                left="0"
              >
                <CircularProgressLabel fontSize={"20px"} fontWeight={"700"}>
                  Churn Rate
                </CircularProgressLabel>
              </CircularProgress>
            </Box>

            <Flex alignItems="center" ml="20px">
              <Box width="13px" height="13px" bg="#0071FE" mr="8px" />
              <Box>
                <Text fontWeight="500" fontSize="14px" color={"gray.600"}>
                  Total users
                </Text>
                <Text fontSize="16px" fontWeight="500" color={"black"}>
                  {totalClients}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Gameporte;
