import { Button, Spinner } from '@chakra-ui/react';

const PrimaryButton2 = (props, { text, color, bg, func, loading, disabled }) => {
  return (
    <Button
      onClick={props.func}
      backgroundColor={bg}
      color={color}
      fontSize=".85rem"
      fontWeight={'500'}
      fontFamily={'Open Sans'}
      borderRadius={'12px'}
      padding="1.5rem 1.5rem"
      _hover={{ backgroundColor: '#101828' }}
      isDisabled={props.disabled}
      transition={'250ms ease'}
      _disabled={{ bg: 'lightgray', cursor: 'not-allowed' }}
      {...props}
    >
      {props.loading === true ? <Spinner /> : props.children}
    </Button>
  );
};

export default PrimaryButton2;