import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  IconButton,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { ChevronLeftIcon, EditIcon } from '@chakra-ui/icons';
import PrimaryButton from '../../../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../../../services/api';
import baseurl from '../../../../../../../config';

const EditScores = ({
  setEditMode,
  type,
  teams,
  setFinalScores,
  onOpen,
  ppk,
  pid,
  state,
  idx,
}) => {
  const [placementDatax, setPlacementDatax] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`${baseurl}/tournaments/placement-system/${pid}`)
      // .then(res => (console.log(res?.data?.data)))
      .then((res) => setPlacementDatax(res?.data?.data))
      .then((res) => setLoading(false))
      .catch((error) => console.log(error?.response));
  }, []);

  const placementData = [
    {
      placement: 1,
      points: placementDatax?.first,
    },
    {
      placement: 2,
      points: placementDatax?.second,
    },
    {
      placement: 3,
      points: placementDatax?.third,
    },
    {
      placement: 4,
      points: placementDatax?.fourth,
    },
    {
      placement: 5,
      points: placementDatax?.fifth,
    },
    {
      placement: 6,
      points: placementDatax?.sixth,
    },
    {
      placement: 7,
      points: placementDatax?.seventh,
    },
    {
      placement: 8,
      points: placementDatax?.eighth,
    },
    {
      placement: 9,
      points: placementDatax?.ninth,
    },
    {
      placement: 10,
      points: placementDatax?.tenth,
    },
    {
      placement: 11,
      points: 0,
    },
    {
      placement: 12,
      points: 0,
    },
    {
      placement: 13,
      points: 0,
    },
    {
      placement: 14,
      points: 0,
    },
    {
      placement: 15,
      points: 0,
    },
    {
      placement: 16,
      points: 0,
    },
    {
      placement: 17,
      points: 0,
    },
    {
      placement: 18,
      points: 0,
    },
    {
      placement: 19,
      points: 0,
    },
    {
      placement: 20,
      points: 0,
    },
    {
      placement: 21,
      points: 0,
    },
    {
      placement: 22,
      points: 0,
    },
    {
      placement: 23,
      points: 0,
    },
    {
      placement: 24,
      points: 0,
    },
    {
      placement: 25,
      points: 0,
    },
  ];

  const initialData = idx.map((team) => ({
    [type === 'TEAM' ? 'teamId' : 'username']:
      type === 'INDIVIDUAL' ? team?.username : team,
    kills: team?.kills,
    placement: team?.placement,
  }));

  const [data, setData] = useState(initialData);

  const handleInputChange = (event, index, field) => {
    const newData = [...data];
    newData[index][field] = event.target.value;
    setData(newData);
  };

  const handleSave = () => {
    const formattedData = data.map(({ kills, placement, ...rest }) => ({
      ...rest,
      kills,
      placement,
    }));
    console.log(formattedData);
    const finalData = formattedData?.map((i) => ({
      kills: i?.kills,
      placement: i?.placement,
      teamId: i?.teamId?.teamId,
    }));

    if (type === 'TEAM') {
      setFinalScores(finalData);
    } else {
      setFinalScores(formattedData);
    }

    onOpen();
  };

  const tags = state?.gamersTagMap;
  console.log(idx);

  return (
    <>
      {loading ? (
        <Flex justifyContent={'center'} mt="5rem">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex
            mb="1rem"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text fontWeight={'600'} fontSize={'1.1rem'} my="1rem">
              Edit Score
            </Text>
            <PrimaryButton
              onClick={() => setEditMode('')}
              width={180}
              bg="#244CC6"
              color="#FFF"
              height="40px"
              padding=".4rem .7rem"
              fontSize=".75rem"
              borderRadius="8px"
              text="Go Back"
            >
              <ChevronLeftIcon mr=".3rem" /> Go Back
            </PrimaryButton>
          </Flex>
          <Table
            colorScheme="grey"
            variant="simple"
            borderRadius={'8px 8px 0 0'}
          >
            <Thead>
              <Tr color="#0A0A0B" padding={'.8rem 1rem'} bg="#EAEAEB">
                <Th>{type === 'TEAM' ? 'Team' : 'Username'}</Th>
                <Th>Placement</Th>
                <Th whiteSpace={'nowrap'}>Placement Pts.</Th>
                <Th>Kills</Th>
                <Th whiteSpace={'nowrap'}>Kill Pts.</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                console.log(row);
                return (
                  <Tr key={index}>
                    <Td
                      border={'1px solid lightgray'}
                      padding={'.2rem 1rem'}
                      whiteSpace={'nowrap'}
                    >
                      {type === 'TEAM'
                        ? row.teamId?.teamName
                        : `${row.username} (${tags[row.username]})`}
                    </Td>
                    <Td border={'1px solid lightgray'} padding={'.2rem 1rem'}>
                      <Input
                        type="tel"
                        value={row.placement}
                        onChange={(e) =>
                          handleInputChange(e, index, 'placement')
                        }
                      />
                    </Td>
                    <Td border={'1px solid lightgray'} padding={'.2rem 1rem'}>
                      <Text>
                        {row.placement
                          ? placementData[row.placement - 1]?.points
                          : ''}
                      </Text>
                    </Td>
                    <Td border={'1px solid lightgray'} padding={'.2rem 1rem'}>
                      <Input
                        type="tel"
                        value={row.kills}
                        onChange={(e) => handleInputChange(e, index, 'kills')}
                      />
                    </Td>
                    <Td border={'1px solid lightgray'} padding={'.2rem 1rem'}>
                      <Text>{row.kills ? ppk * row.kills : ''}</Text>
                    </Td>
                    <Td border={'1px solid lightgray'} padding={'.2rem 1rem'}>
                      <Text>
                        {row?.kills && row.placement
                          ? ppk * row.kills +
                            placementData[row.placement - 1]?.points
                          : ''}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <PrimaryButton
            onClick={handleSave}
            width={'100%'}
            mt="1rem"
            bg="#244CC6"
            color="#FFF"
            height="45px"
            padding=".4rem .7rem"
            fontSize=".85rem"
            fontWeight="500"
            borderRadius="8px"
            text="UPDATE SCORES"
          >
            UPDATE SCORES
          </PrimaryButton>
        </>
      )}
    </>
  );
};

export default EditScores;