import React, { useState } from 'react'
import EmptyState from '../../../../../components/emptyState/emptyState';
import { Avatar, Box, Divider, Flex, Text, useToast } from '@chakra-ui/react';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../services/api';

const Disputes = ({match, matchScore, setRefetch}) => {
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useState("")
  const disputes = matchScore?.filter(i => i?.isScoreDisputed === true)

  const toast = useToast()

  const decideMatch = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax =  {
        matchOutcome: username === match?.host?.username ? "HOST_WIN" : "OPPONENT_WIN"
    }

    try {
        const {data} = await axiosInstance.put(`one-v-one/individual/${match?.id}/decide`, datax)
        toast({
        description: data?.message || "Match Decided",
        status: "success",
        position: 'top'
        });
        // setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0] || "System unavailable",
        status: "error",
        position: 'top'
        });
    } finally {
        setLoading(false)
    }
  }
  
  return (
    <>
    {disputes?.length < 1 ?
    <EmptyState text="No disputes raised for this match" />
    :
    <Flex gap="1rem">
        <Box flex="1" py="2rem">
            {disputes?.map(i => (
                <Box pb=".5rem">
                    <Text mb=".3rem" fontSize={".9rem"} fontWeight={"500"}>Round {i?.round}</Text>
                    <Flex pb=".5rem" key={i?.id} alignItems={"baseline"}>
                        <Box transition={"250ms ease"} zIndex={"10"} _hover={{transform: "scale(7) translateX(1.3rem)"}} borderRadius={"3px"} cursor={"pointer"} height={"40px"} width={"50px"} bg="lightgray" backgroundImage={i?.scoreImage} />
                        <Text mb=".3rem" fontSize={".9rem"} fontWeight={"500"}>{i?.disputeReason}</Text>
                    </Flex>
                    <Divider />
                </Box>
            ))}
        </Box>
        {match?.status !== "PLAYED" &&
        <Box flex="1" boxSizing='border-box' p={"1rem"} boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"} borderRadius={"6px"}>
            <Text fontWeight={"500"} fontSize={"1.15rem"}>Select and declare winner</Text>
            <Flex gap="2rem" width={"300px"} mx="auto" mt="2rem" justifyContent={"space-between"}>
                <Box transition={"250ms ease"} onClick={() => setUsername(match?.host?.username)} textAlign={"center"} p=".5rem 2rem" cursor="pointer" borderRadius={"8px"} border={username === match?.host?.username ? "2px solid #003C4E" : ""}>
                    <Avatar mb=".5rem" src={match?.host?.imageUrl} name={match?.host?.username} />
                    <Text fontWeight={"500"} textTransform={"capitalize"}>{match?.host?.username}</Text>
                </Box>
                <Box transition={"250ms ease"} onClick={() => setUsername(match?.opponent?.username)} textAlign={"center"} p=".5rem 2rem" cursor="pointer" borderRadius={"8px"} border={username === match?.opponent?.username ? "2px solid #003C4E" : ""}>
                    <Avatar mb=".5rem" src={match?.opponent?.imageUrl} name={match?.opponent?.username} />
                    <Text fontWeight={"500"} textTransform={"capitalize"}>{match?.opponent?.username}</Text>
                </Box>
            </Flex>
            <Flex justifyContent={"center"}>
                <PrimaryButton onClick={decideMatch} width="220px" mt="3rem" type="submit" text={`Declare ${username} Winner`} loading={loading} />
            </Flex>
        </Box>}
    </Flex>
    }
    </>
  )
}

export default Disputes;