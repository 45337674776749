import { Box, Image, Text } from '@chakra-ui/react';
import Empty from './empty.svg';

const Bracket = ({ state }) => {
  const data = state?.participantType === 'TEAM' ? state?.teams : state?.individuals;

  return (
    <Box pt=".5rem">
      {data?.length < 1 ? (
        <Image src={Empty} width="100%" />
      ) : (
        <Box overflowX="auto">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#EAEAEB' }}>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>NO</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>PLAYERS</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>KILLS</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>KILL POINTS</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>PLACEMENT</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>PLACEMENT POINTS</th>
                <th style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '700', fontSize: '0.7rem', color: '#0A0A0B' }}>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(({ name, username }, index) => {
                return (
                  <tr key={name || username} style={{ backgroundColor: '#FFF', borderBottom: '1px solid #EAEAEB' }}>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>{index + 1}</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B', textTransform: "capitalize" }}>{name || username}</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>-</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>-</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>-</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>-</td>
                    <td style={{ padding: '0.6rem 0.8rem', textAlign: 'center', fontWeight: '600', fontSize: '0.7rem', color: '#0A0A0B' }}>-</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default Bracket;
