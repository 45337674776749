import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Select,
    Spinner,
    Text,
    useToast,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import SecondaryButton from '../../../../../../components/secondaryButton/secondarybutton';
  import { LuImagePlus } from 'react-icons/lu';
  import { BiPlus } from 'react-icons/bi';
  import axiosInstance from '../../../../../../services/api';
  import baseurl from '../../../../../../config';
  import CustomToast from '../../../../../../components/customToast/customToast';
  
  const Details = ({
    setGameFormat,
    setGameMode,
    setPrize,
    handleContinue,
    setEntryType,
    setEntryFee,
    entryType,
    winnerTakeAll,
    setWinnerTakeAll,
    setFormula,
    image,
    setImage,
    loading,
    setPpk,
    gameId,
    setPlacementSystemId,
    placementSystemId,
  }) => {
    const [pSystems, setPsystems] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [imageSize, setImageSize] = useState(null);
    const toast = useToast();
  
    useEffect(() => {
      axiosInstance
        .get(`${baseurl}/tournaments/placement-system`)
        // .then(res => console.log(res?.data?.data))
        .then((res) => setPsystems(res?.data?.data))
        .then((res) => setLoading1(false))
        .catch((error) => console.log(error?.response));
    }, []);
  
    const [rewardPrices, setRewardPrices] = useState([
      { position: 'first', value: '' },
      { position: 'second', value: '' },
      { position: 'third', value: '' },
    ]);
  
    const getNextPosition = () => {
      const positionNumber = rewardPrices.length + 1;
      return getNumberWord(positionNumber);
    };
  
    const getNumberWord = (number) => {
      if (number === 1) return '"first"';
      if (number === 2) return '"second"';
      if (number === 3) return '"third"';
      if (number === 4) return '"fourth"';
      if (number === 5) return '"fifth"';
      if (number === 6) return '"sixth"';
      if (number === 7) return '"seventh"';
      if (number === 8) return '"eighth"';
      if (number === 9) return '"ninth"';
      if (number === 10) return '"tenth"';
      return `"${number}th"`;
    };
  
    const handleInputChange = (position, value) => {
      setRewardPrices((prevPrices) =>
        prevPrices.map((price) =>
          price.position === position ? { ...price, value } : price
        )
      );
    };
  
    const addMorePositions = () => {
      if (rewardPrices.length < 10) {
        setRewardPrices((prevPrices) => [
          ...prevPrices,
          { position: getNextPosition(), value: '' },
        ]);
      }
    };
  
    useEffect(() => {
      // Function to calculate file size and update state
      const calculateFileSize = (file, setState) => {
        const fileSize = file ? file.size / 1024 : 0; // in KB
        setState(fileSize);
      };
  
      calculateFileSize(image, setImageSize);
    }, [image]);
  
    const handleFileChange = (e, setImage, setImageSize, type) => {
      const file = e.target.files[0];
      if (file) {
        const fileSize = file.size / 1024; // Size in KB
        if (fileSize > 200) {
          toast({
            render: () => (
              <CustomToast
                type={'error'}
                message={'File size must be less than 200KB'}
              />
            ),
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          e.target.value = ''; // Reset the input
        } else {
          setImage(file);
          setImageSize(fileSize);
        }
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const formattedData = rewardPrices
        .filter((price) => price.position && price.value)
        .reduce((data, price, index) => {
          const positionKey = getNumberWord(index + 1);
          data[positionKey] = parseInt(price.value); // Values remain as numbers
          return data;
        }, {});
      setFormula(formattedData);
      // console.log(formattedData)
      handleContinue();
    };
  
    return (
      <Box width={['100%', '70%']} mx="auto">
        <FormControl mb="1.3rem">
          <InputGroup overflow={'hidden'}>
            <Input
              type="text"
              placeholder="Tournament cover image (936 x 270)"
              readOnly
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: 'none' }}
              fontSize={'.9rem'}
              height={'45px'}
              borderRadius={'14px'}
              _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
              value={image?.name}
            />
            <InputRightElement
              width={'120px'}
              cursor={'pointer'}
              children={
                <Box position={'relative'} cursor={'pointer'}>
                  <Input
                    onChange={(e) => handleFileChange(e, setImage, setImageSize)}
                    cursor={'pointer'}
                    type="file"
                    opacity={'0'}
                    transform={'translate(-1.2rem, .6rem)'}
                    position={'absolute'}
                    mt="-1rem"
                    ml="1rem"
                    height={'50px'}
                  />
                  <Flex
                    cursor={'pointer'}
                    bg="#FFF"
                    mt=".25rem"
                    borderRadius={'8px'}
                    ml={["60px", "-.4rem"]}
                    alignItems={'center'}
                    gap=".3rem"
                    padding={'.5rem .6rem'}
                    justifyContent={['flex-end', 'flex-start']}
                  >
                    <LuImagePlus ml={['auto', '0']} />
                    <Text 
                      cursor={'pointer'} 
                      fontSize={'.75rem'} 
                      display={['none', 'block']}
                    >
                      Upload Image
                    </Text>
                  </Flex>
                </Box>
              }
            />
          </InputGroup>
          <Text fontStyle={'italic'} fontSize=".8rem" fontWeight={'bold'}>
            Image should not be more than 200KB
          </Text>
        </FormControl>
        <FormControl mb="1.3rem">
          <FormLabel
            color="#0A0A0B"
            transform={'translateY(-2px)'}
            fontSize={'.9rem'}
          >
            Game Format
          </FormLabel>
          <Select
            type="text"
            bg="#EAEAEB"
            border="2px solid #CFD0D3"
            _focus={{ boxShadow: 'none' }}
            fontSize={'.9rem'}
            height={'45px'}
            borderRadius={'14px'}
            onChange={(e) => setGameFormat(e.target.value)}
          >
            <option selected disabled>
              Select Game Format
            </option>
            <option value="BEST OF 1">Best of 1</option>
            <option value="BEST OF 2">Best of 2</option>
            <option value="BEST OF 3">Best of 3</option>
            <option value="BEST OF 4">Best of 4</option>
            <option value="BEST OF 5">Best of 5</option>
            <option value="BEST OF 6">Best of 6</option>
            <option value="BEST OF 7">Best of 7</option>
          </Select>
        </FormControl>
        <FormControl mb="1.3rem">
          <FormLabel
            color="#0A0A0B"
            transform={'translateY(-2px)'}
            fontSize={'.9rem'}
          >
            Game Mode
          </FormLabel>
          <Select
            type="text"
            bg="#EAEAEB"
            border="2px solid #CFD0D3"
            _focus={{ boxShadow: 'none' }}
            fontSize={'.9rem'}
            height={'45px'}
            borderRadius={'14px'}
            onChange={(e) => setGameMode(e.target.value)}
          >
            <option selected disabled>
              Select Game Mode
            </option>
            <option value="SOLOS">SOLOS</option>
            <option value="DUOS">DUOS</option>
            <option value="TRIOS">TRIOS</option>
            <option value="SQUADS-4">SQUADS-4</option>
            <option value="SQUADS-5">SQUADS-5</option>
          </Select>
        </FormControl>
        {gameId
          ? gameId !== 1 && (
              <FormControl mb="1.2rem">
                <FormLabel
                  color="#0A0A0B"
                  transform={'translateY(-2px)'}
                  fontSize={'.9rem'}
                >
                  Points per kill
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Points per kill"
                  bg="#EAEAEB"
                  border="2px solid #CFD0D3"
                  _focus={{ boxShadow: 'none' }}
                  fontSize={'.9rem'}
                  height={'45px'}
                  borderRadius={'14px'}
                  _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
                  onChange={(e) => setPpk(parseInt(e.target.value))}
                />
              </FormControl>
            )
          : null}
  
        {gameId !== 1 && (
          <FormControl mb="1.3rem">
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <FormLabel
                color="#0A0A0B"
                transform={'translateY(-2px)'}
                fontSize={'.9rem'}
              >
                Placement system
              </FormLabel>
  
              {placementSystemId !== '' && (
                <Popover>
                  <PopoverTrigger>
                    <Text
                      cursor={'pointer'}
                      fontWeight={'500'}
                      color={'#244CC6'}
                      fontSize={'.85rem'}
                    >
                      View selected placement
                    </Text>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>
                      {
                        pSystems?.filter((i) => i.id === placementSystemId)[0]
                          ?.name
                      }
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Text>
                        1st:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.first
                        }
                      </Text>
                      <Text>
                        2nd:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.second
                        }
                      </Text>
                      <Text>
                        3rd:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.third
                        }
                      </Text>
                      <Text>
                        4th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.fourth
                        }
                      </Text>
                      <Text>
                        5th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.fifth
                        }
                      </Text>
                      <Text>
                        6th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.sixth
                        }
                      </Text>
                      <Text>
                        7th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.seventh
                        }
                      </Text>
                      <Text>
                        8th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.eighth
                        }
                      </Text>
                      <Text>
                        9th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.ninth
                        }
                      </Text>
                      <Text>
                        10th:{' '}
                        {
                          pSystems?.filter((i) => i.id === placementSystemId)[0]
                            ?.tenth
                        }
                      </Text>
                    </PopoverBody>
                    <PopoverFooter></PopoverFooter>
                  </PopoverContent>
                </Popover>
              )}
            </Flex>
  
            <Select
              type="text"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: 'none' }}
              fontSize={'.9rem'}
              height={'45px'}
              borderRadius={'14px'}
              onChange={(e) => setPlacementSystemId(parseInt(e.target.value))}
            >
              <option selected disabled>
                Select placement system
              </option>
              {pSystems?.map((i) => (
                <option key={i?.id} value={i?.id}>
                  {i?.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
  
        <FormControl mb="1.2rem">
          <FormLabel
            color="#0A0A0B"
            transform={'translateY(-2px)'}
            fontSize={'.9rem'}
          >
            Prize
          </FormLabel>
          <Input
            type="text"
            placeholder="Enter Prize Amount"
            bg="#EAEAEB"
            border="2px solid #CFD0D3"
            _focus={{ boxShadow: 'none' }}
            fontSize={'.9rem'}
            height={'45px'}
            borderRadius={'14px'}
            _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
            onChange={(e) => setPrize(parseInt(e.target.value))}
          />
        </FormControl>
        <Text fontWeight={'500'} color="#0A0A0B" fontSize={'.9rem'} mb=".5rem">
          Entry Type
        </Text>
        <Flex justifyContent={'space-between'} gap="1.5rem" mb="1.2rem">
          <Box
            cursor={'pointer'}
            onClick={() => setEntryType('FREE')}
            flex={'1 1 200px'}
            bg={entryType === 'FREE' ? "#F9F5FF" : '#EAEAEB'}
            transition={'250ms ease'}
            borderRadius={'12px'}
            border="1px solid #E9D7FE"
            p=".5rem 3rem"
            boxShadow="0px 2px 8px 0px #00000014"
          >
            <Text
              mt=".2rem"
              fontWeight={'500'}
              fontSize={'.85rem'}
              color={entryType !== 'FREE' ? '#000' : "#6941C6"}
              textAlign="center"
            >
              Free
            </Text>
          </Box>
          <Box
            cursor={'pointer'}
            onClick={() => setEntryType('PAID')}
            flex={'1 1 200px'}
            bg={entryType === 'PAID' ? "#F9F5FF" : '#EAEAEB'}
            transition={'250ms ease'}
            borderRadius={'12px'}
            border="1px solid #E9D7FE"
            p=".5rem 3rem"
            boxShadow="0px 2px 8px 0px #00000014"
          >
            <Text
              mt=".2rem"
              fontWeight={'500'}
              fontSize={'.85rem'}
              color={entryType !== 'PAID' ? '#000' : "#6941C6"}
              textAlign="center"
            >
              Paid
            </Text>
          </Box>
        </Flex>
        {entryType === 'PAID' && (
          <FormControl mb="1.2rem">
            <FormLabel
              color="#0A0A0B"
              transform={'translateY(-2px)'}
              fontSize={'.9rem'}
            >
              Entry Fee
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter Entry Fee"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: 'none' }}
              fontSize={'.9rem'}
              height={'45px'}
              borderRadius={'14px'}
              _placeholder={{ fontSize: '.85rem', color: '#4E5055' }}
              onChange={(e) => setEntryFee(parseInt(e.target.value))}
            />
          </FormControl>
        )}
  
        <FormControl mb="1.3rem">
          <FormLabel
            color="#0A0A0B"
            transform={'translateY(-2px)'}
            fontSize={'.9rem'}
          >
            Winner Takes All ?
          </FormLabel>
          <Select
            type="text"
            bg="#EAEAEB"
            border="2px solid #CFD0D3"
            _focus={{ boxShadow: 'none' }}
            fontSize={'.9rem'}
            height={'45px'}
            borderRadius={'14px'}
            onChange={(e) =>
              setWinnerTakeAll(e.target.value === 'true' ? true : false)
            }
          >
            <option selected disabled>
              Select
            </option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>
        </FormControl>
  
        {winnerTakeAll === false &&
          rewardPrices.map((price, index) => (
            <FormControl
              mb="1rem"
              key={index}
              className="mb-2"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <FormLabel color="#0A0A0B" fontSize={'.9rem'} whiteSpace={'nowrap'}>
                {`${
                  price.position.charAt(0).toUpperCase() + price.position.slice(1)
                } Place:`}
              </FormLabel>
  
              <Input
                type="tel"
                bg="#EAEAEB"
                border="2px solid #CFD0D3"
                _focus={{ boxShadow: 'none' }}
                fontSize={'.9rem'}
                height={'45px'}
                width={'650px'}
                borderRadius={'14px'}
                value={price.value}
                onChange={(e) =>
                  handleInputChange(price.position, e.target.value)
                }
                min="0"
              />
            </FormControl>
          ))}
        {winnerTakeAll === false && rewardPrices.length < 10 && (
          <Flex justifyContent={'flex-end'}>
            <button
              style={{ color: '#244CC6' }}
              type="button"
              onClick={addMorePositions}
            >
              <BiPlus
                style={{
                  display: 'inline',
                  transform: 'translateY(2px)',
                  color: '#244CC6',
                }}
              />
              Add More Positions
            </button>
          </Flex>
        )}
  
        <br />
        <br />
        <SecondaryButton
          onClick={handleSubmit}
          type="submit"
          bg="#244CC6"
          color="#FFF"
          width="100%"
          text="Next"
        >
          {loading ? <Spinner /> : 'Next'}
        </SecondaryButton>
      </Box>
    );
  };
  
  export default Details;