import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Metrics from "../../../../components/metrics/metrics";
import ChartView from "../chartView/chartView";

const Porteshop = () => {
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const totalRevenue = 100000;
  const productRevenue = [
    { name: "Product 1", value: 30000, color: "#FFD572" },
    { name: "Product 2", value: 25000, color: "#99FFA3" },
    { name: "Product 3", value: 20000, color: "#FD95D3" },
    { name: "Product 4", value: 15000, color: "#FE6C6C" },
    { name: "Product 5", value: 10000, color: "#7AD3FF" },
  ];

  const data = [
    { title: "Clients added", value: 0 },
    { title: "Supervisors", value: 0 },
    { title: "Promoters", value: 0 },
    { title: "Initiatives", value: "0" },
  ];

  const CircularProgressChart = ({
    data,
    total,
    size = 200,
    maxStrokeWidth = 130,
    gap = 0.5,
  }) => {
    const center = size / 2;
    const radius = (size - maxStrokeWidth) / 1;
    const circumference = 2 * Math.PI * radius;
    let accumulatedProgress = 0;

    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {data.map((item, index) => {
          const percentage = item.value / total;
          const strokeWidth = percentage * maxStrokeWidth;
          const gapAdjustment = gap / radius;
          const adjustedPercentage = percentage - gapAdjustment;
          const strokeDasharray = `${circumference * adjustedPercentage} ${
            circumference * (1 - adjustedPercentage)
          }`;
          const rotation = accumulatedProgress * 360;
          accumulatedProgress += percentage;

          return (
            <circle
              key={index}
              cx={center}
              cy={center}
              r={radius}
              fill="none"
              stroke={item.color}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={circumference * 0.25}
              strokeLinecap="butt"
              transform={`rotate(${rotation} ${center} ${center})`}
            />
          );
        })}
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="18"
          fontWeight="bold"
        >
          #420,000
        </text>
      </svg>
    );
  };

  return (
    <Box
      overflowX="auto"
      whiteSpace="nowrap"
      sx={{
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {currentUser?.role !== "Client" && <Metrics data={data} />}

      <Box mt={"44px"} mb={"20px"} fontWeight={"700"} fontSize={"24px"}>
        Sales and Revenue
      </Box>
      <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
        <ChartView />

        <Box
          width={{ base: "100%", md: "343px" }}
          height={{ base: "auto", md: "335px" }}
          bg="#FFF"
          border=".5px solid #8E8E9333"
          p={{ base: "20px", md: "30px" }}
          borderRadius="12px"
        >
          <Flex mb={{ base: "10px", md: "20px" }} alignItems="flex-start">
            <Text
              fontWeight="500"
              fontSize={{ base: "18px", md: "20px" }}
              color="#101321"
            >
              Revenue by category
            </Text>
          </Flex>

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems="center"
            mt="20px"
          >
            <CircularProgressChart data={productRevenue} total={totalRevenue} />

            <Flex
              direction="column"
              ml={{ base: "0", md: "20px" }}
              mt={{ base: "20px", md: "0" }}
            >
              {productRevenue.map((product, index) => (
                <Flex key={index} alignItems="center" mb="10px">
                  <Box
                    width="13px"
                    height="13px"
                    mr="8px"
                    backgroundColor={product.color}
                  />
                  <Text fontWeight="400" fontSize="14px" color="#909090">
                    {product.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Porteshop;
