import React, { useState } from "react";
import { Box, Flex, Text, Spacer } from "@chakra-ui/react";
import Notifications from "./notifications/notifications";
import Email from "./email/email";
import CustomTabs from "../../../components/customTabs/customTabs";
import SendNotification from "./notifications/sendNotification";
import SendEmail from "./email/sendEmail";
import CustomDrawer from "../../../components/customDrawer/customDrawer";
import SecondaryButton from "../../../components/secondaryButton/secondarybutton";

const EmailAndNotification = () => {
  const [triggerClose, setTriggerClose] = useState("");
  const [refetch, setRefetch] = useState("");
  const [activeTab, setActiveTab] = useState("Notifications"); 

  const data = [
    {
      title: "Notifications",
      render: <Notifications />,
    },
    {
      title: "Emails",
      render: <Email />,
    },
  ];

  // Determine the content, title, and button text based on the active tab
  const drawerTitle =
    activeTab === "Notifications" ? "Send new notification" : "Send new email";
  const buttonText =
    activeTab === "Notifications" ? "Send New Notification" : "Send New Email";
  const drawerContent =
    activeTab === "Notifications" ? (
      <SendNotification setTriggerClose={setTriggerClose} setRefetch={setRefetch} />
    ) : (
      <SendEmail setTriggerClose={setTriggerClose} setRefetch={setRefetch} /> // Use a SendEmail component if available
    );

  return (
    <Box>
      <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"}>
        <Flex alignItems="center">
          <Box>
            <Text fontWeight="700" fontSize="24px">
              Email & Notification
            </Text>
          </Box>
          <Spacer />
          <CustomDrawer
            position={"right"}
            title={drawerTitle}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton type="submit" text={buttonText} loading={false} />
            }
            content={drawerContent}
            titleStyle={{ fontWeight: 600, fontSize: "24px", color: "black" }}
          />
        </Flex>
        <Box mt="2rem" border="1px solid #E9EAEC" borderRadius="12px" p="1rem" bg="#FFF">
          <CustomTabs data={data} onChange={setActiveTab} />
        </Box>
      </Box>
    </Box>
  );
};

export default EmailAndNotification;
