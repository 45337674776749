import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/customInput";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import {
  Box,
  Text,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosInstance2 from "../../../../services/api2";
import ImageUploader from "../../../../components/imageUploader/imageUploader";
import CustomSelect from "../../../../components/customSelect/customSelect";

const CreateGiftCard = ({
  setTriggerClose,
  imageURL,
  name,
  setName,
  setImageURL,
  setRefetch,
  consoles,
  currentPhase,
  goToNextPhase,
}) => {
  const [loading, setLoading] = useState(false);
  
  const [checkedItems, setCheckedItems] = useState([]);
//   const [imageURL, setImageURL] = useState("");
  const [imageURL2, setImageURL2] = useState("");
  const [imageURL3, setImageURL3] = useState("");
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);
  const [regulations, setRegulations] = useState("");
  const [productType, setSelectedProductType] = useState("GIFT_CARD");

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setImageURL(images[0]);
  };
  // const handleImagesUpload2 = (images) => {
  //   setImageURL2(images[0])
  // };
  // const handleImagesUpload3 = (images) => {
  //   setImageURL3(images[0])
  // };

  // const handleCheckboxChange = (event) => {
  //   const value = event.target.value;
  //   const isChecked = event.target.checked;

  //   if (isChecked) {
  //     // Add item to the array if it doesn't already exist
  //     if (!checkedItems.includes(value)) {
  //       setCheckedItems([...checkedItems, parseInt(value.slice(0,1))]);
  //     }
  //   } else {
  //     // Remove item from the array if it exists
  //     const filteredItems = checkedItems.filter((item) => item !== parseInt(value.slice(0,1)));
  //     setCheckedItems(filteredItems);
  //   }
  // };

  const ProductTypeData = [
    // {
    //   title: 'Voucher',
    //   value: 'VOUCHER',
    // },
    // {
    //   title: 'Console',
    //   value: 'CONSOLE',
    // },
    // {
    //   title: 'Game',
    //   value: 'GAME',
    // },
    {
      title: "Gift Card",
      value: "GIFT_CARD",
    },
  ];

  const createGift = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      name,
      lightModeImageUrl: imageURL || "https://image.png",
      darkModeImageUrl: imageURL || "https://image.png",
      howToRedeem: regulations,
      productType: "GIFT_CARD",
    };

    try {
      const { data } = await axiosInstance2.post("games", datax);
      toast({
        description: data?.message || "Game Created",
        status: "success",
        position: "top",
      });
      //   setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
      goToNextPhase();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0] || "System unavailable",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    <Text
              fontSize={"16px"} fontWeight={"500"}
            >{`Gift card details (Step${currentPhase}/3)`}</Text>
      <form onSubmit={createGift}>
        <CustomInput
          type={"text"}
          required
          placeholder={"Gift Card Name"}
          onChange={(e) => setName(e.target.value)}
          mb=".8rem"
        />
        {/* <CustomSelect
          required
          mb="2rem"
          placeholder={"Select Product Type"}
          options={ProductTypeData}
          onChange={(e) => setSelectedProductType(e.target.value)}
        /> */}
          <FormLabel
              transform={"translateY(.5rem)"}
              opacity={".7"}
              fontSize={".9rem"}
            >
            Product Type
          </FormLabel>
         <CustomInput
          type={"text"}
          required
          value="GIFT_CARD"
          isReadOnly
          mb=".8rem"
          onChange={() => setSelectedProductType("GIFT_CARD")}
        />
        <FormControl mb=".8rem">
          <FormLabel
            transform={"translateY(.5rem)"}
            opacity={".7"}
            fontSize={".9rem"}
          >
            Gift Card Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {imageURL !== "" && (
              <Box
                backgroundColor={"lightgray"}
                backgroundImage={imageURL}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <ImageUploader
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingi}
              loading={loadingi}
              max={1}
            />
          </Flex>
        </FormControl>
        <FormLabel
          color="#0A0A0B"
          // transform={'translateY(-12px)'}
          fontSize={".9rem"}
        >
          How it Works{" "}
          {/* <span style={{ opacity: '.5', fontSize: '.8rem' }}>(Editable)</span> */}
        </FormLabel>
        <ReactQuill
          style={{ height: "133px", marginBottom: "2rem" }}
          // value={regulations}
          onChange={setRegulations}
        />
          <PrimaryButton
            mt="2rem"
            text="Create Gift Card"
            type="submit"
            loading={loading}
          />
      </form>
    </>
  );
};

export default CreateGiftCard;
