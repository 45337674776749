import React, { useState } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from '@chakra-ui/react';
import axiosInstance2 from '../../../../services/api2';
import ImageUploader from '../../../../components/imageUploader/imageUploader';

const CreatePrice = ({ setTriggerClose, setRefetch, data }) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [dollarPrice, setDollarPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);

  const toast = useToast();

  const setVoucherPrice = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      newNairaPrice: parseFloat(price), 
      newDollarPrice: parseFloat(dollarPrice),
      discountPercent: parseFloat(discountPercent),
      type: data?.type,
      inStock: data?.voucherPrice?.inStock ? true : false,
    };

    try {
      const { data } = await axiosInstance2.put('/gift-card/settings', datax);
      toast({
        description: data?.message || 'Price Created',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={setVoucherPrice}>
        <CustomInput
          type={'number'} 
          step="0.01"
          required
          placeholder={'Naira Price'}
          onChange={(e) => setPrice(e.target.value)}
          mb=".8rem"
        />
        <CustomInput
          type={'number'}
          step="0.01"
          required
          placeholder={'Dollar Price'}
          onChange={(e) => setDollarPrice(e.target.value)}
          mb=".8rem"
        />
        <CustomInput
          type={'number'}
          step="0.01"
          required
          placeholder={'Discount Percentage %'}
          onChange={(e) => setDiscountPercent(e.target.value)}
          mb=".8rem"
        />
        <PrimaryButton
          mt="2rem"
          text="Set Price"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default CreatePrice;
