import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import CustomInput from "../../../components/customInput/customInput";
import AuthBg from "../../../assets/bg-element.webp";
import FullLogo from "../../../assets/full-logo.svg";
import CustomSelect from "../../../components/customSelect/customSelect";
import axiosInstance2 from "../../../services/UnAuthApi";

const genders = [
  {
    title: "Male",
    value: "MALE",
  },
  {
    title: "Female",
    value: "FEMALE",
  },
];

const t = {type: "error", successText: "Available", errorText: "Taken"}

const Login = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("text");
  const [validatingEmail, setValidatingEmail] = useState(false);
  const [validatingUsername, setValidatingUsername] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [usernameIsValid, setUsernameIsValid] = useState(true);
  const [referrer, setReferrer] = useState('');
  const [isFilled, setIsFilled] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [usernameResolution, setUsernameResolution] = useState(null)
  const [emailResolution, setEmailResolution] = useState(null)
  const { state } = useLocation();

  const toast = useToast();

  const navigate = useNavigate();

  const handleFocus = () => {
    setInputType("date");
  };

  useEffect(() => {
      if (usernameIsValid && emailIsValid && password.length > 4 && cpassword === password && agreement) {
          setIsFilled(true);
      } else {
          setIsFilled(false);
      }
  }, [email, password, cpassword, username, agreement, usernameIsValid, emailIsValid]);


  const datax = {
    username,
    firstName: '',
    lastName: '',
    email,
    dateOfBirth: dob,
    password,
    confirmPassword: cpassword,
    maritalStatus: 'SINGLE',
    gender,
    phoneNumber: phoneNumber.charAt(0) === '0' ? '+234' + phoneNumber.slice(1) : '+234' + phoneNumber,
    referrer,
};

  const signUp = async (e) => {
    e.preventDefault();
    if(password === cpassword) {
      setLoading(true);
      try {
        const { data } = await axiosInstance2.post("users/gamer", datax);
        navigate("/verify", {state: {source: "signup", email: email}});
      } catch (error) {
        console.log(error?.response?.data?.message);
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      toast({
        title: "An Error Occurred",
        description: "Password Mismatch, Please check and try again.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value?.toLowerCase())
    if(e.target.value.length >= 3) {
      validateUsername(e.target.value)
    } else {
      setUsernameResolution(null)
    }
  }

  const validateUsername = async(val) => {
    setValidatingUsername(true)
    const datax = {
      username: val
    }
    try {
      const {data} = await axiosInstance2.post("auth/validate-username", datax)
      setUsernameResolution({
        type: data?.data?.existing === false ? "success" : "error",
        successText: "Available",
        errorText: "Taken"
      })
      setUsernameIsValid(!data?.data?.existing)
    } catch (error) {
      console.log(error?.response?.data?.errors[0])
      setUsernameResolution({
        type: "error",
        successText: "Available",
        errorText: "Validation Error"
      })
    } finally {
      setValidatingUsername(false)
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if(e.target.value.includes(".com")) {
      validateEmail(e.target.value)
    } else {
      setEmailResolution(null)
    }
  }

  const validateEmail = async(val) => {
    setValidatingEmail(true)
    const datax = {
      email: val
    }
    try {
      const {data} = await axiosInstance2.post("auth/validate-email", datax)
      setEmailResolution({
        type: data?.data?.existing === false ? "success" : "error",
        successText: "Available",
        errorText: "Taken"
      })
      setEmailIsValid(!data?.data?.existing)
    } catch (error) {
      console.log(error?.response?.data?.errors[0])
      setEmailResolution({
        type: "error",
        successText: "Available",
        errorText: "Validation Error"
      })
    } finally {
      setValidatingEmail(false)
    }
  }

  return (
    <Box
      width={"100vw"}
      overflow={"hidden"}
      backgroundImage={AuthBg}
      backgroundSize="cover"
      p="1rem"
    >
      <Box
        padding={["2rem .7rem 1.5rem .7rem", "2rem 2rem 1.5rem 2rem"]}
        maxWidth={"600px"}
        bg="#003C4E"
        mx="auto"
        mt="0"
        boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
        borderRadius={["16px", "40px"]}
        textAlign={"center"}
      >
        <Image src={FullLogo} width={["50%", "33%"]} mx="auto" />
        <Heading
          mt={6}
          fontWeight={"700"}
          fontSize={["1.35rem", "1.5rem"]}
          color="#FFF"
        >
          Create account
        </Heading>
        <Text mt={2} mb="1rem" color={"#CFD0D3"} fontSize={".9rem"}>
          Get started to play competitions and get rewarded.
        </Text>
        <form onSubmit={signUp}>
          <CustomInput
            placeholder={"Username"}
            type="text"
            value={username}
            minLength={3}
            onChange={handleUsernameChange}
            resolving={validatingUsername}
            resolvedData={usernameResolution}
          />
          <CustomInput
            placeholder={"Email Address"}
            type="email"
            value={email}
            onChange={handleEmailChange}
            resolving={validatingEmail}
            resolvedData={emailResolution}
          />
          <CustomInput
            placeholder={"Phone Number"}
            type="tel"
            minLength={11}
            maxLength={11}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Flex justifyContent={"space-between"} gap="1rem" width={"100%"}>
            <CustomInput
              placeholder={"Date of Birth"}
              type={inputType}
              value={dob}
              onFocus={handleFocus}
              onChange={(e) => setDob(e.target.value)}
            />
            <CustomSelect
              placeholder={"Gender"}
              options={genders}
              setSelection={setGender}
              selection={gender}
            />
          </Flex>
          <CustomInput
            placeholder={"Password"}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomInput
            placeholder={"Confirm Password"}
            type="password"
            value={cpassword}
            onChange={(e) => setcPassword(e.target.value)}
          />
          <Flex mb="2rem" mt="1rem" alignItems={"center"}>
            <Checkbox
            onChange={(e) => setAgreement(e.target.checked)}
            checked={agreement}
            color={"#CFD0D3"}
            >
              <span style={{ fontSize: ".85rem" }}>
                I agree to the{" "}
                <span style={{ color: "#5DDAFF", cursor: "pointer" }}>
                  Terms and condition{" "}
                </span>
                and{" "}
                <span style={{ color: "#5DDAFF", cursor: "pointer" }}>
                  Privacy policy
                </span>
              </span>
            </Checkbox>
          </Flex>
          
          <PrimaryButton
            type="submit"
            text="Create account"
            loading={loading}
            isDisabled={isFilled === true ? false : true}
          />
          <Text
            mt="1.2rem"
            mb=".3rem"
            textAlign={"center"}
            fontSize={".9rem"}
            color="#CFD0D3"
          >
            Already have an account?{" "}
            <span
              onClick={() =>
                navigate("/", { state: { userType: state?.userType } })
              }
              style={{
                fontWeight: "500",
                color: "#5DDAFF",
                cursor: "pointer",
                transition: "250ms ease",
              }}
            >
              Login
            </span>
          </Text>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
