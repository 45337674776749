import React, { useState } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import axiosInstance2 from '../../../../services/api2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditGame = ({ setTriggerClose, setRefetch, gameData }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(gameData?.name);
  const [imageURL, setImageURL] = useState(gameData?.lightModeImageUrl);
  const [regulations, setRegulations] = useState(gameData?.howToRedeem);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setImageURL(images[0]);
  };

  const editGame = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      name,
      lightModeImageUrl: imageURL,
      darkModeImageUrl: imageURL,
      howToRedeem: regulations,
    };

    try {
      const { data } = await axiosInstance2.put(
        `/games/${gameData?.ulid}/update`,
        datax
      );
      toast({
        description: data?.message || 'Game Updated',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <form onSubmit={editGame}>
        <CustomInput
          type={'text'}
          required
          placeholder={'Porteshop Game Name'}
          onChange={(e) => setName(e.target.value)}
          value={name}
          mb=".8rem"
        />
        <FormControl mb=".8rem">
          <FormLabel
            transform={'translateY(.5rem)'}
            opacity={'.7'}
            fontSize={'.9rem'}
          >
            Porteshop Game Image
          </FormLabel>
          <Flex mt="1rem" gap=".5rem">
            {imageURL !== '' && (
              <Box
                backgroundColor={'lightgray'}
                backgroundImage={imageURL}
                backgroundSize={'cover'}
                height={'100px'}
                width={'120px'}
                borderRadius={'4px'}
              />
            )}
            <ImageUploader
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingi}
              loading={loadingi}
              max={1}
            />
          </Flex>
        </FormControl>
        <FormLabel color="#0A0A0B" fontSize={'.9rem'}>
          How it Works{' '}
          <span style={{ opacity: '.5', fontSize: '.8rem' }}>(Editable)</span>
        </FormLabel>
        <ReactQuill
          style={{ height: '133px', marginBottom: '2rem' }}
          value={regulations}
          onChange={setRegulations}
        />
        <PrimaryButton
          mt="2rem"
          text="Edit Game"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default EditGame;
