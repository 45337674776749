import { Box, Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../services/api'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import AppLoader from '../../../../components/appLoader/appLoader'
import CustomSelect from '../../../../components/customSelect/customSelect'
import CustomInput from '../../../../components/customInput/customInput'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditGameMode = ({setTriggerClose, setRefetch, datax}) => {
  const [games, setGames] = useState([])
  const [game, setGame] = useState(datax?.gameId)
  const [name, setName] = useState(datax?.name)
  const [desc, setDesc] = useState(datax?.description)
  const [loading, setLoading] = useState(true)
  const [loadingx, setLoadingx] = useState(false)
  const [rules, setRules] = useState(datax?.matchRules)

  const toast = useToast()

  const getGames = async() => {
    try {
        const { data } = await axiosInstance.get(`/games?size=20`)
        setGames(data?.data?.content)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
    }

    useEffect(() => {
        getGames()
    }, [])

    const gamesList = games?.map(i => ({
        title: i?.game?.name,
        value: i?.game?.id
    }))

    const editMode = async(e) => {
        e.preventDefault()
        setLoadingx(true)
        const datay = {
          gameId: datax?.gameId,
          name,
          description: desc,
          matchRules: rules
        }
        try {
          const {data} = await axiosInstance.put(`one-v-one/modes/${datax?.id}`, datay)
          toast({
            description: "Game Mode updated" || data?.message,
            status: "success",
            position: "top"
          });
          setTriggerClose('close' + (Math.random()))
          setRefetch(Math.random())
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
          });
        } finally {
          setLoadingx(false)
        }
      }

  
  return (
    <>
    {loading ?
    <AppLoader />
    :
    <form onSubmit={editMode}>
        {/* <CustomSelect
          required
          mt="0"
          placeholder={"Select Game"}
          options={gamesList}
          onChange={e => setGame(e.target.value)}
        /> */}
        <CustomInput
          type={"text"}
          required
          placeholder={"Game Mode Name"}
          onChange={e => setName(e.target.value)}
          value={name}
        />
        <CustomInput
          type={"text"}
          required
          placeholder={"Description"}
          onChange={e => setDesc(e.target.value)}
          value={desc}
         />
         <Text mt="1rem" fontSize={".8rem"}>Match Rules</Text>
         <ReactQuill style={{height: "133px", marginTop: ".1rem"}} defaultValue={rules} theme="snow" value={rules} onChange={setRules} />
        <br /><br /><br /><br />
        <PrimaryButton type='submit' bg="#244CC6" color="#FFF" width="100%" text={loadingx ? <Spinner /> : "Update Game Mode"}>{loadingx ? <Spinner /> : "Update Game Mode"}</PrimaryButton>
    </form>}
    </>
  )
}

export default EditGameMode;