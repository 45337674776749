import { Avatar, Badge, Box, Td, Th, Tr, TableContainer, Thead, Tbody, Table, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Select, Skeleton, Spinner, Stack, Text, useDisclosure, useTab, useToast, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import OverComp from '../overviewComponent/overComp'
import { CiSearch } from 'react-icons/ci'
import ExportCSV from '../../../../utils/libs/exportCSV'
import { HiPaperAirplane } from 'react-icons/hi'
import { MdDelete } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { truncateText } from '../../../../utils/libs/helper'
import { LuSettings2 } from 'react-icons/lu'
import axiosInstance from '../../../../services/api'
import baseurl from '../../../../config'
import EmptyState from '../../../../components/emptyState/emptyState'
import PrimaryButton2 from '../../../../components/primaryButton/primaryButton2'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import { CheckCircleIcon, PlusSquareIcon, SmallCloseIcon } from '@chakra-ui/icons'
import CustomToast from "../../../../components/customToast/customToast"
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import OtpInput from 'react-otp-input'

   

    // const transactions = [
    //     {
    //         id: "1qiA25ZC6024rtruw33122",
    //         date: "Today",
    //         time: "05:23 PM",
    //         user: {
    //             image: "",
    //             firstName: "Emmanuel",
    //             lastName: "Jacob",
    //             email: "emmanuel@gmail.com"
    //         },
    //         amount: 100000,
    //         type: "Top-up",
    //         paymentMethod: "Transfer",
    //         status: "success",
    //     },
    //     {
    //         id: "1qiA25ZC6024rtruw33122",
    //         date: "Today",
    //         time: "05:23 PM",
    //         user: {
    //             image: "",
    //             firstName: "Emmanuel",
    //             lastName: "Jacob",
    //             email: "emmanuel@gmail.com"
    //         },
    //         amount: 100000,
    //         type: "Subscription",
    //         paymentMethod: "Card",
    //         status: "failed",
    //     },
    //     {
    //         id: "1qiA25ZC6024rtruw33122",
    //         date: "Today",
    //         time: "05:23 PM",
    //         user: {
    //             image: "",
    //             firstName: "Emmanuel",
    //             lastName: "Jacob",
    //             email: "emmanuel@gmail.com"
    //         },
    //         amount: 100000,
    //         type: "Transfer",
    //         paymentMethod: "Transfer",
    //         status: "success",
    //     },
    //     {
    //         id: "1qiA25ZC6024rtruw33122",
    //         date: "Today",
    //         time: "05:23 PM",
    //         user: {
    //             image: "",
    //             firstName: "Emmanuel",
    //             lastName: "Jacob",
    //             email: "emmanuel@gmail.com"
    //         },
    //         amount: 100000,
    //         type: "Tournament",
    //         paymentMethod: "Transfer",
    //         status: "success",
    //     },
    //     {
    //         id: "1qiA25ZC6024rtruw33122",
    //         date: "Today",
    //         time: "05:23 PM",
    //         user: {
    //             image: "",
    //             firstName: "Emmanuel",
    //             lastName: "Jacob",
    //             email: "emmanuel@gmail.com"
    //         },
    //         amount: 100000,
    //         type: "Top-up",
    //         paymentMethod: "Transfer",
    //         status: "success",
    //     }
    // ]

const currencies = ["ngn"]

const Payouts = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [tokenIssued, setTokenIssued] = useState(false)

    const [amount, setAmount] = useState("");
    const [currency, setCurrency] = useState("");
    const [desc, setDesc] = useState("");
    const [recipient, setRecipient] = useState("");
    const [loadingv, setLoadingv] = useState(false)
    const [accountType, setAccountType] = useState("")
    const [triggerClose, setTriggerClose] = useState("");

    const [parentAccounts, setParentAccounts] = useState([])

    const [searchVal, setSearchVal] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {isOtpOpen, onOtpOpen, onOtpClose} = useDisclosure()
    const [verifiedUser, setVerifiedUser] = useState(null)

    const [res, setRes] = useState(null);

    const [otp, setOtp] = useState("");

    const handleChange = (otp) => {
        setOtp(otp);
    };
    const toast = useToast()

    useEffect(() => {
        axiosInstance.get(`${baseurl}/wallet/parent-accounts`)
        // .then(res => (console.log(res?.data?.data)))
        .then(res => (setParentAccounts(res?.data?.data)))
        .then(res => (setLoading(false)))
        .catch(error => console.log(error?.response))
    }, [loadingx])

    useEffect(() => {
        axiosInstance.get(`${baseurl}/wallet/transaction/all?size=100`)
        // .then(res => (console.log(res?.data?.data?.content)))
        .then(res => (setItems(res?.data?.data?.content?.filter(i => i.type === "gameporte_to_customer"))))
        .then(res => (setLoading(false)))
        .catch(error => console.log(error?.response))
    }, [loadingx])

    const transData = [
        {
            title: "Total number of Payouts",
            val: !loading ? items?.length : null
        },
        {
            title: "Total Value of Payouts",
            val: !loading ? items?.length : null
        },
        {
            title: "Total number of payouts processed",
            val: !loading ? items?.length : null
        }
    ]

    const headers = [
        {
            key: "anchorTransactionId",
            name: "Transaction ID"
        },
        {
            key: "sender",
            name: "Sender"
        },
        {
            key: "receiver",
            name: "Receiver"
        },
        {
            key: "amount",
            name: "Amount"
        },
        {
            key: "type",
            name: "Type"
        },
        {
            key: "reference",
            name: "Reference"
        },
        {
            key: "transactionDateTime",
            name: "Date"
        },
        {
            key: "status",
            name: "Status"
        },
    ]

    const issuePayout = async(e) => {
        e.preventDefault()
        setLoadingx(true)

        const datax = {
            amount: parseInt(amount),
            currency,
            description: desc,
            beneficiaryUsername: recipient,
            token: otp,
            accountType
        }

        try {
            const {data} = await axiosInstance.post(`${baseurl}/wallet/transfer/b2c`, datax)
            toast({
                render: () => (
                <CustomToast type={"success"} message="Payout Issued" />
                ),
                duration: 5000,
                isClosable: true,
                position: "top"
            })
            setAmount("")
            setCurrency("")
            setDesc("")
            setRecipient("")
            onClose()
        } catch(error) {
            setRes({
                type: "error",
                message: error?.response?.data?.errors[0]
            })
        } finally {
            setLoadingx(false)
        }
    }

    const validateUser = async(val) => {
        setRecipient(val)
        if(val?.length >= 3) {
            setLoadingv(true)
            try {
                // const {data} = await axiosInstance.get(`users/search?username=${val}`)
                const {data} = await axiosInstance.get(`wallet/details/${val}`)
                setVerifiedUser(data?.data)
                setRes({
                    type: "success",
                    message: ""
                })
            } catch(error) {
                setRes({
                    type: "error",
                    message: error?.response?.data?.errors[0]
                })
            } finally {
                setLoadingv(false)
            }
        }
        
    }

    const initiatePayout = async(e) => {
      
        e.preventDefault()
        setLoadingx(true)
        const datax = {
            amount
        }
        try {
            const {data} = await axiosInstance.post(`wallet/transfer/payout-token`, datax)
            setTokenIssued(true)
            setRes({
                type: "success",
                message: ""
            })
        } catch(error) {
            setRes({
                type: "error",
                message: error?.response?.data?.errors[0]
            })
        } finally {
            setLoadingx(false)
        }
    }

    const handleModalClose = () => {
      setTokenIssued(false); 
  };

  const modalContent = (
    <Modal isOpen={tokenIssued} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader fontFamily="Open Sans" fontSize="1.1rem">
                Enter token sent to your email address
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box margin="0 auto 1rem auto" p=".5rem 1.5rem">
                    <Text transform="translateY(-1rem)" fontSize=".85rem">
                        Enter token sent to your email address
                    </Text>
                    <br />
                    <OtpInput
                        containerStyle={{
                            display: 'flex',
                            gap: '1rem',
                            margin: '0 auto',
                            maxWidth: '100%',
                            justifyContent: 'space-between',
                        }}
                        inputStyle={{
                            borderBottom: '3px solid #003C4E',
                            height: '64px',
                            width: '100%',
                            background: '#EAEAEB',
                            color: '#0A0A0B',
                            fontSize: '1.1rem',
                            fontWeight: '500',
                            borderRadius: '50%',
                        }}
                        focusStyle={{
                            boxShadow: 'none',
                            outline: 'none',
                        }}
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                    />
                    <br />
                    <Button onClick={issuePayout} bg="#003C4E" color="#FFF" width="100%">
                        {loadingx ? <Spinner /> : 'Confirm Payout'}
                    </Button>
                </Box>
            </ModalBody>
        </ModalContent>
    </Modal>
);


  return (
    <Box>
        <Flex justifyContent={"space-between"} alignItems={"center"} my="2rem">
            <Text color={"#9A9CA2"}>Wallet / <span style={{fontWeight: "600", color: "#244CC6"}}>Payouts</span></Text>
            <CustomDrawer
                position="right"
                title="Issue Payout"
                toggleElement={<SecondaryButton
                  type="submit"
                  text={"Issue Payout"}
                  loading={false}
                  icon='plus'
                />}
                content={
                    <>
                        {!tokenIssued ? (
                            <form onSubmit={initiatePayout}>
                                <Box>
                <FormControl mb="1.2rem">
                  <FormLabel
                    color="#0A0A0B"
                    transform={"translateY(-2px)"}
                    fontSize={".9rem"}
                  >
                    Enter Amount
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Amount"
                    bg="#EAEAEB"
                    border="2px solid #CFD0D3"
                    _focus={{ boxShadow: "none" }}
                    fontSize={".9rem"}
                    height={"45px"}
                    borderRadius={"14px"}
                    _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FormControl>
                <FormControl mb="1.2rem">
                  <FormLabel
                    color="#0A0A0B"
                    transform={"translateY(-2px)"}
                    fontSize={".9rem"}
                  >
                    Select source account
                  </FormLabel>
                  <Select
                    bg="#EAEAEB"
                    border="2px solid #CFD0D3"
                    _focus={{ boxShadow: "none" }}
                    fontSize={".9rem"}
                    height={"45px"}
                    borderRadius={"14px"}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option selected disabled>
                      Choose source account
                    </option>
                    {parentAccounts?.map((i, index) => (
                      <option key={index} value={i?.data?.attributes?.type?.toLowerCase()}>
                        ₦{`${i?.balance?.availableBalance?.toLocaleString()} - ${i?.data?.attributes?.type} - ${i?.data?.attributes?.accountName}(${i?.data?.attributes?.accountNumber})`}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mb="1.2rem">
                  <FormLabel
                    color="#0A0A0B"
                    transform={"translateY(-2px)"}
                    fontSize={".9rem"}
                  >
                    Select Currency
                  </FormLabel>
                  <Select
                    bg="#EAEAEB"
                    border="2px solid #CFD0D3"
                    _focus={{ boxShadow: "none" }}
                    fontSize={".9rem"}
                    height={"45px"}
                    borderRadius={"14px"}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option selected disabled>
                      Choose currency
                    </option>
                    {currencies?.map((i, index) => (
                      <option key={index} value={i}>
                        {i?.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mb="1.2rem">
                  <FormLabel
                    color="#0A0A0B"
                    transform={"translateY(-2px)"}
                    fontSize={".9rem"}
                  >
                    Enter Description
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Description"
                    bg="#EAEAEB"
                    border="2px solid #CFD0D3"
                    _focus={{ boxShadow: "none" }}
                    fontSize={".9rem"}
                    height={"45px"}
                    borderRadius={"14px"}
                    _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </FormControl>
                <FormControl mb="1rem">
                  <FormLabel
                    color="#0A0A0B"
                    transform={"translateY(-2px)"}
                    fontSize={".9rem"}
                  >
                    Enter Beneficiary Username
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Beneficiary Username"
                    bg="#EAEAEB"
                    border="2px solid #CFD0D3"
                    _focus={{ boxShadow: "none" }}
                    fontSize={".9rem"}
                    height={"45px"}
                    borderRadius={"14px"}
                    _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
                    value={recipient}
                    onChange={(e) => validateUser(e.target.value)}
                  />
                </FormControl>
              </Box>
              {loadingv && (
                <Flex justifyContent={"center"}>
                  <Spinner />
                </Flex>
              )}
              {verifiedUser !== null && recipient?.length >= 3 && (
                <Flex borderRadius={"6px"} p=".7rem" gap=".5rem" bg="#C4D2FF" mx="1.6rem">
                  {/* <Avatar src={verifiedUser?.imageUrl} /> */}
                  <Box>
                    <Text fontSize={".85rem"} textTransform={"capitalize"}>
                      {verifiedUser?.username}
                    </Text>
                    <Text fontSize={".85rem"}>
                      {verifiedUser?.virtualBankAccountNumber} ({verifiedUser?.virtualBankName})
                    </Text>
                    <Text fontSize={".85rem"}>{verifiedUser?.virtualBankAccountName}</Text>
                  </Box>
                </Flex>
              )}
              {res !== null && res?.type === "error" ? (
                <Box
                  width={"100%"}
                  mb="1.3rem"
                  boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.08)"}
                  border={"6px"}
                  bg={res?.type === "error" ? "#FFCECE" : "#C9FFE0"}
                  padding={".5rem 1rem"}
                >
                  <Flex alignItems={"center"} gap=".8rem">
                    {res?.type === "success" && <CheckCircleIcon color={"#0B6A33"} />}
                    {res?.type === "error" && <SmallCloseIcon color={"#A72F2F"} />}
                    <Text
                      fontFamily={"Open Sans"}
                      fontWeight={"500"}
                      fontSize={".8rem"}
                      color={res?.type === "error" ? "#A72F2F" : "#0B6A33"}
                    >
                      {res?.message}
                    </Text>
                  </Flex>
                </Box>
              ) : (
                <br />
              )}
              
                <PrimaryButton2
                  type="submit"
                  bg="#003C4E"
                  color="#FFF"
                  width="100%"
                  loading={loadingx}
                >
                  {loadingx ? <Spinner /> : "Issue Payout"}
                </PrimaryButton2>
              
                            </form>
                        ) : (
                            modalContent
                        )}
                    </>
                }
                isOpen={isOpen}
                onClose={onClose}
                setTriggerClose={setTriggerClose}
                // titleStyle={{ fontFamily: 'Open Sans', fontSize: '1.1rem' }}
            />
        </Flex>
        <OverComp overviewData={transData} />
            <Box bg="white" mt={8} p={6}>
          {/* Header Section */}
          <Flex 
            direction={{ base: "column", md: "row" }} 
            justify="space-between" 
            align={{ base: "flex-start", md: "center" }}
            gap={4}
          >
            <Text fontSize="xl" fontWeight="600">Payouts</Text>
            
            <Flex 
              width={{ base: "100%", md: "auto" }}
              direction={{ base: "column", md: "row" }}
              gap={2}
              align={{ base: "center", md: "center" }}
            >
              <FormControl width={{ base: "100%", md: "400px" }}>
                <InputGroup>
                  <InputLeftElement>
                    <CiSearch color="#6941C6" style={{ transform: "scale(1.4)" }} />
                  </InputLeftElement>
                  <Input
                    onChange={e => setSearchVal(e.target.value)}
                    placeholder="Search"
                    fontFamily="Open Sans"
                    bg="#EAEAEB"
                    fontSize=".85rem"
                    outline="none"
                    focusBorderColor="none"
                    _placeholder={{ fontSize: ".75rem", color: "#4E5055" }}
                    border="none"
                    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.08)"
                    borderRadius="20px"
                  />
                  <InputRightElement>
                    <Button 
                      type="submit" 
                      bg="white"
                      fontSize=".75rem"
                      height="32px"
                      borderRadius="21px"
                      color="#6941C6"
                    >
                      <HiPaperAirplane style={{ transform: "rotate(90deg)" }} />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Box maxWidth={"300px"}><ExportCSV data={items} headers={headers} />
              </Box>
            </Flex>
          </Flex>

          <Box 
            mt={6} 
            borderRadius="12px" 
            bg="#FAFAFA" 
            border="1px solid" 
            borderColor="gray.200" 
            p={4}
          >
            <TableContainer overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Transaction ID</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Date/Time</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Sender</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Receiver</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Amount</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Reference</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Status</Th>
                    <Th fontSize=".8rem" color="#0A0A0B" fontWeight="700">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <Tr>
                      <Td colSpan={8}>
                        <Stack p={4}>
                          {[...Array(10)].map((_, i) => (
                            <Skeleton key={i} height="25px" />
                          ))}
                        </Stack>
                      </Td>
                    </Tr>
                  ) : items?.length < 1 ? (
                    <Tr>
                      <Td colSpan={8}>
                        <EmptyState />
                      </Td>
                    </Tr>
                  ) : (
                    items
                      ?.filter(i => i.anchorTransactionId.includes(searchVal))
                      ?.map(({
                        id,
                        anchorTransactionId,
                        reference,
                        sender,
                        receiver,
                        transactionDateTime,
                        amount,
                        status
                      }) => (
                        <Tr 
                          key={id} 
                          bg="white" 
                          mb={3} 
                          boxShadow="0px 4px 16px rgba(0, 0, 0, 0.08)"
                        >
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            {truncateText(anchorTransactionId, 10)}
                          </Td>
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            {transactionDateTime?.slice(0,10)}
                          </Td>
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            {sender}
                          </Td>
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            {receiver}
                          </Td>
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            ₦{amount?.toLocaleString()}
                          </Td>
                          <Td fontSize=".8rem" color="#4E5055" fontWeight="500">
                            {reference?.slice(0,20)}...
                          </Td>
                          <Td>
                            <Badge 
                              fontSize=".7rem"
                              colorScheme={status === "COMPLETED" ? "green" : "red"}
                            >
                              {status}
                            </Badge>
                          </Td>
                          <Td>
                            <Box as={AiFillEye} 
                              transform="scale(1.7)" 
                              cursor="pointer" 
                              color="#2F80ED" 
                            />
                          </Td>
                        </Tr>
                      ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
    </Box>
  )
}

export default Payouts