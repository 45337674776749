import React, { useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import { Box, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import axiosInstance from '../../../../services/api'
import ImageUploader from '../../../../components/imageUploader/imageUploader'

const CreateChannel = ({setTriggerClose, setRefetch}) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [url, setUrl] = useState("");

  const toast = useToast();

  const createChannel = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax =  {
        name,
        url
    }

    try {
        const {data} = await axiosInstance.post('whatsapp/group', datax)
        toast({
        description: data?.message || "Channel Created",
        status: "success",
        position: 'top'
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0] || "System unavailable",
        status: "error",
        });
    } finally {
        setLoading(false)
    }
  }
  
  return (
    <>
        <form onSubmit={createChannel}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Channel Name"}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Channel Link"}
        onChange={e => setUrl(e.target.value)}
        mb=".8rem"
        />

        <PrimaryButton mt="2rem" text="Create Channel" type="submit" loading={loading} />
        </form>
    </>
  )
}

export default CreateChannel;