import { Box, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import Empty from './empty.svg'
import { useEffect, useState } from 'react'
import axiosInstance from '../../../../../../services/api'
import baseurl from '../../../../../../config'

const Results = ({ state, reTrigger }) => {
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axiosInstance.get(`${baseurl}/tournaments/${state?.id}/${state?.bracketType?.toLowerCase()}/${state?.participantType?.toLowerCase()}/result`)
      .then(res => setResults(res?.data?.data))
      .then(() => setLoading(false))
      .catch(error => console.log(error?.response))
  }, [reTrigger])

  return (
    <Box pt=".5rem">
      <Box>
        <Box overflowX="auto">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#EAEAEB' }}>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>NO</th>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>PLAYERS</th>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>KILLS</th>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>PLACEMENT POINTS</th>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>KILL POINTS</th>
                <th style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '700', fontSize: '0.8rem', color: '#0A0A0B' }}>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Stack padding="1rem">
                      <Skeleton height="25px" width="100%" />
                      <Skeleton height="25px" width="100%" />
                      <Skeleton height="25px" width="100%" />
                      <Skeleton height="25px" width="100%" />
                      <Skeleton height="25px" width="100%" />
                    </Stack>
                  </td>
                </tr>
              ) : results?.length < 1 ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: 'center' }}>
                    <Image mt="1.5rem" src={Empty} width="100%" />
                  </td>
                </tr>
              ) : (
                results?.map(({ teamName, totalKillPoints, totalPlacementPoints, totalKills, totalPoints, username }, index) => (
                  <tr key={teamName}>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray' }}>{index + 1}</td>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray', textTransform: 'capitalize' }}>
                      {teamName || username}
                    </td>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray' }}>{totalKills}</td>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray' }}>{totalPlacementPoints}</td>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray' }}>{totalKillPoints}</td>
                    <td style={{ padding: '0.6rem 1rem', textAlign: 'center', fontWeight: '600', fontSize: '0.8rem', color: '#0A0A0B', border: '1px solid lightgray' }}>{totalPoints}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  )
}

export default Results
