import { Box, Flex, Text } from "@chakra-ui/react";

const OverComp = ({ overviewData }) => {
  return (
    <Flex gap=".9rem" justify="space-between" wrap="wrap">
      {overviewData?.map(({ title, val }) => (
        <Box 
          key={title} 
          flex="1" 
          minWidth="200px" 
          textAlign="center" 
          padding=".8rem 1rem" 
          bg="#FFF" 
          border="1px solid #CFD0D3" 
          borderRadius="12px"
        >
          <Text fontSize=".9rem" fontWeight="500" color="#9A9CA2" mb="1rem">
            {title}
          </Text>
          <Text fontWeight="700" fontSize="1.2rem" color="#0A0A0B">
            {val}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export default OverComp;
