export default function LightenDarkenColor(col, amt) {
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    var b = ((num >> 8) & 0x00FF) + amt;
    var g = (num & 0x0000FF) + amt;
    var newColor = g | (b << 8) | (r << 16);
    return newColor.toString(16);
  }
  
export function truncateText(text, length) {
  let newText = text;
  if(text?.length > length) {
    newText = text?.slice(0,length)
    return newText + "..."
  }
  return newText;
}

export function convertToProperObject(data) {
  const formattedData = {};
  
  for (const key in data) {
      const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
      formattedData[unescapedKey] = data[key];
  }
  
  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, '');
}


export function buildResourceURL(baseURL, queryParams) {
  const queryString = queryParams
    .filter(({ value }) => value !== "" && value !== null && value !== undefined)
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const finalURL = queryString ? `${baseURL}?${queryString}` : baseURL;

  return finalURL;
}