import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Flex,
  Image,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { BiShareAlt } from 'react-icons/bi';
import { HiUsers } from 'react-icons/hi';
import Naira from '../../../../../../assets/naira.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiFillClockCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../../../../services/api';
import baseurl from '../../../../../../config';
// import { toast } from 'react-toastify';
import Note from './note.svg';
import Gold from './gold.svg';
import Silver from './silver.svg';
import Bronze from './bronze.svg';
import { convertToProperObject } from '../../../../../../utils/libs/helper';
import moment from 'moment';
import SecondaryButton from '../../../../../../components/secondaryButton/secondarybutton';

const Preview = ({ previewData, handleContinue, loading }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [games, setGames] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [consoles, setConsoles] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(true);
  const [tourney, setTourney] = useState(null);
  const [reTrigger, setReTrigger] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`${baseurl}/games?size=20`)
      // .then(res => (console.log(res?.data?.data)))
      .then((res) => setGames(res?.data?.data?.content))
      .then((res) => setLoading1(false))
      .catch((error) => console.log(error?.response));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`${baseurl}/console`)
      .then((res) => setConsoles(res?.data?.data?.content))
      .then((res) => setLoading2(false))
      .catch((error) => console.log(error?.response));
  }, []);

  const dat = convertToProperObject(previewData?.sharingFormula);

  const moreDetails = [
    {
      title: 'entry fee',
      val: previewData?.entryFee?.toLocaleString() || 0,
    },
    {
      title: 'prize pool',
      val: previewData?.prize?.toLocaleString(),
    },
    {
      title: `${previewData?.teamSize ? 'team size' : 'individuals'}`,
      val: previewData?.teamSize || 1,
    },
    {
      title: 'event start',
      val: moment(previewData?.startDateTime).calendar(),
    },
    {
      title: 'bracket size',
      val: previewData?.numberOfTeams || previewData?.numberOfIndividuals,
    },
    {
      title: 'bracket type',
      val: previewData?.bracketType,
    },
    {
      title: 'game format',
      val: previewData?.gameFormat,
    },
    {
      title: 'location',
      val: previewData?.location,
    },
  ];

  return (
    <Box maxW={'1000px'} mx="auto">
      <Box bg="#FFF" borderRadius={'10px'} padding={'1rem 2rem'}>
        <Text fontSize={'.8rem'} transform={'translateY(-2px)'} mt="1.5rem">
          Advised Image Dimension is (936 x 270).
        </Text>
        <Box
          borderRadius={'16px'}
          height={'270px'}
          backgroundImage={`url(${previewData?.imageUrl})`}  // Wrap the image URL with url(...)
          backgroundSize={'cover'}
          backgroundPosition={'center'}
        ></Box>
        <Text
          mt="1rem"
          fontSize={'1.1rem'}
          color={'#0A0A0B'}
          fontWeight={'600'}
        >
          {previewData?.title}-{previewData?.gameFormat}-(
          {previewData?.gameMode})
        </Text>
        <Flex mt="1rem" alignItems={'center'} justifyContent={'space-between'}>
          {/* {previewData?.participantType === "TEAM" ?
          <Flex alignItems={"center"}>
            {previewData?.teams?.slice(0,5).map((i, index) => (
              <Avatar border={"1px solid #000"} ml={index !== 0 ? "-1rem" : ""} key={i?.id} size='sm' src={i?.img} />
            ))}
            <Text ml=".5rem" fontWeight={"500"}>{previewData?.numberOfTeams}</Text>
          </Flex>
          :
          <Flex alignItems={"center"}>
            {previewData?.individuals?.slice(0,5).map((i, index) => (
              <Avatar border={"1px solid #000"} ml={index !== 0 ? "-1rem" : ""} key={i?.id} size='sm' src={i?.imageUrl} />
            ))}
            <Text ml=".5rem" fontWeight={"500"}>{previewData?.numberOfIndividuals}</Text>
          </Flex>
          } */}
          <Text></Text>
          {/* <Text color={"#4E5055"} fontSize={".95rem"}><HiUsers style={{display: "inline", transform: "translateY(2px)", marginRight: ".2rem"}} />{tourney?.individuals?.length}</Text> */}
          <Flex alignItems={'center'} gap=".2rem">
            <Image src={Naira} />
            <Text fontSize={'.95rem'} fontWeight={'700'} color={'#0A0A0B'}>
              {previewData?.prize?.toLocaleString()}{' '}
              <span
                style={{
                  color: '#4E5055',
                  fontWeight: '400',
                  fontSize: '.85rem',
                }}
              >
                Prize pool
              </span>
            </Text>
          </Flex>
        </Flex>
        
      </Box>
      <Box
          mt="1rem"
          padding={'1.2rem'}
          borderRadius={'12px'}
          border="2px solid #CFD0D3"
          boxShadow={'0px 4px 16px 0px #00000014'}
        >
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            {/* <Badge colorScheme={previewData?.active ? "green" : "yellow"} textTransform={"capitalize"} borderRadius={"6px"} padding={".3rem .5rem"}>{previewData?.active ? "Active" : "Pending"}</Badge> */}
            <Text></Text>
            <Text fontSize={'.8rem'} fontWeight={'500'} color={'#0A0A0B'}>
              Platforms:{' '}
              {!loading1
                ? consoles.find((i) => i.console.id === previewData?.consoleId)
                    ?.console?.name
                : ''}
            </Text>
          </Flex>
          <Text
            mt="1rem"
            fontSize={'1rem'}
            color={'#0A0A0B'}
            fontWeight={'600'}
          >
            {!loading2
              ? games.find((i) => i.game.id === previewData?.gameId)?.game?.name
              : ''}
          </Text>
          <Flex
            mt=".5rem"
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Text color={'#4E5055'} fontSize={'.9rem'} mb=".1rem">
                {previewData?.participantType === 'TEAM'
                  ? 'Teams'
                  : 'Individuals'}
              </Text>
              {previewData?.participantType === 'TEAM' ? (
                <Text
                  p=".2rem .4rem"
                  fontWeight={'500'}
                  borderRadius={'4px'}
                  bg="#EAEAEB"
                  color={'#4E5055'}
                  fontSize={'.9rem'}
                >
                  <HiUsers
                    style={{
                      display: 'inline',
                      transform: 'translateY(2px)',
                      marginRight: '.2rem',
                    }}
                  />
                  {previewData?.numberOfTeams}
                </Text>
              ) : (
                <Text
                  p=".2rem .4rem"
                  fontWeight={'500'}
                  borderRadius={'4px'}
                  bg="#EAEAEB"
                  color={'#4E5055'}
                  fontSize={'.9rem'}
                >
                  <HiUsers
                    style={{
                      display: 'inline',
                      transform: 'translateY(2px)',
                      marginRight: '.2rem',
                    }}
                  />
                  {previewData?.numberOfIndividuals}
                </Text>
              )}
            </Box>
            <Box>
              <Text color={'#4E5055'} fontSize={'.9rem'} mb=".1rem">
                {previewData?.startDateTime?.slice(0, 10)}
              </Text>
              <Text color={'#4E5055'} fontSize={'.9rem'} fontWeight={'500'}>
                <AiFillClockCircle
                  color="grey"
                  style={{
                    display: 'inline',
                    transform: 'translateY(2px)',
                    marginRight: '.4rem',
                  }}
                />
                {previewData?.startDateTime?.slice(11)}
              </Text>
            </Box>
            <Box>
              <Text color={'#4E5055'} fontSize={'.9rem'} mb=".1rem">
                Entry Fee
              </Text>
              {previewData?.entryType === 'FREE' ? (
                <Text fontSize={'.9rem'} fontWeight={'500'} color={'#0A0A0B'}>
                  FREE
                </Text>
              ) : (
                <Flex alignItems={'center'} gap=".2rem">
                  <Image src={Naira} />
                  <Text fontSize={'.9rem'} fontWeight={'500'} color={'#0A0A0B'}>
                    {previewData?.entryFee?.toLocaleString()}
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </Box>
      <Box>
        <Flex mt={"10px"} p={["5px", ".8rem"]} alignItems={'center'} borderRadius={"10px"} gap=".6rem" bg="#C4D2FF">
          <Image src={Note} transform={'scale(.99)'} />
          <Text fontSize={'.9rem'} color="#0A0A0B">
            <strong>Note: </strong>Tournament entry fees are paid by the team
            captain for each team.
          </Text>
        </Flex>
        <Grid
           templateColumns={['repeat(2, 1fr)']}
           gap="1rem"
           py="1rem"
           mt="1rem"
        >
          <Box
            flex={'1 0 200px'}
            p="1rem"
            borderRadius={'8px'}
            boxShadow="0px 4px 16px 0px #00000014"
          >
            <Flex justifyContent={'center'}>
              <Box>
                <Image src={Gold} />
                <Text fontSize={'.9rem'} mt=".5rem" color="#0A0A0B" ml="-.7rem">
                  <strong>1st Position</strong>
                </Text>
                <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                  <Image src={Naira} />
                  <Text fontSize={'.9rem'} fontWeight={'500'} color={'#0A0A0B'}>
                    {previewData?.winnerTakeAll
                      ? previewData?.prize?.toLocaleString()
                      : dat?.first?.toLocaleString()}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            flex={'1 0 200px'}
            p="1rem"
            borderRadius={'8px'}
            boxShadow="0px 4px 16px 0px #00000014"
          >
            <Flex justifyContent={'center'}>
              <Box>
                <Image src={Silver} />
                <Text fontSize={'.9rem'} mt=".5rem" color="#0A0A0B" ml="-.7rem">
                  <strong>2nd Position</strong>
                </Text>
                <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                  <Image src={Naira} />
                  <Text fontSize={'.9rem'} fontWeight={'500'} color={'#0A0A0B'}>
                    {!previewData?.winnerTakeAll
                      ? dat?.second?.toLocaleString()
                      : 0}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            flex={'1 0 200px'}
            p="1rem"
            borderRadius={'8px'}
            boxShadow="0px 4px 16px 0px #00000014"
          >
            <Flex justifyContent={'center'}>
              <Box>
                <Image src={Bronze} />
                <Text fontSize={'.9rem'} mt=".5rem" color="#0A0A0B" ml="-.7rem">
                  <strong>3rd Position</strong>
                </Text>
                <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                  <Image src={Naira} />
                  <Text fontSize={'.9rem'} fontWeight={'500'} color={'#0A0A0B'}>
                    {!previewData?.winnerTakeAll
                      ? dat?.third?.toLocaleString()
                      : 0}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
          {dat?.fourth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>4th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.fourth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.fifth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>5th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.fifth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.sixth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>6th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.sixth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.seventh && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>7th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.seventh?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.eighth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>8th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.eighth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.ninth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>9th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.ninth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
          {dat?.tenth && (
            <Box
              flex={'1 0 200px'}
              p="1rem"
              borderRadius={'8px'}
              boxShadow="0px 4px 16px 0px #00000014"
            >
              <Flex justifyContent={'center'}>
                <Box>
                  <Image src={Bronze} />
                  <Text
                    fontSize={'.9rem'}
                    mt=".5rem"
                    color="#0A0A0B"
                    ml="-.7rem"
                  >
                    <strong>10th Position</strong>
                  </Text>
                  <Flex alignItems={'center'} gap=".2rem" mt=".5rem" ml=".2rem">
                    <Image src={Naira} />
                    <Text
                      fontSize={'.9rem'}
                      fontWeight={'500'}
                      color={'#0A0A0B'}
                    >
                      {!previewData?.winnerTakeAll
                        ? dat?.tenth?.toLocaleString()
                        : 0}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
        </Grid>

        <Box as="span" flex="1" textAlign="left" py=".5rem">
          <Text fontWeight={'700'}>Tournament Details</Text>
        </Box>
        <Flex gap="1.5rem" flexWrap={'wrap'}>
          {moreDetails?.map(({ title, val }) => (
            <Box
              key={title}
              // boxShadow="0px 2px 8px 0px #00000014"
              flex={'1 1 400px'}
              border={"1px solid #CFD0D4"}
            >
              <Text
                fontSize={'12px'}
                fontWeight={"500"}
                color={"#28282880"}
                p=".5rem"
                bg="#CFD0D3"
                textTransform={'uppercase'}
              >
                {title}
              </Text>
              <Text fontSize={'14px'} fontWeight={"500"} p=".5rem">
                {val}
              </Text>
            </Box>
          ))}
        </Flex>

        <Flex justifyContent={'center'}>
          <SecondaryButton
            mt="2rem"
            onClick={handleContinue}
            type="submit"
            bg="#244CC6"
            color="#FFF"
            width="100%"
            text={loading ? <Spinner /> : 'Create Tournament'}
          />
            
        </Flex>
      </Box>
    </Box>
  );
};

export default Preview;