import React, { useState } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from '@chakra-ui/react';
import axiosInstance2 from '../../../../services/api2';
import ImageUploader from '../../../../components/imageUploader/imageUploader';

const CreatePrice = ({ setTriggerClose, setRefetch, data }) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [imageURL2, setImageURL2] = useState('');
  const [imageURL3, setImageURL3] = useState('');
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);

  const toast = useToast();

  const setVoucherPrice = async (e) => {
    e.preventDefault();
    setLoading(true);
    const datax = {
      newPrice: parseInt(price, 10),
      discountPercent: parseInt(discountPercent, 10),
      type: data?.type,
      inStock: data?.voucherPrice?.inStock ? true : false,
    };

    try {
      const { data } = await axiosInstance2.put(
        '/game-voucher/update-price',
        datax
      );
      toast({
        description: data?.message || 'Price Created',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: 'An Error Occurred',
        description: error?.response?.data?.errors[0] || 'System unavailable',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <form onSubmit={setVoucherPrice}>
        <CustomInput
          type={'int'}
          required
          placeholder={'Price'}
          onChange={(e) => setPrice(e.target.value)}
          mb=".8rem"
        />
        <CustomInput
          type={'Int'}
          required
          placeholder={'Discount Percentage %'}
          onChange={(e) => setDiscountPercent(e.target.value)}
          mb=".8rem"
        />
        <PrimaryButton
          mt="2rem"
          text="Set Price"
          type="submit"
          loading={loading}
        />
      </form>
    </>
  );
};

export default CreatePrice;
