import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { useState } from 'react';
import CustomTabs from '../../../components/customTabs/customTabs';
import Gamers from './gamers';
import CommunityManagers from '../communityMangers/communityManagers';
import CommunityReps from '../communityReps/communityReps';

const GamersAndCommunity = () => {
  const data = [
    {
      title: 'Gamers',
      render: <Gamers />,
    },
    {
      title: 'Community Manager',
      render: <CommunityManagers />,
    },
    {
      title: 'Community Rep',
      render: <CommunityReps />,
    },
  ];

  return (
    <Box>
      <CustomTabs data={data} />
    </Box>
  );
};

export default GamersAndCommunity;
