import React, { useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import { useToast } from '@chakra-ui/react'
import axiosInstance from '../../../../services/api'

const EditChannel = ({setTriggerClose, setRefetch, channelData}) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(channelData?.name)
  const [url, setUrl] = useState(channelData?.url)

  const toast = useToast();

  const editChannel = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax =  {
        name,
        url
    }

    try {
        const {data} = await axiosInstance.put(`whatsapp/group/${channelData?.id}`, datax)
        toast({
        description: data?.message || "Channel Updated",
        status: "success",
        position: 'top'
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0] || "System unavailable",
        status: "error",
        });
    } finally {
        setLoading(false)
    }
  }


  return (
    <>
        <form onSubmit={editChannel}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Channel Name"}
        onChange={e => setName(e.target.value)}
        value={name}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Channel Link"}
        onChange={e => setUrl(e.target.value)}
        value={url}
        mb=".8rem"
        />

        <PrimaryButton mt="2rem" text="Edit Channel" type="submit" loading={loading} />
        </form>
    </>
  )
}

export default EditChannel;