import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useState } from "react";
import AppData from "./components/appData";
import CustomTabs from "../../components/customTabs/customTabs";
import ChangePassword from "./components/changePassword";
import Admins from "./components/admins";
import Rates from "./components/rates";

const Settings = () => {

    const data = [
        {
            title: "App Data",
            render: <AppData />
        },
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        },
        {
            title: "Rates",
            render: <Rates />
        },
        // {
        //     title: "Access Management",
        //     render: <Admins />
        // },
    ]

    const data2 = [
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        }
    ]

    return (
        <Box>
            <CustomTabs data={data} />
        </Box>
    )
}

export default Settings;