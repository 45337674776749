import { Box, Flex, FormControl, FormLabel, Image, Input, Skeleton, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import baseurl from '../../../../../../config'
import axiosInstance from '../../../../../../services/api'
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton'
import SecondaryButton from '../../../../../../components/secondaryButton/secondarybutton'

const Basics = ({game: gamex, console: consolex, title, desc, date, setConsoleId, setGameId, setTitle, setDesc, setDate, handleContinue, channel, setChannel}) => {
    const [games, setGames] = useState([])
    const [loading1, setLoading1] = useState(true)
    const [consoles, setConsoles] = useState([])
    const [loading2, setLoading2] = useState(true)
    


    useEffect(() => {
        axiosInstance.get(`${baseurl}/games?size=20`)
        // .then(res => console.log(res?.data?.data?.content))
        .then(res => setGames(res?.data?.data?.content))
        .then(res => (setLoading1(false)))
        .catch(error => console.log(error?.response))
    }, [])

    useEffect(() => {
        axiosInstance.get(`${baseurl}/console`)
        .then(res => (setConsoles(res?.data?.data?.content)))
        .then(res => (setLoading2(false)))
        .catch(error => console.log(error?.response))
    }, [])

    const formatToISOString = (date) => {
        // if (date) {
          const dateObj = new Date(date);
          const formattedDate = dateObj.toISOString();
          return formattedDate;
        //   console.log(formattedDate);
        // }
      };

      const filteredArray2 = games.filter(item2 => {
        // Check if any console.id value in array2 matches any selected value.id from array1
        return consoles.some(item1 => item2.game.console.some(consoleItem => consoleItem.id === item1.console.id));
      });


  return (
    <Box width={{ base:"100%", md:"70%" }} mx="auto">
        <Text fontWeight={"500"} color={"#282828"} mb=".5rem">Console</Text>
        <Flex justifyContent={"space-between"} gap="1.5rem" overflowX="auto"  sx={{
    '&::-webkit-scrollbar': { display: 'none' }, 
    '-ms-overflow-style': 'none', 
    'scrollbar-width': 'none' 
  }}>
            {loading2 ?
            <>
            <Stack flex={"1 1 200px"} borderRadius={"12px"}>
              <Skeleton height={"60px"} />
            </Stack>
            <Stack flex={"1 1 200px"} borderRadius={"12px"}>
              <Skeleton height={"60px"} />
            </Stack>
            <Stack flex={"1 1 200px"} borderRadius={"12px"}>
              <Skeleton height={"60px"} />
            </Stack>
            </>
            :
            consoles?.map(({console}) => {
                return (
                  <Box
                  cursor="pointer"
                  onClick={() => setConsoleId(console?.id)}
                  flex={{ base: "0 0 auto", md: "1 1 200px" }} 
                  key={console?.id}
                  border={console?.id === consolex ? "4px solid #6941C6" : "none"}  
                  transition="250ms ease"
                  borderRadius="12px"
                  p=".2rem"
                  py={".5rem"}
                  boxShadow={{ base: "none", md: "0px 2px 8px 0px #00000014" }} 
                >
                  <Image
                    width={{ base: "49px", md: "40%" }} 
                    height={{ base: "49px", md: "auto" }} 
                    borderRadius={{ base: "8px", md: "0" }} 
                    mx="auto"
                    src={console?.darkModeImageUrl}
                  />
                  <Text
                    mt=".2rem"
                    fontWeight="500"
                    fontSize="11px"
                    color="#79747E"
                    textAlign="center"
                  >
                    {console?.name}
                  </Text>
                </Box>                
                )
            })}
        </Flex>
        <Text fontWeight={"500"} color={"#282828"} mb=".5rem" mt="1rem">Game</Text>
        <Flex
              justifyContent="space-between"
              gap=".1rem"
              overflowX="auto"
              // whiteSpace="nowrap"
              sx={{
                "&::-webkit-scrollbar": { display: "none" },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              {loading1 ? (
                <>
                  <Stack flex="1 1 200px" borderRadius="12px">
                    <Skeleton height="60px" />
                  </Stack>
                  <Stack flex="1 1 200px" borderRadius="12px">
                    <Skeleton height="60px" />
                  </Stack>
                  <Stack flex="1 1 200px" borderRadius="12px">
                    <Skeleton height="60px" />
                  </Stack>
                  <Stack flex="1 1 200px" borderRadius="12px">
                    <Skeleton height="60px" />
                  </Stack>
                </>
              ) : (
                games?.map(({ game }) => {
                  return (
                    <Box
                      key={game?.id}
                      cursor="pointer"
                      boxSizing="border-box"
                      p=".3rem"
                      borderRadius="12px"
                      onClick={() => setGameId(game?.id)}
                      border={game?.id === gamex ? "4px solid #6941C6" : "none"}
                      textAlign="center" 
                      whiteSpace="nowrap"
                    >
                      <Box
                      width="120px"
                      height="180px">
                      <Image
                        src={game?.lightModeImageUrl}
                        width="100%" 
                        height="100%"
                        objectFit="cover"
                        borderRadius="4px"
                        mx="auto" 
                      />
                      </Box>
                      <Text
                        mt=".2rem"
                        fontWeight="500"
                        fontSize=".85rem"
                        color="#000"
                        wordWrap="break-word"
                        
                      >
                        {game?.name}
                      </Text>
                    </Box>
                  );
                })
              )}
        </Flex>

        <br />
        <FormControl mb=".7rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Tournament Title
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter title"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            </FormControl>
          <FormControl mb=".7rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Description
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter description"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </FormControl>
          <FormControl mb="1.2rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Start Date & Time
            </FormLabel>
            <Input
              type="datetime-local"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              min={new Date().toISOString().split("T")[0]}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            </FormControl>
            <FormControl mb=".7rem">
            <FormLabel
              color="#0A0A0B"
              transform={"translateY(-2px)"}
              fontSize={".9rem"}
            >
              Tournament Channel
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter channel link"
              bg="#EAEAEB"
              border="2px solid #CFD0D3"
              _focus={{ boxShadow: "none" }}
              fontSize={".9rem"}
              height={"45px"}
              borderRadius={"14px"}
              _placeholder={{ fontSize: ".85rem", color: "#4E5055" }}
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
            />
          </FormControl>
            <SecondaryButton mt="1rem" onClick={handleContinue} type="submit" bg="#F9F5FF" color="#6941C6" width="100%"
                text={"Next"}/>
    </Box>
  )
}

export default Basics