import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Checkbox,
  Button,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import axiosInstance2 from '../../../../services/api2';
import CustomSelect from '../../../../components/customSelect/customSelect';
import UploadIcon from './upload.svg';

const UploadGiftCard2 = ({ setTriggerClose, currentPhase, giftCardEntries, setGiftCardEntries, imageURL, name,
  setCurrentPhase,
  handleNext,
  handlePrevious, max, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingi2, setLoadingi2] = useState(false);
  const [loadingi3, setLoadingi3] = useState(false);
  const [games, setGames] = useState([]);
  const [totalGames, setTotalGames] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileUploadType, setSelectedFileUploadType] = useState('');

  const toast = useToast();

  const getGames = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance2.get('/games');
      const matchedGame = data?.data?.content.find(game => game.name === name);
      
      if (matchedGame) {
        setSelectedGame(matchedGame.ulid); 
      }
      
      setGames(data?.data?.content);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, [name]);

  const gameData = games?.map((game) => ({
    title: game.name,
    value: game.ulid,
  }));

  useEffect(() => {
    console.log('file', selectedFile);
  }, [selectedFile]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (selectedFile.length === 0) {
      toast({
        description: 'No file selected',
        status: 'error',
        position: 'top',
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      console.log('fire3', formData);
      const response = await axiosInstance2.post(
        `/gift-card/upload/${selectedGame}?fileType=${selectedFileUploadType}`,
        formData
      );
      toast({
        description: response?.data?.message || 'Card Uploaded Successfully',
        status: 'success',
        position: 'top',
      });
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      toast({
        description: `Error uploading file: ${error.response.data.errors[0]}`,
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };
  const fileTypeData = [
    {
      title: 'PlayStation',
      value: 'PLAYSTATION',
    },
    {
      title: 'Unknown',
      value: 'UNKNOWN',
    },
  ];

  useEffect(() => {
    console.log('Persistent Entries Check:', giftCardEntries);
  }, [giftCardEntries]);

  return (
    <>
        {/* <Box mt={3} mb="0.5rem">
        <Text
          fontSize={"16px"} fontWeight={"500"}
        >{`Upload gift card (Step2/3)`}</Text>
        </Box> */}

        <Box mb={"1.0rem"}>
      <Text fontSize={"16px"} fontWeight={"500"}>
        Gift card types created
      </Text>
        {giftCardEntries && giftCardEntries.length > 0 ? (
          giftCardEntries.map((entry, index) => (  
            <Text key={index} fontSize="16px" mt="0.5rem">
              {entry.name}
            </Text>
          ))
        ) : (
          <Text fontSize="16px" color="gray.500">
            No gift cards added yet.
          </Text>
        )}
      </Box>

      <form onSubmit={uploadFile}>
      {/* <CustomInput
        type={"text"}
        required
        value={name}
        isReadOnly
        mb=".8rem"
      /> */}
        <CustomSelect
          required
          mb="2rem"
          placeholder={'Select Gift Card'}
          options={gameData}
          onChange={(e) => setSelectedGame(e.target.value)}
        />

        <CustomSelect
          required
          mb="2rem"
          placeholder={'Select File Type'}
          options={fileTypeData}
          onChange={(e) => setSelectedFileUploadType(e.target.value)}
        />
        <Flex
          position={'relative'}
          height={'100px'}
          alignItems={'center'}
          borderRadius={'8px'}
          width={'100%'}
          border="1px dashed #D0D5DD"
          cursor={'pointer'}
          boxSizing="border-box"
          padding={'1rem'}
        >
          <Image src={UploadIcon} />
          <>
            {loading ? (
              <Text
                ml="1rem"
                color={'#344054'}
                fontSize={'.9rem'}
                whiteSpace={'nowrap'}
              >
                Processing {max > 1 ? 'files' : 'file'}{' '}
                <Spinner
                  transform={'translateY(10px)'}
                  ml=".3rem"
                  size={'xs'}
                />
              </Text>
            ) : (
              <Text
                ml="1rem"
                color={'#344054'}
                fontSize={'.9rem'}
                whiteSpace={'nowrap'}
              >
                Upload {max > 1 ? 'Card' : 'Card'} here{' '}
                {max < 10 && `(max ${max})`}
              </Text>
            )}
            {!loading === true && (
              <>
                <Input
                  style={{
                    height: '100%',
                    cursor: 'pointer',
                    opacity: '0',
                    position: 'absolute',
                    left: '0',
                  }}
                  type="file"
                  multiple
                  maxLength={2}
                  onChange={handleFileChange}
                />
              </>
            )}
          </>
        </Flex>
        {selectedFile && (
          <Text color={'#344054'} fontSize={'.9rem'} whiteSpace={'nowrap'}>
            {selectedFile?.name}{' '}
          </Text>
        )}
        <Flex>
        <Button
            onClick={handlePrevious}
            mt="2rem"
            mr={4}
            width={"100%"}
            fontSize=".9rem"
            fontWeight={"600"}
            fontFamily={"Inter"}
            borderRadius={"12px"}
            padding="1.87rem 1.5rem"
            transition={"250ms ease"}
            border={"1px solid"}
            backgroundColor="white"
            borderColor="#003C4E"
          >
            Go back
          </Button>
        <PrimaryButton
          mt="2rem"
          text="Upload Gift Card"
          type="submit"
          loading={loading}
          isDisabled={selectedGame === '' || selectedFile.length < 1}
        />
        </Flex>
      </form>
    </>
  );
};

export default UploadGiftCard2;
