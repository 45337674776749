import {
    CheckCircleIcon,
    InfoOutlineIcon,
    SmallCloseIcon,
  } from "@chakra-ui/icons";
  import { Box, Flex, Text } from "@chakra-ui/react";
  
  const CustomToast = ({ type, message }) => {
    return (
      <Box
        maxW="400px"
        boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.08)"}
        border={"6px"}
        bg={
          type === "error" ? "#FFCECE" : type === "info" ? "#FFF1C6" : "#C9FFE0"
        }
        padding={".5rem 1rem"}
      >
        <Flex alignItems={"center"} gap=".8rem">
          {type === "success" && <CheckCircleIcon color={"#0B6A33"} />}
          {type === "error" && <SmallCloseIcon color={"#A72F2F"} />}
          {type === "info" && <InfoOutlineIcon color={"#9E7D19"} />}
          <Text
            fontFamily={"Open Sans"}
            fontWeight={"500"}
            color={
              type === "error"
                ? "#A72F2F"
                : type === "info"
                ? "#9E7D19"
                : "#0B6A33"
            }
          >
           {message}
          </Text>
        </Flex>
      </Box>
    );
  };
  
  export default CustomToast;