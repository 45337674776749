import { Box, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../services/api'
import CustomSelect from '../../../../components/customSelect/customSelect'
import CustomInput from '../../../../components/customInput/customInput'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import SpinLoader from '../../../../components/spinLoader/spinLoader'
import ReactQuill from 'react-quill'

const AddGameMode = ({setTriggerClose}) => {
  const [games, setGames] = useState([])
  const [game, setGame] = useState("")
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [loading, setLoading] = useState(true)
  const [loadingx, setLoadingx] = useState(false)
  const [rules, setRules] = useState("")

  const toast = useToast()

  const getGames = async() => {
    try {
        const { data } = await axiosInstance.get(`/games?size=20`)
        setGames(data?.data?.content)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
    }

    useEffect(() => {
        getGames()
    }, [])

    const gamesList = games?.map(i => ({
        title: i?.game?.name,
        value: i?.game?.id
    }))

    const createMode = async(e) => {
        e.preventDefault()
        setLoading(true)
        const datax = {
          gameId: game,
          modes: [
            {
                name,
                description: desc,
                matchRules: rules
            }
          ]
        }
        try {
          const {data} = await axiosInstance.post('one-v-one/modes', datax)
          toast({
            description: "Game Mode created" || data?.message,
            status: "success",
            position: "top"
          });
          setTriggerClose('close' + (Math.random()))
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
          });
        } finally {
          setLoading(false)
        }
      }

  
  return (
    <>
    {loading ?
    <SpinLoader />
    :
    <form onSubmit={createMode}>
        <CustomSelect
          required
          mt="0"
          placeholder={"Select Game"}
          options={gamesList}
          onChange={e => setGame(e.target.value)}
        />
        <CustomInput
          type={"text"}
          required
          placeholder={"Game Mode Name"}
          onChange={e => setName(e.target.value)}
         />
        <CustomInput
          type={"text"}
          required
          placeholder={"Description"}
          onChange={e => setDesc(e.target.value)}
         />

      <Text mt="1rem" fontSize={".8rem"}>Match Rules</Text>
         <ReactQuill style={{height: "133px", marginTop: ".1rem"}} theme="snow" value={rules} onChange={setRules} />
        <br /><br /><br /><br />
         <PrimaryButton mt="3rem" type="submit" text={`Add Game Mode`} loading={loadingx} />
    </form>}
    </>
  )
}

export default AddGameMode