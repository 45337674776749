import {
  Avatar,
  Badge,
  Box,
  Td,
  Tr,
  Th,
  Tbody,
  Thead,
  Table,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OverComp from "../overviewComponent/overComp";
import { CiSearch } from "react-icons/ci";
import ExportCSV from "../../../../utils/libs/exportCSV";
import { HiPaperAirplane } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { truncateText } from "../../../../utils/libs/helper";
import { LuSettings2 } from "react-icons/lu";
import axiosInstance from "../../../../services/api";
import baseurl from "../../../../config";
import moment from "moment";
import EmptyState from "../../../../components/emptyState/emptyState";
import DateRangeSelector from "../../home/dateRangeSelector/dateRangeSelector";

// const transactions = [
//     {
//         id: "1qiA25ZC6024rtruw33122",
//         date: "Today",
//         time: "05:23 PM",
//         user: {
//             image: "",
//             firstName: "Emmanuel",
//             lastName: "Jacob",
//             email: "emmanuel@gmail.com"
//         },
//         amount: 100000,
//         type: "Top-up",
//         paymentMethod: "Transfer",
//         status: "success",
//     },
//     {
//         id: "1qiA25ZC6024rtruw33122",
//         date: "Today",
//         time: "05:23 PM",
//         user: {
//             image: "",
//             firstName: "Emmanuel",
//             lastName: "Jacob",
//             email: "emmanuel@gmail.com"
//         },
//         amount: 100000,
//         type: "Subscription",
//         paymentMethod: "Card",
//         status: "failed",
//     },
//     {
//         id: "1qiA25ZC6024rtruw33122",
//         date: "Today",
//         time: "05:23 PM",
//         user: {
//             image: "",
//             firstName: "Emmanuel",
//             lastName: "Jacob",
//             email: "emmanuel@gmail.com"
//         },
//         amount: 100000,
//         type: "Transfer",
//         paymentMethod: "Transfer",
//         status: "success",
//     },
//     {
//         id: "1qiA25ZC6024rtruw33122",
//         date: "Today",
//         time: "05:23 PM",
//         user: {
//             image: "",
//             firstName: "Emmanuel",
//             lastName: "Jacob",
//             email: "emmanuel@gmail.com"
//         },
//         amount: 100000,
//         type: "Tournament",
//         paymentMethod: "Transfer",
//         status: "success",
//     },
//     {
//         id: "1qiA25ZC6024rtruw33122",
//         date: "Today",
//         time: "05:23 PM",
//         user: {
//             image: "",
//             firstName: "Emmanuel",
//             lastName: "Jacob",
//             email: "emmanuel@gmail.com"
//         },
//         amount: 100000,
//         type: "Top-up",
//         paymentMethod: "Transfer",
//         status: "success",
//     }
// ]

const Transactions = () => {
  const [items, setItems] = useState([]);
  const [overview, setOverview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingx, setLoadingx] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchVal, setSearchVal] = useState("");

  const handleDateRangeChange = (start, end) => {
    const now = moment().endOf("day");
    let calculatedStartDate;
    let calculatedEndDate = now;

    if (!start.includes("T")) {
      switch (start) {
        case "1 day":
          calculatedStartDate = moment().startOf("day");
          break;
        case "7 days":
          calculatedStartDate = moment().subtract(6, "days").startOf("day");
          break;
        case "1 month":
          calculatedStartDate = moment().subtract(29, "days").startOf("day");
          break;
        default:
          calculatedStartDate = moment(start);
          calculatedEndDate = moment(end);
      }
    } else {
      calculatedStartDate = moment(start).startOf("day");
      calculatedEndDate = moment(end).endOf("day");
    }

    setStartDate(calculatedStartDate.format());
    setEndDate(calculatedEndDate.format());
    setLoading(true);
  };

  useEffect(() => {
    fetchOverviewData();
  }, [loadingx]);

  useEffect(() => {
    if (!loading) return;
    fetchTransactions();
  }, [loading, startDate, endDate]);

  const fetchOverviewData = () => {
    axiosInstance
      .get(`${baseurl}/wallet/transaction/overview`)
      .then((res) => setOverview(res?.data?.data))
      .then((res) => setLoadingx(false))
      .catch((error) => console.log(error?.response));
  };

  const fetchTransactions = () => {
    let apiUrl = `${baseurl}/wallet/transaction/all?size=200`;

    axiosInstance
      .get(apiUrl)
      .then((res) => {
        const filteredItems = res?.data?.data?.content.filter((item) => {
          if (!startDate) return true;

          const transactionDate = moment(item.transactionDateTime);
          const start = moment(startDate);
          const end = moment(endDate);

          return transactionDate.isBetween(start, end, "day", "[]");
        });
        setItems(filteredItems);
      })
      .finally(() => setLoading(false))
      .catch((error) => console.error(error?.response));
  };

  const transData = [
    {
      title: "Total Transactions",
      val: !loadingx ? overview?.totalTransfers : null,
    },
    {
      title: "Total Top-ups",
      val: !loadingx ? overview?.totalTopUps : null,
    },
    {
      title: "Total Transfers",
      val: !loadingx ? overview?.totalTransfers : null,
    },
    {
      title: "Failed Transactions",
      val: !loadingx ? overview?.totalFailedTransfers : null,
    },
    {
      title: "Successful Transactions",
      val: !loadingx ? overview?.totalSuccessfulTransfers : null,
    },
  ];

  const headers = [
    {
      key: "anchorTransactionId",
      name: "Transaction ID",
    },
    {
      key: "sender",
      name: "Sender",
    },
    {
      key: "receiver",
      name: "Receiver",
    },
    {
      key: "amount",
      name: "Amount",
    },
    {
      key: "type",
      name: "Type",
    },
    {
      key: "reference",
      name: "Reference",
    },
    {
      key: "transactionDateTime",
      name: "Date",
    },
    {
      key: "status",
      name: "Status",
    },
  ];

  return (
    <Box>
      <Text my="2rem" color={"#9A9CA2"}>
        Wallet /{" "}
        <span style={{ fontWeight: "600", color: "#244CC6" }}>
          Transactions
        </span>
      </Text>
      <Box
        overflowX="auto"
        whiteSpace="nowrap"
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <OverComp overviewData={transData} />
      </Box>
      <Box
        bg="#FFF"
        mt="2rem"
        borderRadius={"10px"}
        p="1.5rem"
        overflowX="auto"
        whiteSpace="nowrap"
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          mt=".2rem"
          direction={["column", "row"]}
          gap={["1rem", "0"]}
        >
          <Text fontWeight={"600"} fontSize={["1.1rem", "1.3rem"]}>
            Transactions
          </Text>
          <Flex
            gap={["1rem", ".6rem"]}
            alignItems={"center"}
            flexDirection={["column", "row"]}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Box maxWidth={"250px"} width={"100%"}>
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                onDateRangeChange={handleDateRangeChange}
              />
            </Box>
            <FormControl width={["100%", "400px"]}>
              <InputGroup>
                <InputLeftElement
                  children={
                    <CiSearch
                      color="#6941C6"
                      style={{ transform: "scale(1.4)" }}
                    />
                  }
                />
                <Input
                  onChange={(e) => setSearchVal(e.target.value)}
                  placeholder="Search"
                  fontFamily={"Open Sans"}
                  bg="#EAEAEB"
                  fontSize={".85rem"}
                  outline={"none"}
                  focusBorderColor="none"
                  _placeholder={{ fontSize: ".75rem", color: "#4E5055" }}
                  border="none"
                  boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.08)"}
                  borderRadius="20px"
                />
                <InputRightElement
                  children={
                    <Flex gap="1rem" marginLeft={"-.6rem"}>
                      <Button
                        type="submit"
                        bg="#FFF"
                        fontSize={".75rem"}
                        padding=".2rem 0rem"
                        height={"32px"}
                        borderRadius="21px"
                        color="#6941C6"
                      >
                        <HiPaperAirplane
                          style={{ transform: "rotate(90deg)" }}
                        />
                      </Button>
                    </Flex>
                  }
                />
              </InputGroup>
            </FormControl>
            <ExportCSV data={items} headers={headers} />
          </Flex>
        </Flex>

        <Box
          mt="1.5rem"
          borderRadius="12px"
          bg="#FFF"
          border="1px solid #EAECF0"
          // overflow="hidden"
          overflowX="auto"
          whiteSpace="nowrap"
          sx={{
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Table variant="simple" colorScheme="gray">
            <Thead bg="#F9FAFB">
              <Tr>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Transaction ID
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Date/Time
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Sender
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Receiver
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Amount
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Type
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Reference
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Status
                </Th>
                <Th color="#475467" fontSize="0.7rem" fontWeight="600">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan="9">
                    <Stack padding="1rem">
                      <Skeleton height="25px" width="100%" />
                      <Skeleton height="25px" width="100%" />
                    </Stack>
                  </Td>
                </Tr>
              ) : items?.length < 1 ? (
                <Tr>
                  <Td colSpan="9" textAlign="center" padding="1rem">
                    <EmptyState text="There are no transactions yet" />
                  </Td>
                </Tr>
              ) : (
                items
                  .filter((i) => i.anchorTransactionId.includes(searchVal))
                  .map(
                    ({
                      id,
                      anchorTransactionId,
                      currency,
                      reference,
                      sender,
                      receiver,
                      transactionDateTime,
                      amount,
                      type,
                      status,
                    }) => (
                      <Tr
                        key={id}
                        color="#475467"
                        fontSize="0.8rem"
                        borderBottom="1px solid #EAECF0"
                      >
                        <Td fontSize={"0.7rem"}>
                          {truncateText(anchorTransactionId, 8)}
                        </Td>
                        <Td fontSize={"0.7rem"}>
                          {moment(transactionDateTime).format(
                            "YYYY-MM-DD hh:mm A"
                          )}
                        </Td>
                        <Td>{truncateText(sender, 10)}</Td>
                        <Td>{truncateText(receiver, 10)}</Td>
                        <Td>₦{amount?.toLocaleString()}</Td>
                        <Td fontSize={"0.8rem"}>{type}</Td>
                        <Td fontSize={"0.7rem"}>
                          {truncateText(reference, 12)}
                        </Td>
                        <Td>
                          <Badge
                            fontSize="0.7rem"
                            colorScheme={
                              status === "COMPLETED" || status === "done"
                                ? "green"
                                : "red"
                            }
                          >
                            {status}
                          </Badge>
                        </Td>
                        <Td textAlign="center">
                          <AiFillEye
                            style={{
                              transform: "scale(1.3)",
                              cursor: "pointer",
                            }}
                            color="#2F80ED"
                          />
                        </Td>
                      </Tr>
                    )
                  )
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default Transactions;
