export default function LightenDarkenColor(col, amt) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
}

export function truncateText(text, length) {
  let newText = text;
  if (text?.length > length) {
    newText = text?.slice(0, length);
    return newText + '...';
  }
  return newText;
}

export function convertToProperObject(data) {
  const formattedData = {};

  for (const key in data) {
    const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
    formattedData[unescapedKey] = data[key];
  }

  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, '');
}

export function buildResourceURL(baseURL, queryParams) {
  const queryString = queryParams
    .filter(
      ({ value }) => value !== '' && value !== null && value !== undefined
    )
    .map(
      ({ key, value }) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  const finalURL = queryString ? `${baseURL}?${queryString}` : baseURL;

  return finalURL;
}

export const findDefaultCenter = (coordinates) => {
  if (!coordinates || coordinates.length === 0) {
    return null;
  }

  // Calculate average latitude and longitude
  const avgLatitude =
    coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
  const avgLongitude =
    coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;

  return { lat: avgLatitude, lng: avgLongitude };
};

export const formatAmount = (amount) => {
  // Convert the amount to a string
  const amountStr = amount.toString();

  // Split the string into whole and decimal parts (if any)
  const parts = amountStr.split('.');
  let wholePart = parts[0];
  const decimalPart = parts[1] || '';

  // Add commas for thousands separators in the whole part
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the formatted whole part and the decimal part (if any)
  let formattedAmount = formattedWholePart;
  if (decimalPart !== '') {
    formattedAmount += `.${decimalPart}`;
  }

  return formattedAmount;
};
