import React, { useState } from 'react'
import { Flex, useToast } from '@chakra-ui/react';
import axiosInstance from '../../../../../services/api';
import CustomInput from '../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import CustomSelect from '../../../../../components/customSelect/customSelect';

const genders = [
  {
    title: "Male",
    value: "MALE"
  },
  {
    title: "Female",
    value: "FEMALE"
  }
]


const AddGamer = ({loading, setLoading, setTriggerClose, title, setRefetch}) => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("SINGLE");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const toast = useToast();

  const createGamer = async(e) => {
    e.preventDefault()
    if(password === confirmPassword) {
    setLoading(true)
    const datax = {
      username,
      firstName,
      lastName,
      email,
      dateOfBirth,
      password,
      confirmPassword,
      maritalStatus,
      gender,
      phoneNumber
    }
    try {
      const {data} = await axiosInstance.post('users/gamer', datax)
      toast({
        description: "Gamer created" || data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0],
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
   } else {
    toast({
      title: "An Error Occurred",
      description: "Password mismatch, please check and try again.",
      status: "error",
      position: "top"
    });
   }
  }


  return (
    <form onSubmit={createGamer}>
        <Flex gap="1rem">
          <CustomInput
          type={"text"}
          required
          placeholder={"First Name"}
          onChange={e => setFirstName(e.target.value)}
          />
          <CustomInput
          type={"text"}
          required
          placeholder={"Last Name"}
          onChange={e => setLastName(e.target.value)}
          />
        </Flex>

        <CustomInput
        type={"text"}
        required
        placeholder={"Username"}
        onChange={e => setUsername(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
         <CustomInput
        type={"tel"}
        minLength={11}
        maxLength={11}
        required
        placeholder={"Phone number"}
        mb={".8rem"}
        onChange={e => setPhoneNumber(e.target.value)}
        />
        <Flex gap="1rem" padding={"0 !important"}>
          <CustomInput
          type={"date"}
          required
          mt="0"
          placeholder={"Date of Birth"}
          onChange={e => setDateOfBirth(e.target.value)}
          />
          <CustomSelect
          required
          mt="0"
          placeholder={"Gender"}
          options={genders}
          onChange={e => setGender(e.target.value)}
          />
        </Flex>

        <CustomInput
        type={"password"}
        required
        placeholder={"Password"}
        onChange={e => setPassword(e.target.value)}
        />
        <CustomInput
        type={"password"}
        required
        placeholder={"Confirm Password"}
        onChange={e => setConfirmPassword(e.target.value)}
        />

        <PrimaryButton mt="1rem" type="submit" text={`Add ${title}`} func={() => {}} loading={loading} />
    </form>
  )
}

export default AddGamer;