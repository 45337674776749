import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Avatar,
  Image,
  Spinner,
  useToast,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { CheckCircleIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { HiUsers } from 'react-icons/hi';
import { AiFillClockCircle } from 'react-icons/ai';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { useSelector } from 'react-redux';
import CustomToast from '../../../components/customToast/customToast';
import { truncateText } from '../../../utils/libs/helper';
import Naira from '../../../assets/naira.svg';
import PhoneIcon from '../../../assets/phoneIcon.svg';

const ApprovalRequests = () => {
    const [items, setItems] = useState([]);
  const [itemsx, setItemsx] = useState([]);
  const [itemsxx, setItemsxx] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadinga, setLoadinga] = useState(false);
  const [loadingr, setLoadingr] = useState(false);
  const [loadingx, setLoadingx] = useState(true);
  const [loadingxx, setLoadingxx] = useState(true);
  const [focus, setFocus] = useState('');

  const toast = useToast();

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  useEffect(() => {
    axiosInstance
      .get('approval-requests/approvals?size=200')
      // .then(res => console.log(res?.data))
      .then((res) => setItems(res?.data?.data?.content))
      .then((res) => setLoading(false))
      .catch((error) => console.log(error?.response?.data?.message));
  }, [loadinga, loadingr]);
  useEffect(() => {
    axiosInstance
      .get('approval-requests/all?size=200')
      // .then(res => console.log(res?.data?.data?.content))
      .then((res) => setItemsx(res?.data?.data?.content))
      .then((res) => setLoadingx(false))
      .catch((error) => console.log(error?.response?.data?.message));
  }, [loadinga, loadingr]);
  useEffect(() => {
    axiosInstance
      .get('approval-requests/my-request?size=200')
      // .then(res => console.log(res?.data?.data?.content))
      .then((res) => setItemsxx(res?.data?.data?.content))
      .then((res) => setLoadingxx(false))
      .catch((error) => console.log(error?.response?.data?.message));
  }, [loadinga, loadingr]);

  const approveRequest = async (id) => {
    setFocus(id);
    setLoadinga(true);
    try {
      const { data } = await axiosInstance.post(
        `approval-requests/approve/${id}`
      );
      // console.log(data)
      toast({
        render: () => (
          <CustomToast type={'success'} message="Request Approved" />
        ),
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      toast({
        render: () => (
          <CustomToast
            type={'error'}
            message={error?.response?.data?.errors[0]}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoadinga(false);
    }
  };
  const rejectRequest = async (id) => {
    setFocus(id);
    setLoadingr(true);
    try {
      const { data } = await axiosInstance.post(
        `approval-requests/reject/${id}`
      );
      // console.log(data)
      toast({
        render: () => (
          <CustomToast type={'success'} message="Request Rejected" />
        ),
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      toast({
        render: () => (
          <CustomToast
            type={'error'}
            message={error?.response?.data?.errors[0]}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoadingr(false);
    }
  };

  const tix = [
    {
      id: 0,
      requesterUsername: 'string',
      approver: 'string',
      approvalStatus: 'pending',
      requestType: 'team_invite',
      workflowType: 'individual',
      requestDate: '2023-07-15T15:59:45.507Z',
      lastApprovalDate: '2023-07-15T15:59:45.507Z',
      classPath: 'string',
      requestObject: 'string',
      approvalHistory: [
        {
          id: 0,
          approverUsername: 'string',
          approvalDate: '2023-07-15T15:59:45.507Z',
          comment: 'string',
        },
      ],
    },
    {
      id: 0,
      requesterUsername: 'string',
      approver: 'string',
      approvalStatus: 'approved',
      requestType: 'team_invite',
      workflowType: 'individual',
      requestDate: '2023-07-15T15:59:45.507Z',
      lastApprovalDate: '2023-07-15T15:59:45.507Z',
      classPath: 'string',
      requestObject: 'string',
      approvalHistory: [
        {
          id: 0,
          approverUsername: 'string',
          approvalDate: '2023-07-15T15:59:45.507Z',
          comment: 'string',
        },
      ],
    },
  ];

  return (
    <Box mt="1rem">
      {loadingx ? (
        <Stack padding={'1rem'}>
          <Skeleton height={'25px'} width={'100%'} />
          <Skeleton height={'25px'} width={'100%'} />
          <Skeleton height={'25px'} width={'100%'} />
          <Skeleton height={'25px'} width={'100%'} />
          <Skeleton height={'25px'} width={'100%'} />
        </Stack>
      ) : itemsx?.length < 1 ? (
        <Text mt={'6rem'} textAlign={'center'}>
          No Requests Found
        </Text>
      ) : (
        itemsx?.map(
          ({
            requesterUsername,
            approver,
            approvalStatus,
            requestType,
            workflowType,
            requestDate,
            requestObject,
            id,
          }) => {
            const reqObj = JSON.parse(requestObject);
            return (
              <Box
                mb=".5rem"
                key={id}
                padding={'1rem'}
                borderRadius={'8px'}
                bg="#FFF"
                boxShadow={'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'}
              >
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Box>
                    <Text fontSize={'.9rem'}>
                      <strong style={{ textTransform: 'capitalize' }}>
                        {currentUser?.username === requesterUsername
                          ? 'You'
                          : requesterUsername}
                      </strong>{' '}
                      requested approval for{' '}
                      <strong style={{ textTransform: 'capitalize' }}>
                        {requestType}
                      </strong>
                    </Text>
                    <Text fontSize={'.8rem'} mt=".3rem" opacity={'.7'}>
                      {requestDate?.slice(0, 10)}
                    </Text>
                  </Box>
                  {approvalStatus === 'pending' ? (
                    approver === 'gameporte' ? (
                      <Flex alignItems={'center'} gap="1rem">
                        <PrimaryButton
                          onClick={() => approveRequest(id)}
                          bg="green.400"
                          _hover={{ backgroundColor: '#013220' }}
                          color="#FFF"
                          height="30px"
                          padding=".4rem .7rem"
                          fontSize=".75rem"
                          borderRadius="8px"
                          text={focus === id && loadinga ? (
                            <Spinner />
                          ) : (
                            'Approve'
                          )}
                        />
                          
                  
                        <PrimaryButton
                          onClick={() => rejectRequest(id)}
                          bg="red.400"
                          _hover={{ backgroundColor: 'red' }}
                          color="#FFF"
                          height="30px"
                          padding=".4rem .7rem"
                          fontSize=".75rem"
                          borderRadius="8px"
                          text={focus === id && loadingr ? (
                            <Spinner />
                          ) : (
                            'Reject'
                          )}
                        />
                      </Flex>
                    ) : null
                  ) : (
                    <Text
                      fontSize={'.8rem'}
                      color={
                        approvalStatus?.includes('reject')
                          ? 'red.500'
                          : 'green.600'
                      }
                      fontWeight={'500'}
                    >
                      Request {approvalStatus}{' '}
                      {!approvalStatus?.includes('reject') ? (
                        <CheckCircleIcon />
                      ) : (
                        <CloseIcon fontSize={'.7rem'} />
                      )}
                    </Text>
                  )}
                </Flex>

                {requestType === 'create_tournament' && (
                  <>
                    <Flex
                      cursor={'pointer'}
                      flexDirection={'column'}
                      padding={'.25rem'}
                      overflow={'hidden'}
                      backgroundImage={reqObj.imageUrl}
                      transition={'250ms ease'}
                      overflowY={'hidden'}
                      height={focus === id ? '220px' : '0px !important'}
                      backgroundSize={'contain'}
                      width={'100%'}
                      borderRadius={'22px'}
                      border="5px solid #FFF"
                    >
                      <Flex
                        px=".3rem"
                        mt=".5rem"
                        mb="4.3rem"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Avatar
                          border="1.5px solid lightgray"
                          size="xs"
                          src={reqObj.imageUrl}
                        />
                        <Text
                          bg="#F3A805"
                          color={'#FFF'}
                          fontSize={'.7rem'}
                          fontWeight={'600'}
                          padding={'.2rem .4rem'}
                          borderRadius={'18px'}
                        >
                          {reqObj.gameId}: {reqObj.gameMode}
                        </Text>
                      </Flex>
                      <Box
                        height={'50%'}
                        width={'100%'}
                        borderRadius={'14px'}
                        bg="#FFF"
                        padding={'.5rem'}
                      >
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                          <Flex alignItems={'center'} gap=".2rem">
                            <Image src={Naira} />
                            <Text fontSize={'.7rem'} fontWeight={'700'} color={'#0A0A0B'}>
                              {reqObj.prize?.toLocaleString()}{' '}
                              <span
                                style={{
                                  color: '#4E5055',
                                  fontWeight: '400',
                                  fontSize: '.65rem',
                                }}
                              >
                                Prize pool
                              </span>
                            </Text>
                          </Flex>
                          <Flex alignItems={'center'} gap=".5rem">
                            <Text
                              bg="#54F297"
                              color={'#022711'}
                              fontSize={'.55rem'}
                              fontWeight={'500'}
                              padding={'.1rem .2rem'}
                              borderRadius={'18px'}
                            >
                              {reqObj.active}
                            </Text>
                            <Image src={PhoneIcon} />
                          </Flex>
                        </Flex>
                        <Text mt=".5rem" fontSize={'.8rem'} fontWeight={'700'} color={'#0A0A0B'}>
                          {truncateText(reqObj.title, 25)}
                        </Text>
                        <Flex mt=".5rem" alignItems={'center'} justifyContent={'space-between'}>
                          <Box>
                            <Text
                              color={'#4E5055'}
                              fontSize={'.7rem'}
                              mb=".1rem"
                              textTransform={'capitalize'}
                            >
                              {reqObj?.participantType}
                            </Text>
                            <Text color={'#4E5055'} fontSize={'.7rem'}>
                              <HiUsers
                                style={{
                                  display: 'inline',
                                  transform: 'translateY(2px)',
                                  marginRight: '.2rem',
                                }}
                              />
                              {reqObj.numberOfTeams || reqObj?.numberOfIndividuals}
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color={'#4E5055'}
                              fontSize={'.7rem'}
                              mb=".1rem"
                            >
                              {reqObj.startDateTime?.date?.day +
                                '-' +
                                reqObj.startDateTime?.date?.month +
                                '-' +
                                reqObj.startDateTime?.date?.year}
                            </Text>
                            <Text
                              color={'#4E5055'}
                              fontSize={'.7rem'}
                              fontWeight={'500'}
                            >
                              <AiFillClockCircle
                                color="grey"
                                style={{
                                  display: 'inline',
                                  transform: 'translateY(2px)',
                                  marginRight: '.4rem',
                                }}
                              />
                              {reqObj.startDateTime?.time?.hour}:00
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              color={'#4E5055'}
                              fontSize={'.7rem'}
                              mb=".1rem"
                            >
                              Entry Fee
                            </Text>
                            {reqObj.entryFee ? (
                              <Flex alignItems={'center'} gap=".2rem">
                                <Image src={Naira} />
                                <Text
                                  fontSize={'.7rem'}
                                  fontWeight={'500'}
                                  color={'#0A0A0B'}
                                >
                                  {reqObj.entryFee?.toLocaleString()}
                                </Text>
                              </Flex>
                            ) : (
                              <Text
                                textAlign={'right'}
                                fontSize={'.7rem'}
                                fontWeight={'500'}
                                color={'#0A0A0B'}
                              >
                                FREE
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>

                    <Flex justifyContent={'center'}>
                      <Text
                        onClick={() =>
                          focus === id ? setFocus('') : setFocus(id)
                        }
                        cursor={'pointer'}
                        mt="1rem"
                        fontSize={'.8rem'}
                        fontWeight={'600'}
                      >
                        {focus === id ? 'Hide details' : 'See more details'}{' '}
                        <ChevronDownIcon />
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            );
          }
        )
      )}
    </Box>
  );
};

export default ApprovalRequests;
