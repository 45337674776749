import { Box, Flex, Text, Spacer, Heading } from '@chakra-ui/react'
import React, { useState, useMemo } from 'react'
import Metrics from '../../../components/metrics/metrics';
import ClientsView from './clientsView/clientsView';
import ChartView from './chartView/chartView';
import SupervisorsView from './supervisorsView/supervisorsView';
import PromotersView from './promotersView/promotersView';
import StoresView from './storesView/storesView';
import RequestsView from './requestsView/requestsView';
import { useSelector } from 'react-redux';
import SecondaryButton from '../../../components/secondaryButton/secondarybutton';
import ExportCSV from '../../../components/exportCsv';
import Porteshop from './porteshop/porteshop';
import Gameporte from './gameporte/gameporte';
import CustomTabs from '../../../components/customTabs/customTabs';
import DateRangeSelector from './dateRangeSelector/dateRangeSelector';

const Home = (metricsData, ) => {
  const [totalClients, setTotalClients] = useState(0)
  const [totalPromoters, setTotalPromoters] = useState(0)
  const [totalSupervisors, setTotalSupervisors] = useState(0)

  const [metricsCsv, setMetricsCsv] = useState([]);

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

  const memoizedDateRange = useMemo(() => dateRange, [dateRange.startDate, dateRange.endDate]);

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const tabsData = useMemo(() => [
    {
      title: "Gameporte",
      render: <Gameporte dateRange={memoizedDateRange}  onMetricsCsvChange={setMetricsCsv}/>,
    },
    {
      title: "Porteshop",
      render: <Porteshop dateRange={memoizedDateRange} />,
    },
  ], [memoizedDateRange]);

  const data = [
    {
      title: "Clients added",
      value: totalClients
    },
    {
      title: "Supervisors",
      value: totalSupervisors
    },
    {
      title: "Promoters",
      value: totalPromoters
    },
    {
      title: "Initiatives",
      value: "0"
    },
  ]

  const headers =  [
    { key: 'totalSignUp', name: 'Total Sign-Ups' },
    { key: 'totalLogin', name: 'Total Logins' },
    { key: 'totalUsers', name: 'Total Users' },
    { key: 'startDate', name: 'Start Date' },
    { key: 'endDate', name: 'End Date' },
  ];

  return (
    <Box>
      <Box bg="#FFF" mt=".7rem"  p="40px" borderRadius={"12px"}>
      <Flex alignItems="center" mb={"10px"}>
          <Box>
            <Text fontWeight="700" fontSize="24px">
              Users Metrics
            </Text>
          </Box>
          <Spacer />
          <Flex alignItems="center" gap={2}>
          <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
            <ExportCSV headers={headers} items={metricsCsv} />
          </Flex>
        </Flex>
        <CustomTabs data={tabsData} />
  
        {/* <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
          <SupervisorsView setTotalSupervisors={setTotalSupervisors} />
          <PromotersView setTotalPromoters={setTotalPromoters} />
        </Flex>
        <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
          <StoresView />
          <RequestsView />
        </Flex> */}
      </Box>
    </Box>
  )
}

export default Home;