import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buildResourceURL } from '../../../utils/helper'
import CustomInput from '../../../components/customInput/customInput'
import ExportCSV from '../../../components/exportCsv'
import LineSkeleton from '../../../components/lineSkeleton'
import EmptyState from '../../../components/emptyState/emptyState'
import CustomPagination from '../../../components/customPagination/customPagination'
import axiosInstance from '../../../services/api'

const CommunityReps = () => {
    const title = 'Community Rep'
    const [reps, setReps] = useState([]);
    const [totalReps, setTotalReps] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refetch, setRefetch] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)


    const queryParams = [
        {
            key: 'page',
            value: page
        },
        {
            key: 'size',
            value: limit
        },
        {
            key: 'size',
            value: limit
        }
    ]

    const getReps = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('users/community-rep', queryParams))
            setReps(data?.data?.content)
            setTotalReps(data?.data?.totalElements)
            setTotalPages(data?.data?.totalPages)
        } catch(error) {
            console.error(error?.response?.data?.errors[0])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getReps()
    }, [page, limit, refetch])



    const headers = [
      {
        key: "id",
        name: "ID"
      },
      {
        key: "email",
        name: "Email"
      },
      {
        key: "username",
        name: "Username"
      },
      {
        key: "gender",
        name: "Gender"
      },
      {
        key: "phoneNumber",
        name: "Phone Number"
      },
    ]

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"} overflowX="auto" whiteSpace="nowrap"
    sx={{
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}>
  
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>{title}s <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalReps}</span></Text>
                <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={`Search ${title}s..`}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                />
            </Flex>

            <Flex alignItems={"center"} gap="1rem">
            
            <ExportCSV headers={headers} items={reps} />

            </Flex>

        </Flex>
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Username</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            reps?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no managers yet" />
            :
            <Tbody>
            {reps?.filter(i => i.username?.toLowerCase()?.includes(searchVal?.toLowerCase()))?.map((({id, firstName, lastName, email, username, phoneNumber}) => {
                return (
                    <Tr key={id}>
                        <Td color={"#475467"} fontSize={".9rem"}>{id}</Td>
                        <Td>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{firstName ? (firstName + " " + lastName) : "-- --"}</Text>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{username}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{phoneNumber}</Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>

       

        <CustomPagination
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={totalPages}
         />
    </Box>
  )
}

export default CommunityReps;