import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import { BiDotsVerticalRounded } from "react-icons/bi";
import EmptyState from "../../../../components/emptyState/emptyState";
import LineSkeleton from "../../../../components/lineSkeleton";
import AddGameMode from "../addGameMode/addGameMode";
import CustomDrawer from "../../../../components/customDrawer/customDrawer";
import { useEffect, useState } from "react";
import { buildResourceURL, truncateText } from "../../../../utils/libs/helper";
import axiosInstance from "../../../../services/api";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import EditGameMode from "../editGameMode/editGameMode";
import SecondaryButton from "../../../../components/secondaryButton/secondarybutton";

const GameModes = () => {

    const [triggerClose, setTriggerClose] = useState("");
    const [refetch, setRefetch] = useState("");
    const [loading, setLoading] = useState(true)
    const [loadingx, setLoadingx] = useState(false)
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [modes, setModes] = useState([])
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const navigate = useNavigate()

    const queryParams = [
        {
            key: 'page',
            value: page
        },
        {
            key: 'size',
            value: limit
        },
        {
            key: 'size',
            value: limit
        }
    ]

    const getModes = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('one-v-one/modes', queryParams))
            setModes(data?.data)
            console.log(data?.data)
            // setTotalPages(data?.data?.totalPages)
        } catch(error) {
            console.error(error?.response?.data?.errors[0])
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
        getModes()
    }, [refetch])

    const deleteMode = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`one-v-one/modes/${selectedUser?.id}`)
            toast({
            description: "Game mode deleted" || data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"} overflowX="auto"
    whiteSpace="nowrap"
    sx={{
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}>
  
    <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
        
        <Flex gap="1.5rem" alignItems={"center"}>
        <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Game Modes <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{modes?.length}</span></Text>
            {/* <CustomInput
            type={"text"}
            required
            value={searchVal}
            placeholder={'Search Matches..'}
            onChange={e => setSearchVal(e.target.value)}
            mt="0"
            sType="search"
            py="1.19rem"
            width="350px"
            /> */}
        </Flex>

        <Flex alignItems={"center"} gap="1rem">
        
        <CustomDrawer
        position={"right"}
        title={`Add Game Mode`}
        triggerClose={triggerClose}
        setTriggerClose={setTriggerClose}
        toggleElement={
            <SecondaryButton bg="#244CC6" color="#FFF" height="35px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="24px" icon="add" text="Add Game Modes" />
        }
        content={
            <AddGameMode setRefetch={setRefetch} setTriggerClose={setTriggerClose} />
        }
        />

        </Flex>

    </Flex>
    <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
        <Thead bg="#F9FAFB" border="1px solid #EAECF0">
            <Tr>
                <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>Game</Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>Description</Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>Match Rules</Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
            </Tr>
        </Thead>
        
        {loading ? 
        <LineSkeleton width="100%" num={10} />
        :
        modes?.length < 1 ?
        <EmptyState mt="1.5rem" />
        :
        <Tbody>
        {modes?.map((({id, gameName, matchRules, description, name, gameId}) => {
            const data = {id, gameName, matchRules, description, name, gameId}
            return (
                <Tr key={id}>
                    <Td color={"#475467"} fontSize={".9rem"}>{id}</Td>
                    <Td>
                      <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{gameName}</Text>
                    </Td>
                    <Td color={"#475467"} fontSize={".9rem"}>{name}</Td>
                    <Tooltip label={description}>
                         <Td color={"#475467"} fontSize={".9rem"}>
                            <Text mb=".2rem" opacity={".7"} color={"#101828"} fontSize={".85rem"} fontWeight={"400"} textTransform={"capitalize"}>{truncateText(description, 30)}</Text>
                        </Td>
                    </Tooltip>
                    <Tooltip label={matchRules}>
                         <Td color={"#475467"} fontSize={".9rem"}>
                            {/* <Text mb=".2rem" opacity={".7"} color={"#101828"} fontSize={".85rem"} fontWeight={"400"} textTransform={"capitalize"}>{truncateText(matchRules, 30)}</Text> */}
                            <p dangerouslySetInnerHTML={{ __html: truncateText(matchRules, 30) }} />
                        </Td>
                    </Tooltip>
                    <Td>
                        <Menu width={"200px !important"}>
                            <MenuButton>
                                <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                            </MenuButton>
                            <MenuList>
                            <Divider my=".2rem" />
                                <CustomDrawer
                                position={"right"}
                                title={`Edit Game Mode`}
                                triggerClose={triggerClose}
                                setTriggerClose={setTriggerClose}
                                toggleElement={
                                    <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit Mode</MenuItem>
                                }
                                content={
                                    <EditGameMode setRefetch={setRefetch} setTriggerClose={setTriggerClose} datax={data} />
                                }
                                />
                                <Divider my=".2rem" />
                                <MenuItem color={"crimson"} onClick={() => handleModal('delete', {title: `${name} (${gameName})`, id})} fontWeight={"500"} fontSize={".85rem"}>Delete Mode</MenuItem>
                                {/* <Divider my=".2rem" />
                                {status !== "Completed" && <MenuItem onClick={() => handleModal(status === "Pending" ? 'start' : 'restart', {title, id, status})} fontWeight={"500"} fontSize={".85rem"}>{status === "Pending" ? "Start" : status === "Ongoing" ? "Restart" : ""} Initiative</MenuItem>}
                                <Divider my=".2rem" />
                                {status === "Ongoing" && <MenuItem onClick={() => handleModal('complete', {title, id, status})} fontWeight={"500"} fontSize={".85rem"}>Complete Initiative</MenuItem>}
                                <Divider my=".2rem" /> */}
                                {/* {status === "PENDING" &&
                                <>
                                <Divider my=".2rem" />
                                <MenuItem onClick={() => handleModal('delete', {title: `${gameName} Match between ${host?.username} & ${opponent?.username || "--"}`, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete Match</MenuItem>
                                </>} */}
                                <Divider my=".2rem" />
                            </MenuList>
                        </Menu>
                    </Td>
                </Tr>
            )
        }))
        }
        </Tbody>}
    </Table>


    <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} 1v1 Game Mode`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {modalType === 'delete' &&
                <>
                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.title}?</Text>
                    <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton onClick={deleteMode} bg="crimson" color="#FFF" height="35px" padding=".4rem .7rem" fontSize=".75rem" borderRadius="8px" text={loadingx ? <Spinner /> : "Delete Game Mode"}>{loadingx ? <Spinner /> : "Delete Game Mode"}</PrimaryButton>
                    </Flex>
                </>
                }
                <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
            </ModalBody>
        </ModalContent>
    </Modal>

   

    {/* <CustomPagination page={page} setPage={setPage} limit={limit} totalpages={totalPages} /> */}
</Box>
  )
}

export default GameModes