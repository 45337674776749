import React, { useState, useEffect } from 'react';
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  Stack,
  useToast,
  Flex,
} from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import SendNotificationImageUploader from '../../../../components/imageUploader/notificationImageUploader';
import SearchableSelect from '../../../../components/customSearchableSelect/customSearchableSelect';

const SendNotification = ({ setTriggerClose, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [loadingi, setLoadingi] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    recipientType: '',
    recipient: '',
    recipientSelection: '',
    manualRecipients: [],
    game: '',
    team: '',
    imageURL: '',
  });
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const toast = useToast();

  // Fetch users for recipient selection
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axiosInstance.get('/users/gamers');
        setRecipientOptions(data.data.content);
      } catch (error) {
        showToast('error', 'An Error Occurred', 'Failed to load users');
      }
    };
    fetchUsers();
  }, [searchTerm]);

  const showToast = (status, title, description) => {
    toast({
      title,
      description,
      status,
      position: 'top',
    });
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleImagesUpload = (images) => {
    handleInputChange('imageURL', images[0]);
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    setLoading(true);

    const {
      title,
      body,
      recipientType,
      recipientSelection,
      manualRecipients,
      game,
      team,
      recipient,
    } = formData;

    let endpoint = '';
    let payload = {};
    console.log(manualRecipients, recipientOptions);
    switch (recipientType) {
      case 'single':
        endpoint = '/push-notifications/message';
        payload = { message: body, receiver: recipient.username };
        break;

      case 'multiple':
        if (recipientSelection === 'manual') {
          endpoint = manualRecipients.includes('all')
            ? '/push-notifications/all'
            : '/push-notifications/send-multiple';
          payload = {
            title,
            message: body,
            ...(manualRecipients.includes('all')
              ? {}
              : { topics: manualRecipients.map((rec) => rec.username) }),
          };
        } else if (
          recipientSelection === 'game' ||
          recipientSelection === 'team'
        ) {
          endpoint = '/push-notifications/send-multiple';
          payload = {
            title,
            message: body,
            topics: recipientSelection === 'game' ? [game] : [team],
          };
        }
        break;

      default:
        endpoint = '/push-notifications/all';
        payload = { title, message: body };
        break;
    }

    try {
      const { data } = await axiosInstance.post(endpoint, payload);
      showToast('success', '', data?.message || 'Notification Sent');
      setTriggerClose('close' + Math.random());
      setRefetch(Math.random());
    } catch (error) {
      showToast(
        'error',
        'An Error Occurred',
        error?.response?.data?.errors[0] || 'System unavailable'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={sendNotification}>
      <FormField
        label="Notification title"
        type="text"
        placeholder="Enter title here"
        value={formData.title}
        onChange={(e) => handleInputChange('title', e.target.value)}
      />

      <FormField
        label="Notification body"
        type="textarea"
        placeholder="Enter body here"
        value={formData.body}
        onChange={(e) => handleInputChange('body', e.target.value)}
        customHeight="188px"
      />

      <FormField label="Attachment">
        <SendNotificationImageUploader
          onImagesUpload={handleImagesUpload}
          setLoading={setLoadingi}
          loading={loadingi}
          max={1}
        />
      </FormField>

      <FormField label="Select Recipients">
        <RadioGroup
          onChange={(value) => handleInputChange('recipientType', value)}
          value={formData.recipientType}
        >
          <Stack direction="row">
            <Radio value="single">Single User</Radio>
            <Radio value="multiple">Multiple Users</Radio>
          </Stack>
        </RadioGroup>
      </FormField>

      {formData.recipientType === 'single' && (
        <FormField label="Recipient">
          <SearchableSelect
            options={recipientOptions}
            placeholder="Select recipient"
            onChange={(e) => handleInputChange('recipient', e.target.value)}
            value={formData.recipient}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </FormField>
      )}

      {formData.recipientType === 'multiple' && (
        <MultipleRecipientsSection
          formData={formData}
          recipientOptions={recipientOptions}
          handleInputChange={handleInputChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}

      <PrimaryButton
        mt="2rem"
        text="Send notification"
        type="submit"
        loading={loading}
      />
    </form>
  );
};

// Extracted components for better structure and reuse
const FormField = ({
  label,
  type = 'text',
  placeholder,
  value,
  onChange,
  children,
  customHeight,
}) => (
  <FormControl mb="24px">
    <FormLabel fontSize=".9rem" opacity=".7" color="#71717A">
      {label}
    </FormLabel>
    {children || (
      <CustomInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        customHeight={customHeight}
      />
    )}
  </FormControl>
);

const MultipleRecipientsSection = ({
  formData,
  recipientOptions,
  handleInputChange,
  searchTerm,
  setSearchTerm,
}) => (
  <FormField label="Recipients Selection">
    <Select
      placeholder="Select recipient type"
      onChange={(e) => handleInputChange('recipientSelection', e.target.value)}
    >
      <option value="manual">Manual selection</option>
      <option value="game">Select by game</option>
      <option value="team">Select by team</option>
    </Select>

    {formData.recipientSelection === 'manual' && (
      <FormField label="Recipients">
        <SearchableSelect
          options={[{ title: 'All Users', id: 0 }, ...recipientOptions]}
          placeholder="Select recipients"
          onChange={(selectedValues) =>
            handleInputChange('manualRecipients', selectedValues.target.value)
          }
          value={formData.manualRecipients}
          isMulti={true}
        />
      </FormField>
    )}

    {formData.recipientSelection === 'game' && (
      <FormField label="Game">
        <Select
          placeholder="Select game"
          onChange={(e) => handleInputChange('game', e.target.value)}
        >
          <option value="cod">COD</option>
          <option value="freefire">Freefire</option>
          <option value="pubg">Pubg</option>
        </Select>
      </FormField>
    )}

    {formData.recipientSelection === 'team' && (
      <FormField label="Team">
        <CustomInput
          type="text"
          placeholder="Select Team"
          value={formData.team}
          onChange={(e) => handleInputChange('team', e.target.value)}
        />
      </FormField>
    )}
  </FormField>
);

export default SendNotification;
