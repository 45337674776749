import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { buildResourceURL } from '../../../utils/helper'
import CustomInput from '../../../components/customInput/customInput'
import ExportCSV from '../../../components/exportCsv'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../components/secondaryButton/secondarybutton'
import LineSkeleton from '../../../components/lineSkeleton'
import EmptyState from '../../../components/emptyState/emptyState'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import AddGamer from './gamersView/addGamer/addGamer'
import ViewGamer from './gamersView/viewGamer/viewGamer'
import CustomPagination from '../../../components/customPagination/customPagination'

const Gamers = () => {
    const title = 'Gamer'
    const [gamers, setGamers] = useState([]);
    const [totalGamers, setTotalGamers] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(100)


    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()

    const queryParams = [
        {
            key: 'page',
            value: page
        },
        {
            key: 'size',
            value: limit
        },
        {
            key: 'size',
            value: limit
        }
    ]

    const getGamers = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('users/gamers', queryParams))
            setGamers(data?.data?.content)
            setTotalGamers(data?.data?.totalElements)
            setTotalPages(data?.data?.totalPages)
        } catch(error) {
            console.error(error?.response?.data?.errors[0])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getGamers()
    }, [page, limit, refetch])

    const searchGamer = async() => {
      if(searchVal?.length >= 3) {
        try {
            const {data} = await axiosInstance.get(`users/search?username=${searchVal}`)
            setGamers(data?.data?.content)
        } catch(error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
      } else {
        getGamers()
      }
    }

    useEffect(() => {
        searchGamer()
    }, [searchVal])


    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const upgradeGamer = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.post(`/users/upgrade-to-cr/${selectedUser?.username}`)
            toast({
            description: data?.message || "Gamer Upgraded to Community Rep",
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deactivateGamer = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.post(`/users/deactivate/${selectedUser?.username}`)
            toast({
            description: data?.message || "Gamer Deactivated",
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.errors[0],
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }


    const headers = [
      {
        key: "id",
        name: "ID"
      },
      {
        key: "email",
        name: "Email"
      },
      {
        key: "username",
        name: "Username"
      },
      {
        key: "status",
        name: "Status"
      },
      {
        key: "dateOfBirth",
        name: "DOB"
      },
    ]

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"} overflowX="auto"
    whiteSpace="nowrap"
    sx={{
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }}>
  
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>{title}s <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalGamers}</span></Text>
                <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={`Search ${title}s..`}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                />
            </Flex>

            <Flex alignItems={"center"} gap="1rem">
            
            <ExportCSV headers={headers} items={gamers} />

            <CustomDrawer
            position={"right"}
            title={`Add ${title}`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Add ${title}`} icon="add" loading={false}  />
            }
            content={
                <AddGamer title={title} loading={loadingc} setTriggerClose={setTriggerClose} setRefetch={setRefetch} setLoading={setLoadingc} />
            }
            />

            </Flex>

        </Flex>
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Username</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            gamers?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no gamers yet" />
            :
            <Tbody>
            
            {gamers?.map((({id, firstName, lastName, email, username, status, createdAt, dateOfBirth}) => {
                const data = {id, firstName, lastName, email, status, createdAt, username, dateOfBirth}
                return (
                    <Tr key={id}>
                        <Td color={"#475467"} fontSize={".9rem"}>{id}</Td>
                        <Td>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{firstName ? (firstName + " " + lastName) : "-- --"}</Text>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{username}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        <Td>
                            <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem" borderRadius={"16px"} border={status === 'ACTIVE' ? "1px solid #ABEFC6" : "1px solid lightgray"} color={status === 'ACTIVE' ? "#067647" : "#475467"} bg={status === 'ACTIVE' ? "#ECFDF3" : "#F9FAFB"}>{status}</Text>
                        </Td>
                        <Td>
                            
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={`${title} details`}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>View {title?.toLowerCase()}</MenuItem>
                                    }
                                    content={
                                        <ViewGamer title={title} data={data} />
                                    }
                                    />
                                    <Divider my=".2rem" /> 
                                    <MenuItem onClick={() => handleModal('upgrade', {username, id})} fontWeight={"500"} fontSize={".85rem"}>Upgrade to CR</MenuItem>
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => handleModal('deactivate', {username, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Deactivate {title?.toLowerCase()}</MenuItem>
                                    <Divider my=".2rem" />
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} ${title}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'upgrade' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm upgrade of {selectedUser?.username} to Community Rep?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={upgradeGamer} text="Upgrade" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'deactivate' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deactivation of {selectedUser?.username}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deactivateGamer} text="Deactivate" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>

        <CustomPagination
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={totalGamers}
         totalPages={totalPages}
         />
    </Box>
  )
}

export default Gamers;