import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const CustomTabs = ({ data, onChange, ...props }) => {
  return (
    <Tabs onChange={(index) => onChange(data[index]?.title)} {...props}>
      <TabList border={"none"} gap="1rem" mb="1rem">
        {data?.map((i, index) => (
          <Tab
            px="auto"
            color="#667085"
            fontWeight={"600"}
            fontSize={"14px"}
            _selected={{
              color: "#244CC6",
              fontWeight: "600",
              borderBottom: "3px solid #244CC6",
            }}
            key={index}
          >
            {i?.title}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {data?.map((i, index) => (
          <TabPanel key={index} px="0">
            {i?.render}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CustomTabs;
