import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const ChartView = ({ height }) => {
  const ratingData = [600, 480, 360, 240, 120]; 
  const totalUsers = 900;

  const getPercentage = (rating) => {
    return totalUsers > 0 ? (rating / totalUsers) * 100 : 0;
  };

  const ratingColors = {
    5: "linear-gradient(180deg, #99FFA3 0%, #68EE76 100%)",
    4: "linear-gradient(180deg, #7AD3FF 0%, #4FBAF0 100%)",
    3: "linear-gradient(180deg, #FFD572 0%, #FEBD38 100%)",
    2: "linear-gradient(180deg, #FF9364 0%, #F25F33 100%)",
    1: "linear-gradient(180deg, #FE6C6C 0%, #FE464B 100%)",
  };

  return (
    <Box
      height={height}
      flex={["1 0 300px", "1 1 400px"]}
      bg="#FFF"
      border=".5px solid #8E8E9333"
      px={["15px", "20px", "30px"]} 
      pt={["20px", "25px", "30px"]} 
      pb={["0.5rem", "0.75rem", "1rem"]} 
      borderRadius="12px"
    >
      {/* Title Section */}
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          whiteSpace="nowrap"
          fontWeight="500"
          fontSize={["16px", "18px", "20px"]} 
          color="#101321"
        >
          Customer Satisfaction and Feedback
        </Text>
      </Flex>

      <Box mt="1rem">
        {[5, 4, 3, 2, 1].map((star, index) => (
          <Box key={star} mb={["8px", "10px"]}>
            <Flex justifyContent="space-between" alignItems="center" mb="2px">
              <Text
                fontWeight="400"
                color="#909090"
                fontSize={["12px", "14px"]} 
              >
                {`${star} Stars`}
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Box
                width="100%"
                background="#D1D1D1"
                height={["16px", "18px", "19px"]} 
                borderRadius="10px"
                position="relative"
              >
                <Box
                  width={`${getPercentage(ratingData[index])}%`}
                  height="100%" // Match parent height
                  borderRadius="10px"
                  background={ratingColors[star]}
                  border="1px solid white"
                  position="absolute"
                  left="0"
                  top="0"
                ></Box>
                <Text
                  position="absolute"
                  right={["10px", "15px", "20px"]} 
                  top="50%"
                  transform="translateY(-50%)"
                  fontWeight="500"
                  fontSize={["0.65rem", "0.75rem"]} 
                  color="#909090"
                >
                  {`${ratingData[index]} / ${totalUsers} users`}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ChartView;
